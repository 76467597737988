import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type {
  BaseCharacteristicDto,
  FilterCharacteristicDto,
  FilterSelectedOptionsCarDto,
  SearchDataDto,
} from '~/types/client'
import type {
  CharacteristicDto,
  CheckboxCharacteristicDto, ColorCharacteristicDto,
  NumberCharacteristicDto,
  SingleChoiceCharacteristicDto,
} from '~/types/common'
import {
  CharacteristicTypesEnum,
  FilterGroupsEnum,
} from '~/types/common'
import DataCollectionService from '~/api/common/data-collection.service'

export class CharacteristicsDecodeStrategy extends StringDecodeStrategyAbstract<FilterSelectedOptionsCarDto> {
  readonly pattern = `^${FilterGroupsEnum.CHARACTERISTICS}=(&?(${this.slugPattern}:(${this.uuidPattern}(,${this.uuidPattern})*|${this.numberRangePattern}|${this.conditionPattern})))*$`

  process(subject: SearchDataDto<FilterSelectedOptionsCarDto>, searchItemString: string): void {
    const characteristics = searchItemString.split('=')[1].split('&')
    const mapOfCharacteristics = DataCollectionService.collections.characteristic!.maps.slug

    for (const characteristicString of characteristics) {
      const characteristicData = characteristicString.split(':')
      const characteristicSlug = characteristicData[0]
      const characteristic = mapOfCharacteristics[characteristicSlug] as CharacteristicDto<BaseCharacteristicDto> | undefined

      if (!characteristic) {
        continue
      }

      switch (characteristic.dtype) {
        case CharacteristicTypesEnum.singleChoice:
        case CharacteristicTypesEnum.multiChoice:
          const valueIds = characteristicData[1].split(',')

          const filterChoiceCharacteristic: FilterCharacteristicDto<SingleChoiceCharacteristicDto<BaseCharacteristicDto>> = {
            characteristic: characteristic as SingleChoiceCharacteristicDto<BaseCharacteristicDto>,
            values: {
              values: (characteristic as SingleChoiceCharacteristicDto<BaseCharacteristicDto>).values.filter(value => valueIds.includes(value.id)),
            },
          }

          subject.selectedOptions.filterCharacteristics.push(filterChoiceCharacteristic)

          continue

        case CharacteristicTypesEnum.color:
          const colorIds = characteristicData[1].split(',')

          const filterColorCharacteristic: FilterCharacteristicDto<ColorCharacteristicDto<BaseCharacteristicDto>> = {
            characteristic: characteristic as ColorCharacteristicDto<BaseCharacteristicDto>,
            values: {
              values: (characteristic as ColorCharacteristicDto<BaseCharacteristicDto>).values.filter(value => colorIds.includes(value.id)),
            },
          }

          subject.selectedOptions.filterCharacteristics.push(filterColorCharacteristic)

          continue

        case CharacteristicTypesEnum.number:
          const filterNumberCharacteristic: FilterCharacteristicDto<NumberCharacteristicDto<BaseCharacteristicDto>> = {
            characteristic: characteristic as NumberCharacteristicDto<BaseCharacteristicDto>,
            values: {
              value: this.parseFloatRange(characteristicData[1]),
            },
          }

          subject.selectedOptions.filterCharacteristics.push(filterNumberCharacteristic)

          continue

        case CharacteristicTypesEnum.checkbox:
          const filterCheckboxCharacteristic: FilterCharacteristicDto<CheckboxCharacteristicDto<BaseCharacteristicDto>> = {
            characteristic: characteristic as CheckboxCharacteristicDto<BaseCharacteristicDto>,
            values: {
              value: characteristicData[1] === 'true',
            },
          }

          subject.selectedOptions.filterCharacteristics.push(filterCheckboxCharacteristic)
      }
    }
  }
}
