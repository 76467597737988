import type { DirectiveBinding } from 'vue/types/options'
import type { VueConstructor } from 'vue'
import Vue from 'vue'
import ImgScreen from '~/components/common/v-img/v-img-screen.vue'
import type { UnknownObject } from '~/types/common'

const addPluginAttributes = (el: HTMLImageElement, binding: DirectiveBinding, options: UnknownObject) => {
  let cursor = 'pointer'
  let group = binding.arg || null
  let openOn
  let src = el.src
  let srcset
  const events: UnknownObject = {}

  openOn = options.openOn

  if (typeof binding.value !== 'undefined') {
    cursor = binding.value.cursor || cursor
    group = binding.value.group || group
    openOn = binding.value.openOn || openOn
    src = binding.value.src || src
    srcset = binding.value.srcset
    events.opened = binding.value.opened
    events.closed = binding.value.closed
    events.changed = binding.value.changed
  }

  el.setAttribute('data-vue-img-src', src)
  el.setAttribute('data-vue-img-srcset', srcset)

  if (group) {
    el.setAttribute('data-vue-img-group', group)
  }
  if (!src) {
    throw new Error('v-img element missing src parameter')
  }

  el.style.cursor = cursor

  return {
    cursor,
    src,
    srcset,
    group,
    events,
    openOn,
  }
}

const install = (Vue: VueConstructor, options: UnknownObject) => {
  const Screen = Vue.extend(ImgScreen)

  const defaultOptions = {
    openOn: 'click',
  }

  options = { ...defaultOptions, ...options }

  Vue.directive('img', {
    update(el, binding, vnode, oldVnode) {
      let srcUpdated

      if (oldVnode.data && oldVnode.data.attrs && vnode.data && vnode.data.attrs) {
        srcUpdated = oldVnode.data.attrs.src !== vnode.data.attrs.src
        // handle alt tag change only if option altAsTitle is enabled
      }

      const bindingValueUpdated = binding.oldValue !== binding.value

      if (srcUpdated || bindingValueUpdated) {
        addPluginAttributes(el as HTMLImageElement, binding, options)
      }
    },

    bind(el, binding) {
      const addedAttributes = addPluginAttributes(el as HTMLImageElement, binding, options)

      if (!window.vueImg) {
        const element = document.createElement('div')
        element.setAttribute('id', 'imageScreen')
        document.querySelector('body')!.appendChild(element)
        // @ts-ignore
        window.vueImg = new Screen().$mount('#imageScreen')
      }

      // Updating vm's data
      el.addEventListener(addedAttributes.openOn, () => {
        const images: HTMLElement[] = el.dataset.vueImgGroup
          ? Array.from(document.querySelectorAll(`img[data-vue-img-group="${el.dataset.vueImgGroup}"]`))
          : [el]

        if (!window.vueImg) {
          return
        }

        Vue.set(window.vueImg, 'srcList', images.map(e => e.dataset.vueImgSrc))
        Vue.set(window.vueImg, 'srcsetList', images.map(e => e.dataset.vueImgSrcset))
        Vue.set(window.vueImg, 'currentImageIndex', images.indexOf(el))
        Vue.set(window.vueImg, 'handlers', addedAttributes.events)
        Vue.set(window.vueImg, 'closed', false)
      })
    },
  })
}

Vue.use(install)

export default install
