import Vue from 'vue'
import Preloader from '~/components/common/directives/preloader.vue'

const dataInfoHtmlClass = 'preloader-info'

function getPreloaderComponent(title: string, color: string): Element {
  const PreloaderComponentClass = Vue.extend(Preloader)
  const instance = new PreloaderComponentClass()
  instance.$mount()

  if (title) {
    instance.$props.title = title
  }
  if (color) {
    instance.$props.color = color
  }

  const element = instance.$el
  element.classList.add(dataInfoHtmlClass)

  return element
}

function getDataInfoElements(element: Element): Element[] {
  return Array.from(element.getElementsByClassName(dataInfoHtmlClass)).filter(child => child.parentElement === element)
}

Vue.directive('preloader', {
  update: (el, binding) => {
    const dataInfoElements = getDataInfoElements(el)

    if (!binding.value.value) {
      for (const dataInfoElement of dataInfoElements) {
        // @ts-ignore
        dataInfoElement.__vue__.$destroy()
        dataInfoElement.remove()
      }

      el.classList.remove('preloader-enabled')

      return
    }

    el.classList.add('preloader-enabled')
    !dataInfoElements.length && el.append(getPreloaderComponent(binding.value.title, binding.value.color))
  },

  bind: (el, binding) => {
    const dataInfoElements = getDataInfoElements(el)

    if (!binding.value.value) {
      return
    }

    el.classList.add('preloader-enabled')
    !dataInfoElements.length && el.append(getPreloaderComponent(binding.value.title, binding.value.color))
  },
})
