import type { AdvertisementDto } from '~/types/client'
import CacheStorageService from '~/services/common/cache-storage.service'
import type { Dictionary } from '~/types/common'
import CarService from '~/api/client/car.service'

export default abstract class AdvertisementListLocalAbstract {
  abstract cacheKey: string
  abstract maxLength: number
  abstract maxLengthError: string
  private _ids: string[] = []
  private initialized = false
  private mapOfAdvertisements: Dictionary<AdvertisementDto> = {}

  async list(): Promise<AdvertisementDto[]> {
    if (!this.ids.length) {
      return []
    }

    const mappedIds = Object.keys(this.mapOfAdvertisements)
    const idsToFetch = this.ids.filter(id => !mappedIds.includes(id))

    if (!idsToFetch.length) {
      return Object.values(this.mapOfAdvertisements)
    }

    const advertisements = await this.getAdvertisements(idsToFetch)

    advertisements.forEach((car) => {
      if (!idsToFetch.includes(car.id)) {
        this.remove(car.id)
      }

      this.mapOfAdvertisements[car.id] = car
    })

    return Object.values(this.mapOfAdvertisements)
  }

  protected async getAdvertisements(idsToFetch: string[]) {
    return await CarService.listByIds(idsToFetch) as AdvertisementDto[]
  }

  count(): number {
    return this.ids.length
  }

  private commit() {
    CacheStorageService.write(this.cacheKey, this.ids)
  }

  public get ids(): string[] {
    if (!this.initialized) {
      const ids = CacheStorageService.read(this.cacheKey) as string[] | null || []
      this._ids.push(...ids)
      this.initialized = true
    }

    return this._ids
  }

  add(advertisementId: string, advertisement?: AdvertisementDto) {
    if (advertisement) {
      this.mapOfAdvertisements[advertisement.id] = advertisement
    }

    if (this.ids.includes(advertisementId)) {
      return
    }

    if (this.ids.length >= this.maxLength) {
      throw new Error(this.maxLengthError)
    }

    this.ids.push(advertisementId)

    this.commit()
  }

  remove(advertisementId: string) {
    if (advertisementId in this.mapOfAdvertisements) {
      delete this.mapOfAdvertisements[advertisementId]
    }

    const index = this.ids.indexOf(advertisementId)

    if (index < 0) {
      return
    }

    this.ids.splice(index, 1)

    this.commit()
  }

  has(advertisementId: string): boolean {
    return this.ids.includes(advertisementId)
  }
}
