import type { HeaderLinkDto } from '~/types/admin'

export interface FormHeaderLinkDto {
  id: string
  label: string
  url: string
  title: string|null
}

export function emptyFormHeaderLink(): FormHeaderLinkDto {
  return {
    id: '',
    title: null,
    url: '',
    label: '',
  }
}

export function mapHeaderLinkToForm(headerLink: HeaderLinkDto): FormHeaderLinkDto {
  return {
    id: headerLink.id,
    label: headerLink.label,
    url: headerLink.url,
    title: headerLink.title || null,
  }
}
