import type { RequestLocationDto } from '~/types/common'

export interface UserRegistrationDto {
  phone: string | null
  firstName: string | null
  lastName: string | null
  password: string | null
  facebookId?: string
  googleId?: string
  email: string | null
  location?: RequestLocationDto
}

export function emptyUserRegistrationDto(): UserRegistrationDto {
  return {
    phone: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null,
  }
}
