import type { TagDto } from '~/types/common'

export interface SelectedOptionsNewsDto {
  content: string
  tags: TagDto[]
}

export function emptySelectedOptionsNewsDto(): SelectedOptionsNewsDto {
  return {
    content: '',
    tags: [],
  }
}
