import type {
  FormBaseCharacteristicDto,
  NumberCharacteristicDto,
} from '~/types/admin'
import {
  emptyFormBaseCharacteristic,
  mapBaseCharacteristicToForm,
} from '~/types/admin'
import type { SectionDto } from '~/types/common'
import { CharacteristicTypesEnum } from '~/types/common'

export interface FormNumberCharacteristicDto extends FormBaseCharacteristicDto {
  min: number | null
  max: number | null
  unit: string | null
  pluralizable: boolean
}

export function emptyFormNumberCharacteristic(section: SectionDto): FormNumberCharacteristicDto {
  return {
    ...emptyFormBaseCharacteristic(CharacteristicTypesEnum.number, section),
    min: null,
    max: null,
    unit: null,
    pluralizable: false,
  }
}

export function mapNumberCharacteristicToForm(characteristic: NumberCharacteristicDto): FormNumberCharacteristicDto {
  return {
    ...mapBaseCharacteristicToForm(characteristic),
    min: characteristic.min !== undefined ? characteristic.min : null,
    max: characteristic.max !== undefined ? characteristic.max : null,
    unit: characteristic.unit || null,
    pluralizable: characteristic.pluralizable,
  }
}
