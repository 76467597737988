import type { DataCollectionMapperOptions } from '~/types/common'
import { DataCollectionTypeEnum } from '~/types/common'

export const profileDataCollectionOptions: DataCollectionMapperOptions = {
  'userOwnCars.*.model': DataCollectionTypeEnum.car_model,
  'dealerRoles.*.dealer': DataCollectionTypeEnum.dealer,
  'location.city': DataCollectionTypeEnum.city,
  'favoriteLists.*.filters.*.filters.category': DataCollectionTypeEnum.category,
  'favoriteLists.*.filters.*.filters.bodyType': DataCollectionTypeEnum.car_body_type,
  'favoriteLists.*.filters.*.filters.fuelType': DataCollectionTypeEnum.car_fuel_type,
  'favoriteLists.*.filters.*.filters.brandModelGroups.*.model': DataCollectionTypeEnum.car_model,
  'favoriteLists.*.filters.*.filters.brandModelGroups.*.brand': DataCollectionTypeEnum.car_brand,
  'favoriteLists.*.filters.*.filters.colorCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
  'favoriteLists.*.filters.*.filters.checkboxCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
  'favoriteLists.*.filters.*.filters.singleChoiceCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
  'favoriteLists.*.filters.*.filters.multiChoiceCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
  'favoriteLists.*.filters.*.filters.numberCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
}
