import type { RequestVideoDto, TempFileDto, VideoDto } from '~/types/common'

export interface FormVideoDto {
  youtubeUrl: string | null
  tempFile: TempFileDto | null
  video: VideoDto | null
}

export function emptyFormVideo(): FormVideoDto {
  return {
    youtubeUrl: null,
    tempFile: null,
    video: null,
  }
}

export function mapVideoToFormVideo(video: VideoDto|null): FormVideoDto {
  const formData = emptyFormVideo()

  formData.video = video

  return formData
}

export function mapFormVideoToRequest(formData: FormVideoDto): RequestVideoDto|null|undefined {
  if (formData.tempFile) {
    return {
      file: {
        tempFile: formData.tempFile.id,
      },
    }
  }

  if (formData.youtubeUrl) {
    return {
      youtubeUrl: formData.youtubeUrl,
    }
  }

  return formData.video ? undefined : null
}
