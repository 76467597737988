import type {
  RequestCharacteristicValueListDto,
  RequestImageListDto,
  RequestStatusLogDto, StatusCodeEnum,
} from '~/types/common'
import {
  mapCharacteristicValueListToRequest,
  mapFormImageListToRequest,
} from '~/types/common'
import type { FormModeratingCarDto } from '~/types/admin'

export interface RequestModeratingCarDto {
  imageList: RequestImageListDto
  currentStatusLog: RequestStatusLogDto
  currency: string
  bodyType: string
  year: number
  mileage: number | null
  description?: string
  characteristicValues: RequestCharacteristicValueListDto
}

export function mapCarModeratingFormToRequest(formData: FormModeratingCarDto, status: StatusCodeEnum, comment?: string): RequestModeratingCarDto {
  return {
    imageList: mapFormImageListToRequest(formData.imageList),
    currency: formData.currency.code,
    bodyType: formData.bodyType.id,
    characteristicValues: mapCharacteristicValueListToRequest(formData.characteristicValues),
    year: formData.year,
    mileage: formData.mileage,
    description: formData.description,
    currentStatusLog: {
      comment,
      status,
    },
  }
}
