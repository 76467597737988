export enum ImageCodeEnum {
  FRONT = 'front',
  BACK = 'back',
  LEFT_FRONT = 'left_front',
  RIGHT_FRONT = 'right_front',
  LEFT_BACK = 'left_back',
  RIGHT_BACK = 'right_back',
  SALON_FRONT = 'salon_front',
  SALON_BACK = 'salon_back',
}
