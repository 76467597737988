import type { BodyTypeDto } from '~/types/admin'

export interface RequestBodyTypeDto {
  name: string
  position?: number
  carCategory: string
  usedInSalesLeaders: boolean
  logo: string|null
}

export function getEmptyRequestBodyType(): RequestBodyTypeDto {
  return {
    name: '',
    logo: null,
    carCategory: '',
    usedInSalesLeaders: false,
  }
}

export function mapBodyTypeToRequestBodyType(bodyType: BodyTypeDto): RequestBodyTypeDto {
  return {
    name: bodyType.name,
    logo: bodyType.logo,
    carCategory: bodyType.category ? bodyType.category.id : '',
    usedInSalesLeaders: bodyType.usedInSalesLeaders,
  }
}
