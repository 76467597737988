import type { DataCollectionTypeEnum } from '~/types/common'
import DataCollectionService from '~/api/common/data-collection.service'

export function DataCollectionGetter(collectionName: DataCollectionTypeEnum) {
  return (target: object, propertyKey: string) => {
    const collection = DataCollectionService.collections[collectionName]
    Object.defineProperty(target, propertyKey, {
      value: collection ? collection.items : [],
    })
  }
}
