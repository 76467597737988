




import { Component, Prop, Vue } from 'vue-property-decorator'
import { ButtonTypeEnum } from '~/types/client'
import ClientError404 from '~/components/client/error/404.vue'
import type { BaseError } from '~/types/common'
import { ErrorEnum, PartModeEnum } from '~/types/common'
import { errorToComponent } from '~/mappers/common/error-to-component.mapper'
import { closedIndexMeta } from '~/configs/client/robots-meta'
import AdminError404 from '~/components/admin/error/404.vue'

@Component({
  components: {
    [errorToComponent(ErrorEnum.FourHundredFour, PartModeEnum.FRONT)]: ClientError404,
    [errorToComponent(ErrorEnum.FourHundredFour, PartModeEnum.ADMIN)]: AdminError404,
  },
  head(this: Error) {
    return {
      meta: [
        closedIndexMeta,
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
})
export default class Error extends Vue {
  readonly buttonTypes = ButtonTypeEnum
  $i18n: any

  @Prop({ required: true })
  readonly error!: BaseError

  get errorComponent(): string {
    if (!Object.values(ErrorEnum).includes(this.error.statusCode)) {
      return errorToComponent(ErrorEnum.FourHundredFour, this.$getPartMode())
    }

    return errorToComponent(this.error.statusCode, this.$getPartMode())
  }
}
