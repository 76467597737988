import { Component, Prop, Vue } from 'vue-property-decorator'
import { ClientAuthStoreModule } from '~/store/client/auth'
// eslint-disable-next-line @typescript-eslint/no-duplicate-imports
import type { MenuGroup, UserDataDto } from '~/types/common'
import { Colors, iconSizes } from '~/configs/client'
import { ProfileDropdownMenuTabEnum } from '~/configs/client/profile-dropdown-menu/profile-dropdown-menu-tab.enum'
import { profileDropdownMenuUser } from '~/configs/client/profile-dropdown-menu/profile-dropdown-menu-user'
import { profileDropdownMenuDealer } from '~/configs/client/profile-dropdown-menu/profile-dropdown-menu-dealer'
import { ButtonTypeEnum, IconEnum, TestDriveStatusesEnum } from '~/types/client'
import { profileDropdownMenuGuest } from '~/configs/client/profile-dropdown-menu/profile-dropdown-menu-guest'
import DealerTestDriveService from '~/api/client/dealer-test-drive.service'
import type {
  ProfileDealerRoleWithTestDriveCountsDto,
} from '~/types/common/auth/profile-dealer-role-with-test-drive-counts.dto'

@Component
export default class ProfileMenuMixin extends Vue {
    readonly tabs = ProfileDropdownMenuTabEnum
    readonly colors = Colors
    readonly iconSizes = iconSizes
    readonly icons = IconEnum
    readonly buttonTypes = ButtonTypeEnum

    dealerRolesWithTestDriveCounts: ProfileDealerRoleWithTestDriveCountsDto[] = []

    innerMenuEnabled: boolean = true

    @ClientAuthStoreModule.Getter('isDealer')
    readonly isUserDealer!: boolean

    @ClientAuthStoreModule.Getter('dealerRoles')
    readonly dealerRoles!: ProfileDealerRoleWithTestDriveCountsDto[]

    @ClientAuthStoreModule.Getter('isLoggedIn')
    readonly isLoggedIn!: boolean

    @ClientAuthStoreModule.Getter('userData')
    readonly userData!: UserDataDto

    currentDealerRole: ProfileDealerRoleWithTestDriveCountsDto | null = null

    headerLinks = [
      {
        id: '1',
        label: 'Авто з пробігом',
        type: 'profile-menu',
        url: '/used-cars',
      },
      {
        id: '2',
        label: 'Дилерські центри',
        type: 'profile-menu',
        url: '/dealer-centers',
      },
      {
        id: '3',
        label: 'Усі авто',
        type: 'profile-menu',
        url: '/catalog',
      },
      {
        id: '4',
        label: 'Нові авто',
        type: 'profile-menu',
        url: '/new-cars',
      },
      {
        id: '5',
        label: 'Новини',
        type: 'profile-menu',
        url: '/news',
      },
      {
        id: '6',
        label: 'Трейдери',
        type: 'profile-menu',
        url: '/traders',
      },
      {
        id: '7',
        label: 'Перевірені авто з гарантією',
        type: 'profile-menu',
        url: '/certification-info',
      },
    ]

    @Prop({ default: false, type: Boolean })
    readonly inMobileMenu!: boolean

    get isClient(): boolean {
      return !this.currentDealerRole
    }

    get profileMenuItems(): MenuGroup[] {
      if (!this.isLoggedIn) {
        return profileDropdownMenuGuest
      }

      return this.currentDealerRole ? profileDropdownMenuDealer(this.currentDealerRole) : profileDropdownMenuUser
    }

    async fillDealerRoleTestDriveCounts() {
      this.dealerRolesWithTestDriveCounts = []

      for (const dealerRole of this.dealerRoles) {
        const testDrives = await DealerTestDriveService.listByRole(dealerRole.id, 1, 8) || []

        const testDriveCounts = testDrives.items.filter((testDrive: any) => {
          return testDrive.status === TestDriveStatusesEnum.NEW
        }).length

        this.dealerRolesWithTestDriveCounts.push({
          dealer: dealerRole.dealer,
          dealerActive: dealerRole.dealerActive,
          id: dealerRole.id,
          role: dealerRole.role,
          testDriveCounts: testDriveCounts,
        })
      }
    }

    get dealerRoleId(): string | null {
      return this.currentDealerRole ? this.currentDealerRole.id : null
    }

    get changeVisibleInnerMenu() {
      return this.innerMenuEnabled
    }

    get activeDealers() {
      return this.dealerRolesWithTestDriveCounts.filter(dealerRole => dealerRole.dealer.active && dealerRole.dealer.approved)
    }

    activeDealerRoleMenuItem(dealerRole: ProfileDealerRoleWithTestDriveCountsDto) {
      return this.currentDealerRole && this.currentDealerRole.id === dealerRole.id
    }

    onDealerTab(dealerRole: ProfileDealerRoleWithTestDriveCountsDto) {
      this.currentDealerRole = dealerRole

      localStorage.setItem('currentDealerRoleId', dealerRole.id)
      this.toggleInnerMenu()
    }

    dealerRoleById(id: string): ProfileDealerRoleWithTestDriveCountsDto | null {
      if (!id) {
        return null
      }

      return this.dealerRolesWithTestDriveCounts.filter(dealerRole => dealerRole.id === id)[0]
    }

    onClientTab() {
      localStorage.removeItem('currentDealerRoleId')
      this.currentDealerRole = null
      this.toggleInnerMenu()
    }

    async logout() {
      await this.$authenticate().logout()
    }

    toggleInnerMenu() {
    }
}
