import { client } from '~/ports/client'
import type { DataCollectionMapperOptions } from '~/types/common'
import { mapFromDataCollections } from '~/mappers/common/map-from-data-collections.mapper'

export function ApiCreate<IndexType, RequestType>(options: DataCollectionMapperOptions = {}) {
  return function <T extends { new (...args: any[]): {} }>(constructor: T) {
    constructor.prototype.create = async (data: RequestType): Promise<IndexType | null> => {
      if (!constructor.prototype.apiPrefix || !constructor.prototype.apiOptions.responseItemName) {
        throw new Error('@Api needed parameters is not defined')
      }

      let response
      try {
        response = await client.$axios.$post(constructor.prototype.apiPrefix, data)
      } catch (e) {
        return null
      }

      const result = response[constructor.prototype.apiOptions.responseItemName]

      if (Object.entries(options).length) {
        mapFromDataCollections(result, options)
      }

      return result
    }
  }
}
