import type {
  CharacteristicDto,
  CheckboxCharacteristicDto,
  ChoiceItemDto,
  ColorCharacteristicDto, ColorItemDto,
  MultiChoiceCharacteristicDto,
  NumberCharacteristicDto,
  SingleChoiceCharacteristicDto,
  TextCharacteristicDto,
} from '~/types/common'
import isCharacteristicValueNotEmpty from '~/filters/common/is-characteristic-value-not-empty'

export type CharacteristicValueListDto<T extends CharacteristicDto = CharacteristicDto> = {
  characteristic: T
  value:CharacteristicValue<T>
  fromEquipment: boolean
}

export type CharacteristicValue<T extends CharacteristicDto = CharacteristicDto> = null |
  (T extends NumberCharacteristicDto<CharacteristicDto> ? number :
    T extends CheckboxCharacteristicDto<CharacteristicDto> ? boolean :
      T extends MultiChoiceCharacteristicDto<CharacteristicDto> ? ChoiceItemDto[] :
        T extends SingleChoiceCharacteristicDto<CharacteristicDto> ? ChoiceItemDto :
          T extends ColorCharacteristicDto<CharacteristicDto> ? ColorItemDto :
            T extends TextCharacteristicDto<CharacteristicDto> ? string :
              never)

export function emptyCharacteristicValueListDto(characteristic: CharacteristicDto): CharacteristicValueListDto {
  return { characteristic, value: null, fromEquipment: false }
}

export function mapCharacteristicValueLists(originList: CharacteristicValueListDto[], templateList: CharacteristicValueListDto[], override: boolean = true, fromEquipment: boolean = false) {
  templateList.forEach((templateCharacteristicValue) => {
    const templateCharacteristicIsEmpty = isCharacteristicValueNotEmpty(templateCharacteristicValue)

    const originCharacteristicValue = originList.find(
      originCharacteristicValue => originCharacteristicValue.characteristic.id === templateCharacteristicValue.characteristic.id,
    )

    if (!originCharacteristicValue) {
      originList.push({
        ...templateCharacteristicValue,
        fromEquipment: fromEquipment && templateCharacteristicIsEmpty,
      })

      return
    }

    if (!override && isCharacteristicValueNotEmpty(originCharacteristicValue)) {
      return
    }

    originCharacteristicValue.value = templateCharacteristicValue.value
    originCharacteristicValue.fromEquipment = fromEquipment && templateCharacteristicIsEmpty
  })

  return originList
}

export function resetEquipmentCharacteristicValue(characteristicValues: CharacteristicValueListDto[]): CharacteristicValueListDto[] {
  characteristicValues.forEach((characteristic) => {
    if (characteristic.fromEquipment) {
      characteristic.value = null
      characteristic.fromEquipment = false
    }
  })

  return characteristicValues
}
