import { Component, Prop, Vue } from 'vue-property-decorator'
import { ClientAuthStoreModule } from '~/store/client/auth'
import type { MenuGroup, ProfileDealerRoleDto } from '~/types/common'
import { Colors, iconSizes } from '~/configs/client'
import { ProfileDropdownMenuTabEnum } from '~/configs/client/profile-dropdown-menu/profile-dropdown-menu-tab.enum'
import { profileDropdownMenuUser } from '~/configs/client/profile-dropdown-menu/profile-dropdown-menu-user'
import { profileDropdownMenuDealer } from '~/configs/client/profile-dropdown-menu/profile-dropdown-menu-dealer'
import { ButtonTypeEnum, IconEnum } from '~/types/client'

@Component
export default class ProfileMenuMixin extends Vue {
  readonly tabs = ProfileDropdownMenuTabEnum
  readonly colors = Colors
  readonly iconSizes = iconSizes
  readonly icons = IconEnum
  readonly buttonTypes = ButtonTypeEnum

  innerMenuEnabled: boolean = false

  @ClientAuthStoreModule.Getter('isDealer')
  readonly isUserDealer!: boolean

  @ClientAuthStoreModule.Getter('dealerRoles')
  readonly dealerRoles!: ProfileDealerRoleDto[]

  currentDealerRole: ProfileDealerRoleDto | null = null

  @Prop({ default: false, type: Boolean })
  readonly inMobileMenu!: boolean

  get isClient(): boolean {
    return !this.currentDealerRole
  }

  get profileMenuItems(): MenuGroup[] {
    return this.currentDealerRole ? profileDropdownMenuDealer(this.currentDealerRole) : profileDropdownMenuUser
  }

  get dealerRoleId(): string | null {
    return this.currentDealerRole ? this.currentDealerRole.id : null
  }

  get changeVisibleMenuWithRole() {
    return !this.innerMenuEnabled && this.dealerRoles.length && this.activeDealers.length
  }

  get changeVisibleInnerMenu() {
    return this.innerMenuEnabled || !this.dealerRoles.length || !this.activeDealers.length
  }

  get nameActiveRole() {
    return this.currentDealerRole ? this.currentDealerRole.dealer.name : this.$t('private')
  }

  get activeDealers() {
    return this.dealerRoles.filter(dealerRole => dealerRole.dealer.active && dealerRole.dealer.approved)
  }

  activeDealerRoleMenuItem(dealerRole: ProfileDealerRoleDto) {
    return this.currentDealerRole && this.currentDealerRole === dealerRole
  }

  onDealerTab(dealerRole: ProfileDealerRoleDto) {
    this.currentDealerRole = dealerRole

    this.toggleInnerMenu()
  }

  onClientTab() {
    this.currentDealerRole = null

    this.toggleInnerMenu()
  }

  async logout() {
    await this.$authenticate().logout()
  }

  toggleInnerMenu() {
    this.innerMenuEnabled = !this.innerMenuEnabled
  }
}
