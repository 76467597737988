import type { ConditionItemDto, QuestionAnswerDto } from '~/types/common'

export type MetadataDto = {
  id: string
  metaTitle?: string
  metaDescription?: string
  metaHeader?: string
  metaType?: string
  additionalText?: string
  conditionList: ConditionItemDto[]
  questionAnswerList: QuestionAnswerDto[]
}

export const defaultMetadataDto: MetadataDto = {
  conditionList: [],
  questionAnswerList: [],
  id: '',
}
