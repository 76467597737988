import type { TagCategoryDto } from '~/types/admin'

export interface FormTagCategoryDto {
  id: string
  title: string
}

export function mapTagCategoryDtoToForm(category: TagCategoryDto): FormTagCategoryDto {
  return {
    id: category.id,
    title: category.title,
  }
}

export function emptyFormTagCategoryDto(): FormTagCategoryDto {
  return {
    id: '',
    title: '',
  }
}
