import { client } from '~/ports/client'
import type { DataCollectionMapperOptions, UnknownObject } from '~/types/common'
import { mapFromDataCollections } from '~/mappers/common/map-from-data-collections.mapper'

export function ApiList<IndexType>(options: DataCollectionMapperOptions = {}) {
  return function <T extends { new (...args: any[]): {} }>(constructor: T) {
    constructor.prototype.list = async (): Promise<IndexType[] | null> => {
      if (!constructor.prototype.apiPrefix || !constructor.prototype.apiOptions.responseItemListName) {
        throw new Error('@Api needed parameters is not defined')
      }

      let response
      try {
        response = await client.$axios.$get(constructor.prototype.apiPrefix)
      } catch (e) {
        return null
      }

      const result = response[constructor.prototype.apiOptions.responseItemListName]

      if (Object.entries(options).length) {
        result.forEach((item: UnknownObject) => mapFromDataCollections(item, options))
      }

      return result
    }
  }
}
