












import { Vue, Prop, Component } from 'vue-property-decorator'
import type { ButtonTypeEnum, ButtonSizeEnum } from '~/types/client'

@Component
export default class VBtn extends Vue {
  @Prop({ default: false })
  readonly disabled!: boolean

  @Prop({ default: 'btn' })
  readonly type!: ButtonTypeEnum

  @Prop({ default: false, type: Boolean })
  readonly noSubmit!: boolean

  @Prop()
  readonly size!: ButtonSizeEnum

  @Prop({ default: false })
  readonly isLoading!: boolean
}
