import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type { FilterSelectedOptionsCarDto, SearchDataDto, SelectedOptionsDealersDto } from '~/types/client'
import type { CityDto } from '~/types/common'
import { FilterKeysEnum } from '~/types/common'
import DataCollectionService from '~/api/common/data-collection.service'

export class CityDecodeStrategy<T extends FilterSelectedOptionsCarDto | SelectedOptionsDealersDto> extends StringDecodeStrategyAbstract<T> {
    readonly pattern = `^${FilterKeysEnum.CITY}=${this.numberArrayPattern}$`

    process(subject: SearchDataDto<T>, searchItemString: string): void {
      const data = searchItemString.split('=')
      const cityIds = data[1].split(',').map(item => parseInt(item))

      const mapOfCities = DataCollectionService.collections.city!.maps.id
      for (const cityId of cityIds) {
        const city = mapOfCities[cityId] as CityDto|undefined

        if (!city) {
          continue
        }

        subject.selectedOptions.city.push(city)
      }
    }
}
