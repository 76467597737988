import type { CurrencyDto } from '~/types/common'

class CurrencyConverterService {
  convert(price: number, sourceCurrency: CurrencyDto, targetCurrency: CurrencyDto): number {
    const currencyCoefficient = sourceCurrency.rate / targetCurrency.rate

    return Math.round(price * currencyCoefficient)
  }
}

export default new CurrencyConverterService()
