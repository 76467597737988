import type { Plugin } from '@nuxt/types'

declare module 'vue/types/vue' {
  interface Vue {
    $isAllowBodyScroll(isAllow: boolean): void
  }
}

export const disabledBodyScrollClassName = 'js-no-body-scroll'

const $isAllowBodyScroll: Plugin = function (_context, inject) {
  inject('isAllowBodyScroll', (isAllow: boolean) => {
    isAllow ? document.body.classList.remove(disabledBodyScrollClassName) : document.body.classList.add(disabledBodyScrollClassName)
  })
}

export default $isAllowBodyScroll
