import { store } from '~/ports/store'
import type { BrandDto, ModelDto, RawRecentlyViewedBrandModelDto } from '~/types/client'
import type { StorageContract } from '~/services/common/storage/storage.contract'
import localStorageServiceProvider from '~/services/common/storage/local-storage-service.provider'
import type { UnknownObject } from '~/types/common'

class RecentlyViewedBrandModelDataService {
  private readonly storageKey = 'recentlyViewedBrandModel'
  private storageData: RawRecentlyViewedBrandModelDto[] | null = null
  private countRecentlyViewedItems = 5

  constructor(private readonly storage: StorageContract) {}

  addBrandModel(brand: BrandDto, model: ModelDto | null): void {
    store.$store.commit(
      'client/recently-viewed/addBrandModel',
      {
        brand: brand.id,
        model: model ? model.id : null,
        limit: this.countRecentlyViewedItems,
      },
    )

    this.writeToStorage()
  }

  getDataFromStorage(): RawRecentlyViewedBrandModelDto[] {
    if (!this.storageData) {
      const rawString = this.storage.get<string>(this.storageKey)
      if (!rawString) {
        return []
      }

      let data: UnknownObject[] = JSON.parse(rawString)

      if (!Array.isArray(data)) {
        return []
      }

      // checking old version of RecentlyViewedBrandModel
      data = data.map((item) => {
        return ('brand' in item) ? item : { brand: item.id, models: item.models }
      })

      this.storageData = <unknown>data as RawRecentlyViewedBrandModelDto[]
    }

    return this.storageData
  }

  writeToStorage() {
    this.storage.set(this.storageKey, JSON.stringify(this.getDataFromStorage()))
  }
}

export default new RecentlyViewedBrandModelDataService(localStorageServiceProvider())
