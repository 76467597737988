import type { FileDto, FormFileDto, MultiSizeImageDto } from '~/types/common'

export interface RequestFileDto {
  tempFile?: string
  existedFile?: string
}

export function mapFormFileToRequest(formData: FormFileDto<MultiSizeImageDto | FileDto>): RequestFileDto | undefined | null {
  if (formData.newFile?.tempFile || formData.newFile?.existedFile) {
    return {
      existedFile: formData.newFile.existedFile?.id,
      tempFile: formData.newFile.tempFile?.id,
    }
  }

  if (formData.file) {
    return undefined // to not change the file on server
  }

  return null // to delete file from server
}
