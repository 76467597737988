import type {
  RequestContentBlockDto,
  RequestImageContentBlockDto,
  RequestTextContentBlockDto,
} from '~/types/common'
import {
  ContentBlockTypeEnum,
  isFormImageContentBlock,
  isFormTextContentBlock,
  mapFormFileToRequest,
} from '~/types/common'

export interface CreateBaseContentBlockDto {
  type: ContentBlockTypeEnum
  position?: number
}

export function mapCreateFormContentToRequestDto(contentBlock: CreateBaseContentBlockDto): RequestContentBlockDto<CreateBaseContentBlockDto> | null {
  if (isFormTextContentBlock(contentBlock)) {
    return {
      type: ContentBlockTypeEnum.TEXT,
      text: contentBlock.text,
      position: contentBlock.position,
    } as RequestTextContentBlockDto
  }

  if (isFormImageContentBlock(contentBlock)) {
    return {
      type: ContentBlockTypeEnum.IMAGE,
      position: contentBlock.position,
      image: mapFormFileToRequest(contentBlock.image),
      caption: contentBlock.caption,
    } as RequestImageContentBlockDto
  }

  return null
}
