








import { Component, Vue } from 'vue-property-decorator'
import HeaderMain from '~/components/client/header/header-main.vue'
import FooterMain from '~/components/client/footer/footer-main.vue'
import { closedIndexMeta } from '~/configs/client/robots-meta'

@Component({
  components: {
    FooterMain,
    HeaderMain,
  },
  head(this: DefaultLayout) {
    return {
      meta: [
        closedIndexMeta,
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
})
export default class DefaultLayout extends Vue {
  $i18n: any
}
