import MobileDetect from 'mobile-detect'
import { PlatformEnum } from '~/types/common'

class DeviceDetectService {
  private _mobileDetect: MobileDetect | null = null

  isMobile(): boolean {
    return !!this.mobileDetect.mobile() && !this.isTablet()
  }

  isTablet(): boolean {
    return !!this.mobileDetect.tablet()
  }

  isDesktop(): boolean {
    return !this.isMobile() && !this.isTablet()
  }

  isLessThenMedium(): boolean {
    return window.innerWidth < 992
  }

  isIOS(): boolean {
    return this.mobileDetect.os() === PlatformEnum.IOS
  }

  isAndroid(): boolean {
    return this.mobileDetect.os() === PlatformEnum.ANDROID
  }

  isPrerenderer(): boolean {
    return window.navigator.userAgent.toLowerCase() === 'prerenderer'
  }

  get mobileDetect(): MobileDetect {
    if (!this._mobileDetect) {
      this._mobileDetect = new MobileDetect(window.navigator.userAgent)
    }

    return this._mobileDetect
  }
}

export default new DeviceDetectService()
