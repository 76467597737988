export const transliterationKeys = new Map<string, string>([
  ['А', 'A'],
  ['В', 'B'],
  ['С', 'C'],
  ['І', 'I'],
  ['Е', 'E'],
  ['Н', 'H'],
  ['К', 'K'],
  ['М', 'M'],
  ['О', 'O'],
  ['Р', 'P'],
  ['Т', 'T'],
  ['Х', 'X'],
  ['У', 'Y'],
])
