import LZString from 'lz-string'
import JsonService from '~/services/common/json.service'
import type { StorageContract } from '~/services/common/storage/storage.contract'
import localStorageServiceProvider from '~/services/common/storage/local-storage-service.provider'

class CacheStorageService {
  constructor(private readonly storage: StorageContract) {
  }

  write(key: string, value: unknown) {
    if (value === null || value === undefined) {
      return
    }

    this.storage.set(key, LZString.compressToUTF16(JsonService.stringify(value)))
  }

  read<T = unknown>(key: string): T | null {
    const encodedValue = this.storage.get(key) as string
    if (encodedValue === null || encodedValue === undefined) {
      return null
    }

    return JsonService.parse(LZString.decompressFromUTF16(encodedValue) || '') as T
  }

  size(key: string): number {
    return this.storage.getSize(key)
  }
}

export default new CacheStorageService(localStorageServiceProvider())
