import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type { FilterSelectedOptionsCarDto, SearchDataDto, SelectedOptionsDealersDto } from '~/types/client'
import type { SelectedOptionsNewsDto } from '~/types/common'
import { FilterKeysEnum } from '~/types/common'

export class PageDecodeStrategy<T extends FilterSelectedOptionsCarDto | SelectedOptionsDealersDto | SelectedOptionsNewsDto> extends StringDecodeStrategyAbstract<T> {
  readonly pattern = `^${FilterKeysEnum.PAGE}-\\d+$`

  process(subject: SearchDataDto<T>, searchItemString: string): void {
    subject.page = parseInt(searchItemString.split('-')[1])
  }
}
