import type { CharacteristicTypesEnum, RequestFileDto } from '~/types/common'
import { mapFormFileToRequest } from '~/types/common'
import type { FormBaseCharacteristicDto } from '~/types/admin'

export interface RequestBaseCharacteristicDto {
  section: string
  name: string
  label: string
  active: boolean
  filter: boolean
  required: boolean
  showInShortView: boolean
  option: boolean
  logo?: RequestFileDto | null
  dtype: CharacteristicTypesEnum
  position?: number
  useForICE: boolean
  useForElectric: boolean
}

export function mapFormBaseCharacteristicToRequest(formData: FormBaseCharacteristicDto): RequestBaseCharacteristicDto {
  return {
    section: formData.section.id,
    name: formData.name!,
    label: formData.label!,
    active: formData.active,
    required: formData.required,
    showInShortView: formData.showInShortView,
    option: formData.option,
    filter: formData.filter,
    useForICE: formData.useForICE,
    useForElectric: formData.useForElectric,
    logo: mapFormFileToRequest(formData.logo),
    dtype: formData.dtype!,
  }
}
