import { Singleton } from 'typescript-ioc'
import {
  CharacteristicTypesEnum,
  DataCollectionMappingFieldEnum,
  DataCollectionTypeEnum,
  emptyFilterNumberRangeDto, isFilterNumberRangeDtoEmpty,
} from '~/types/common'
import type {
  Dictionary, RegionDto, CityDto,
  NumberRangeDto,
  ChoiceItemDto, ColorItemDto,
  SortOptionDto,
} from '~/types/common'
import type {
  BodyTypeDto,
  BrandDto,
  CarCategoryDto,
  ModelDto,
  FilterSelectedOptionsCarDto,
  SearchDataDto,
} from '~/types/client'
import DataCollectionService from '~/api/common/data-collection.service'
import type {
  CatalogCanonicalUrlParamsDto,
  NumberRangeKeys,
} from '~/modules/common/canonical-url/type/catalog-canonical-url-params.dto'
import { emptyCatalogCanonicalUrlParamsDto } from '~/modules/common/canonical-url/type/catalog-canonical-url-params.dto'
import { areCarSortOptionsEqual, carSortOptions, defaultCarSortOption } from '~/configs/client'
import CurrencyPriceHelperConverterService from '~/services/common/currency-price-helper-converter.service'

@Singleton
export class CanonicalUrlService {
  private readonly collections = DataCollectionService.collections

  public buildParams(canonicalUrlParams: CatalogCanonicalUrlParamsDto): Dictionary<string> {
    const params: Dictionary<string> = {}
    const values: string[] = [
      canonicalUrlParams.category ? canonicalUrlParams.category.slug : '',
      canonicalUrlParams.region ? canonicalUrlParams.region.slug : '',
      // @ts-ignore
      canonicalUrlParams.city ? canonicalUrlParams.city.id.toString() : '',
      canonicalUrlParams.bodyType ? canonicalUrlParams.bodyType.slug : '',
      canonicalUrlParams.brand ? canonicalUrlParams.brand.slug : '',
      canonicalUrlParams.brand && canonicalUrlParams.model ? this.prepareModelSlug(canonicalUrlParams.brand, canonicalUrlParams.model) : '',
      canonicalUrlParams.year && !isFilterNumberRangeDtoEmpty(canonicalUrlParams.year) ? this.prepareRangeSlug(canonicalUrlParams.year, 'year') : '',
      canonicalUrlParams.mileage && !isFilterNumberRangeDtoEmpty(canonicalUrlParams.mileage) ? this.prepareRangeSlug(canonicalUrlParams.mileage, 'mileage') : '',
      canonicalUrlParams.price && !isFilterNumberRangeDtoEmpty(canonicalUrlParams.price) ? this.preparePriceRangeSlug(canonicalUrlParams.price, 'price') : '',
      canonicalUrlParams.order ? this.prepareOrderSlug(canonicalUrlParams.order) : '',
      canonicalUrlParams.page ? this.preparePageSlug(canonicalUrlParams.page) : '',
      canonicalUrlParams.isCertificated ? 'certificated' : '',
    ]

    const paramKeys: string[] = [
      'query',
      'region',
      'city',
      'bodyType',
      'brand',
      'model',
      'year',
      'mileage',
      'price',
      'order',
      'page',
      'certificated',
    ]

    for (const value of values) {
      if (!paramKeys.length || !value) {
        continue
      }

      params[paramKeys[0]] = value
      paramKeys.shift()
    }

    return params
  }

  public parseParams(slug1?: string, slug2?: string, slug3?: string, slug4?: string, slug5?: string, slug6?: string, slug7?: string, slug8?: string, slug9?: string, slug10?: string, slug11?: string, slug12?: string): CatalogCanonicalUrlParamsDto {
    const parsedParams: CatalogCanonicalUrlParamsDto = {}
    const processFunctionList: ((slug: string) => boolean)[] = [
      this.parseCategory(parsedParams),
      this.parseRegion(parsedParams),
      this.parseCity(parsedParams),
      this.parseBodyType(parsedParams),
      this.parseBrand(parsedParams),
      this.parseModel(parsedParams),
      this.parseRange(parsedParams, 'year', 'year'),
      this.parseRange(parsedParams, 'mileage', 'mileage'),
      this.parsePriceRange(parsedParams, 'price', 'price'),
      this.parsePage(parsedParams),
      this.parseOrder(parsedParams),
      this.parseCertificated(parsedParams),
    ]
    const slugList: (string | undefined)[] = [slug1, slug2, slug3, slug4, slug5, slug6, slug7, slug8, slug9, slug10, slug11, slug12]

    for (const slug of slugList) {
      if (!slug) {
        return parsedParams
      }

      const index = processFunctionList.findIndex(processFunction => processFunction(slug))

      processFunctionList.splice(index, 1)
    }

    return parsedParams
  }

  public prepareRangeSlug(range: NumberRangeDto, prefix: string): string {
    if (!range.min && range.max) {
      return `${prefix}-${range.max}`
    } else if (range.min && !range.max) {
      return `${prefix}-from-${range.min}`
    }

    return `${prefix}-${range.min}-${range.max}`
  }

  public preparePriceRangeSlug(range: NumberRangeDto, prefix: string): string {
    if (!range.min && range.max) {
      return `${prefix}-${CurrencyPriceHelperConverterService.unconvert(range.max)}`
    } else if (range.min && !range.max) {
      return `${prefix}-from-${CurrencyPriceHelperConverterService.unconvert(range.min)}`
    }

    return `${prefix}-${CurrencyPriceHelperConverterService.unconvert(range.min)}-${CurrencyPriceHelperConverterService.unconvert(range.max)}`
  }

  public prepareModelSlug(brand: BrandDto, model: ModelDto): string {
    const modelSlugWithoutBrand = model.slug.replace(`${brand.slug}-`, '')

    if (isNaN(Number(modelSlugWithoutBrand))) {
      return modelSlugWithoutBrand
    } else {
      return model.slug
    }
  }

  private parseRegion(processingParams: CatalogCanonicalUrlParamsDto): (slug: string) => boolean {
    return (slug: string) => {
      const region = this.getDataCollectionItemByMapping(DataCollectionTypeEnum.region, DataCollectionMappingFieldEnum.SLUG, slug)

      if (!region) {
        return false
      }

      processingParams.region = region as RegionDto

      return true
    }
  }

  private parseCertificated(processingParams: CatalogCanonicalUrlParamsDto): (slug: string) => boolean {
    return (slug: string) => {
      if (slug !== 'certificated') {
        return false
      }

      processingParams.isCertificated = true as boolean

      return true
    }
  }

  private parseCity(processingParams: CatalogCanonicalUrlParamsDto): (slug: string) => boolean {
    return (slug: string) => {
      const city = this.getDataCollectionItemByMapping(DataCollectionTypeEnum.city, DataCollectionMappingFieldEnum.SLUG, slug) ??
              this.getDataCollectionItemByMapping(DataCollectionTypeEnum.city, DataCollectionMappingFieldEnum.ID, slug)
      if (!city) {
        return false
      }
      processingParams.city = city as CityDto

      return true
    }
  }

  private parseCategory(processingParams: CatalogCanonicalUrlParamsDto): (slug: string) => boolean {
    return (slug: string) => {
      const category = this.getDataCollectionItemByMapping(DataCollectionTypeEnum.category, DataCollectionMappingFieldEnum.SLUG, slug)

      if (!category) {
        return false
      }

      processingParams.category = category as CarCategoryDto

      return true
    }
  }

  private parseBodyType(processingParams: CatalogCanonicalUrlParamsDto): (slug: string) => boolean {
    return (slug: string) => {
      const bodyType = this.getDataCollectionItemByMapping(DataCollectionTypeEnum.car_body_type, DataCollectionMappingFieldEnum.SLUG, slug)

      if (!bodyType) {
        return false
      }

      processingParams.bodyType = bodyType as BodyTypeDto

      return true
    }
  }

  private parseBrand(processingParams: CatalogCanonicalUrlParamsDto): (slug: string) => boolean {
    return (slug: string) => {
      const brand = this.getDataCollectionItemByMapping(DataCollectionTypeEnum.car_brand, DataCollectionMappingFieldEnum.SLUG, slug)

      if (!brand) {
        return false
      }

      processingParams.brand = brand as BrandDto

      return true
    }
  }

  private parseRange(
    processingParams: CatalogCanonicalUrlParamsDto,
    propertyName: keyof NumberRangeKeys,
    rangeName: string,
  ): (slug: string) => boolean {
    return (slug: string) => {
      if (!slug.includes(rangeName)) {
        return false
      }

      const matches = slug.match(/\d+/g)

      if (!matches) {
        return false
      }

      const range = matches.slice(0, 2).map((value: string) => parseInt(value))

      const rangePram = emptyFilterNumberRangeDto()

      if (range.length === 1) {
        if (slug.includes('from')) {
          rangePram.min = range[0]
        } else {
          rangePram.max = range[0]
        }
      } else {
        rangePram.min = range[0]
        rangePram.max = range[1]
      }

      processingParams[propertyName] = rangePram

      return true
    }
  }

  private parsePriceRange(
    processingParams: CatalogCanonicalUrlParamsDto,
    propertyName: keyof NumberRangeKeys,
    rangeName: string,
  ): (slug: string) => boolean {
    return (slug: string) => {
      if (!slug.includes(rangeName)) {
        return false
      }

      const matches = slug.match(/\d+/g)

      if (!matches) {
        return false
      }

      const range = matches.slice(0, 2).map((value: string) => parseInt(value))

      const rangePram = emptyFilterNumberRangeDto()

      if (range.length === 1) {
        if (slug.includes('from')) {
          rangePram.min = CurrencyPriceHelperConverterService.convert(range[0])
        } else {
          rangePram.max = CurrencyPriceHelperConverterService.convert(range[0])
        }
      } else {
        rangePram.min = CurrencyPriceHelperConverterService.convert(range[0])
        rangePram.max = CurrencyPriceHelperConverterService.convert(range[1])
      }

      processingParams[propertyName] = rangePram

      return true
    }
  }

  private parseModel(processingParams: CatalogCanonicalUrlParamsDto): (slug: string) => boolean {
    return (slug: string) => {
      if (!processingParams.brand) {
        return false
      }

      let computedSlug
      if (slug.includes(processingParams.brand.slug)) {
        computedSlug = slug
      } else {
        computedSlug = `${processingParams.brand.slug}-${slug}`
      }

      const model = this.getDataCollectionItemByMapping(DataCollectionTypeEnum.car_model, DataCollectionMappingFieldEnum.SLUG, computedSlug)

      if (!model) {
        return false
      }

      processingParams.model = model as ModelDto

      return true
    }
  }

  public getDataCollectionItemByMapping(type: DataCollectionTypeEnum, fieldName: DataCollectionMappingFieldEnum, fieldKey: string): unknown|undefined {
    return this.collections[type]!.maps[fieldName][fieldKey]
  }

  public tryCanonLink(type: string, searchData: SearchDataDto<FilterSelectedOptionsCarDto>) {
    const canonicalUrlParams = emptyCatalogCanonicalUrlParamsDto()
    let text!: string

    if (this.hasNonCanonicalOptionSelected(searchData)) {
      return false
    }

    if (searchData.sortOption) {
      canonicalUrlParams.order = searchData.sortOption
    }

    if (searchData.selectedOptions.category) {
      canonicalUrlParams.category = searchData.selectedOptions.category
      text = searchData.selectedOptions.category.name
    }

    if (searchData.selectedOptions.region[0] && !searchData.selectedOptions.region[1]) {
      canonicalUrlParams.region = searchData.selectedOptions.region[0]
      text = searchData.selectedOptions.region[0].areaName
    }

    if (searchData.selectedOptions.city[0] && !searchData.selectedOptions.city[1]) {
      canonicalUrlParams.city = searchData.selectedOptions.city[0]
      text = searchData.selectedOptions.city[0].name
    }

    if (searchData.selectedOptions.bodyTypes[0] && !searchData.selectedOptions.bodyTypes[1]) {
      canonicalUrlParams.bodyType = searchData.selectedOptions.bodyTypes[0]
      text = searchData.selectedOptions.bodyTypes[0].name
    }

    if (
      searchData.selectedOptions.brandModelGroups[0]
        && searchData.selectedOptions.brandModelGroups[0].brand
        && !searchData.selectedOptions.brandModelGroups[1]
    ) {
      canonicalUrlParams.brand = searchData.selectedOptions.brandModelGroups[0].brand
      text = searchData.selectedOptions.brandModelGroups[0].brand.name

      if (searchData.selectedOptions.brandModelGroups[0].model) {
        canonicalUrlParams.model = searchData.selectedOptions.brandModelGroups[0].model
        text = searchData.selectedOptions.brandModelGroups[0].model?.name
      }
    }

    if (
      searchData.selectedOptions.brandModelGroups[0]
        && searchData.selectedOptions.brandModelGroups[0].year
        && !searchData.selectedOptions.brandModelGroups[1]
    ) {
      if (searchData.selectedOptions.brandModelGroups[0].year) {
        canonicalUrlParams.year = searchData.selectedOptions.brandModelGroups[0].year
      }
    }

    if (
      searchData.selectedOptions.brandModelGroups[0]
        && searchData.selectedOptions.brandModelGroups[0].mileage
        && !searchData.selectedOptions.brandModelGroups[1]
    ) {
      if (searchData.selectedOptions.brandModelGroups[0].mileage) {
        canonicalUrlParams.mileage = searchData.selectedOptions.brandModelGroups[0].mileage
      }
    }

    if (
      searchData.selectedOptions.brandModelGroups[0]
        && searchData.selectedOptions.brandModelGroups[0].price
        && !searchData.selectedOptions.brandModelGroups[1]
    ) {
      if (searchData.selectedOptions.brandModelGroups[0].price) {
        canonicalUrlParams.price = searchData.selectedOptions.brandModelGroups[0].price
      }
    }

    if (searchData.selectedOptions.isCertificated) {
      canonicalUrlParams.isCertificated = searchData.selectedOptions.isCertificated
    }

    return {
      text: text,
      to: {
        name: 'client-type-query',
        params: {
          type: type,
          ...this.buildParams(canonicalUrlParams),
        },
      },
    }
  }

  private hasNonCanonicalOptionSelected(searchData: SearchDataDto<FilterSelectedOptionsCarDto>): boolean {
    if (
      searchData.selectedOptions.fuelTypes[0]
        || searchData.selectedOptions.location
        || (searchData.selectedOptions.region[0] && searchData.selectedOptions.region[1])
        || (searchData.selectedOptions.city[0] && searchData.selectedOptions.city[1])
        || (searchData.selectedOptions.bodyTypes[0] && searchData.selectedOptions.bodyTypes[1])
        || (searchData.selectedOptions.brandModelGroups[0] && searchData.selectedOptions.brandModelGroups[1])
        || (Object.keys(searchData.selectedOptions.marks).length)
    ) {
      return true
    }

    for (const item of searchData.selectedOptions.filterCharacteristics) {
      if (!Object.entries(item.values).length || (!item.values.value && !item.values.values)) {
        continue
      }

      switch (item.characteristic.dtype) {
        case CharacteristicTypesEnum.singleChoice:
        case CharacteristicTypesEnum.multiChoice:
          if (!(<unknown>item.values.values as ChoiceItemDto[]).length) {
            continue
          }
          break
        case CharacteristicTypesEnum.number:
          if (isFilterNumberRangeDtoEmpty(<unknown>item.values.value as NumberRangeDto)) {
            continue
          }
          break
        case CharacteristicTypesEnum.color:
          if (!(<unknown>item.values.values as ColorItemDto[]).length) {
            continue
          }
          break
      }

      return true
    }

    return false
  }

  private parsePage(processingParams: CatalogCanonicalUrlParamsDto): (slug: string) => boolean {
    return (slug: string) => {
      if (!slug.includes('page-')) {
        return false
      }
      const match = slug.match(/\d+/g)

      if (match && match.length) {
        processingParams.page = Number(match[0])

        return true
      }

      return false
    }
  }

  orderKeyReplacements: Dictionary<string> = {
    propulsionAt: 'propulsion-at',
  }

  private parseOrder(processingParams: CatalogCanonicalUrlParamsDto): (slug: string) => boolean {
    return (slug: string) => {
      if (!slug.includes('order-')) {
        return false
      }
      const matches = slug.match(/order-([\w-]+)-(\w+)/)

      if (matches && matches.length === 3) {
        const keyToFind = this.orderKeyReplacements[matches[1]] || matches[1]
        processingParams.order = carSortOptions().filter(item => item.key === keyToFind && item.direction === matches[2])[0]

        return true
      }

      return false
    }
  }

  private prepareOrderSlug(order: SortOptionDto) {
    if (areCarSortOptionsEqual(order, defaultCarSortOption())) {
      return ''
    }

    return `order-${this.orderKeyReplacements[order.key as string] || order.key}-${order.direction}`
  }

  private preparePageSlug(page: number) {
    return `page-${page}`
  }
}
