import type { DataCollectionMappingOptions } from '~/types/common'
import { DataCollectionTypeEnum } from '~/types/common'

export const dataCollectionMappingOptions: DataCollectionMappingOptions = {
  [DataCollectionTypeEnum.category]: {
    mappingFields: ['slug'],
  },
  [DataCollectionTypeEnum.car_body_type]: {
    mappingFields: ['slug'],
  },
  [DataCollectionTypeEnum.car_fuel_type]: {
    mappingFields: ['slug'],
  },
  [DataCollectionTypeEnum.car_brand]: {
    mappingFields: ['slug'],
  },
  [DataCollectionTypeEnum.car_model]: {
    mappingFields: ['slug'],
  },
  [DataCollectionTypeEnum.characteristic]: {
    mappingFields: ['slug'],
  },
  [DataCollectionTypeEnum.region]: {
    mappingFields: ['slug'],
  },
  [DataCollectionTypeEnum.city]: {
    mappingFields: ['slug'],
  },
}
