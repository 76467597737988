// @ts-ignore
import Toasted from 'vue-toasted/dist/toasted.js'
import type { ToastOptions } from 'vue-toasted'
import type { ToastObject } from '~/node_modules/vue-toasted'
import type { Toasted as ToastInterface } from '~/plugins/toasted/type/index'
import { ToastedTypesEnum } from '~/plugins/toasted/type/index'

const defaultOptions: ToastOptions = {
  duration: 3 * 1000,
  closeOnSwipe: true,
}

const toastedInstance = new Toasted(defaultOptions)

function escapeHtml(text: string) {
  return text
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

function show(message: string, options?: ToastOptions, withHtml: boolean = false) {
  return toastedInstance.show(withHtml ? message : escapeHtml(message), options)
}

export const toastedDecorator: ToastInterface = {
  show: (message: string, options: ToastOptions = {}, withHtml: boolean = false): ToastObject => {
    return show(message, options, withHtml)
  },
  success: (message: string, options: ToastOptions = {}, withHtml: boolean = false): ToastObject => {
    options.type = ToastedTypesEnum.SUCCESS

    return show(message, options, withHtml)
  },
  info: (message: string, options: ToastOptions = {}, withHtml: boolean = false): ToastObject => {
    options.type = ToastedTypesEnum.INFO

    return show(message, options, withHtml)
  },
  error: (message: string, options: ToastOptions = {}, withHtml: boolean = false): ToastObject => {
    options.type = ToastedTypesEnum.ERROR

    return show(message, options, withHtml)
  },
  register: (name: string, message: string | ((payload: any) => string), options?: ToastOptions, withHtml?: boolean): void => {
    if (typeof message === 'string') {
      return toastedInstance.register(name, withHtml ? message : escapeHtml(message), options)
    }

    return toastedInstance.register(name, withHtml ? message : (payload: any) => escapeHtml(message(payload)), options)
  },
  clear: (): boolean => {
    return toastedInstance.clear()
  },
}
