import type { CancelTokenSource } from 'axios'
import axios from 'axios'
import type { ActionTree, ActionContext, MutationTree, GetterTree } from 'vuex'
import { namespace } from '~/node_modules/vuex-class'
import type {
  CarState,
  CarDto,
  FullCarDto,
} from '~/types/client'
import {
  emptyCarState, isFullCar,
} from '~/types/client'
import type { RootState, MultiSizeImageDto } from '~/types/common'
import CarService from '~/api/client/car.service'
import ClientCarService from '~/api/client/client-car.service'
import CarSearchService from '~/services/client/car-search-result.service'

let showCarCancellationSource: CancelTokenSource | null = null

export const state = (): CarState => emptyCarState()

export const actions: ActionTree<CarState, RootState> = {
  async fetchCurrentCar({ commit, dispatch }: ActionContext<CarState, RootState>, id: string) {
    if (!CarSearchService.items.length) {
      await dispatch('loadCar', id)

      return
    }

    CarSearchService.currentCarId = id
    commit('setCurrentCar', CarSearchService.currentCar)
    await dispatch('loadCar', id)
  },

  async loadCar({ commit, rootState }: ActionContext<CarState, RootState>, id: string) {
    commit('setCurrentCar', null)
    const isLoggedIn = rootState.client.auth.isLoggedIn
    let car: FullCarDto | null = null

    if (showCarCancellationSource) {
      showCarCancellationSource.cancel()
    }

    showCarCancellationSource = axios.CancelToken.source()

    car = isLoggedIn
      ? await ClientCarService.show(id, showCarCancellationSource.token)
      : await CarService.show(id, showCarCancellationSource.token)

    if (!car) {
      return
    }

    commit('setCurrentCar', car)
  },

  async fetchRecommendedCars({ commit }: ActionContext<CarState, RootState>, currentCarId: string) {
    const response: CarDto[] | null = await CarService.similarListById(currentCarId)
    commit('setRecommendedCars', response)
  },
}

export const mutations: MutationTree<CarState> = {
  setCurrentCar(state: CarState, car: FullCarDto | CarDto | null) {
    state.currentCar = car
  },

  setRecommendedCars(state: CarState, cars: CarDto[] | null) {
    state.recommendedCars = cars
  },

  setLikes(state: CarState, likes: number) {
    if (!state.currentCar || !('likes' in state.currentCar)) {
      return
    }

    (state.currentCar as FullCarDto).likes = likes
  },
}

export const getters: GetterTree<CarState, RootState> = {
  currentCar(state: CarState): CarDto | null {
    return state.currentCar
  },

  images(state: CarState): MultiSizeImageDto[] {
    if (!state.currentCar) {
      return []
    }

    return state.currentCar.imageList.images.map(image => image.file)
  },

  recommendedCars(state: CarState): CarDto[] | null {
    return state.recommendedCars || []
  },

  isDealersCar(state: CarState): boolean {
    return !!(state.currentCar && isFullCar(state.currentCar) && state.currentCar.dealerRole)
  },
}

export const ClientCarModule = namespace('client/car')
