import type { CharacteristicValueListDto, NumberCharacteristicDto } from '~/types/common'
import { CharacteristicTypesEnum } from '~/types/common'
import type { ParseCharacteristicValueStrategyInterface } from '~/plugins/formatter/characteristic-value/parse-characteristic-value-strategy/parse-characteristic-value-strategy.interface'

class ParseNumberCharacteristicValueStrategy implements ParseCharacteristicValueStrategyInterface {
  support(type: CharacteristicTypesEnum): boolean {
    return type === CharacteristicTypesEnum.number
  }

  parse(valueList: CharacteristicValueListDto): string {
    if (!valueList.value) {
      return '--'
    }

    let value = String(valueList.value)
    const characteristic = valueList.characteristic as NumberCharacteristicDto

    if (characteristic.unit) {
      value += ` ${characteristic.unit}`
    }

    return value
  }
}

export default new ParseNumberCharacteristicValueStrategy()
