export interface StepsFormWithAuthStatePayloadDto {
  code: string | null,
  phone: string | null,
  firstName: string | null,
  lastName: string | null,
  confirmationCodeButtonId: string | null,
  newPassword: string | null,
  recaptchaToken: string | null,
}

export function emptyStepsFormWithAuthStatePayload(): StepsFormWithAuthStatePayloadDto {
  return {
    code: null,
    phone: null,
    firstName: null,
    lastName: null,
    confirmationCodeButtonId: null,
    newPassword: null,
    recaptchaToken: '',
  }
}
