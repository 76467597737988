











  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { i18n } from '~/ports/i18n'

@Component
  export default class NoDataComponent extends Vue {
  @Prop({ default: 'nothing-to-show' })
  readonly title!: string

  get i18n() {
    return i18n.i18n
  }
  }
