import { emptyUserRegistrationDto, RegistrationStepEnum } from '~/types/client'
import type { UserRegistrationDto } from '~/types/client'
import type { FormDealerDto, SocialDataResponseDto } from '~/types/common'

export interface RegistrationDto {
  step?: RegistrationStepEnum
  code: string | null
  user: UserRegistrationDto
  dealer?: FormDealerDto
}

export function emptyRegistrationDto(): RegistrationDto {
  return {
    step: RegistrationStepEnum.ENTER_PHONE,
    code: null,
    user: emptyUserRegistrationDto(),
  }
}

export function mapSocialDataToRegistrationDto(registrationData: RegistrationDto, socialData: SocialDataResponseDto) {
  registrationData.step = RegistrationStepEnum.SOCIAL_PHONE
  registrationData.user.firstName = socialData.firstName || null
  registrationData.user.lastName = socialData.lastName || null
  registrationData.user.facebookId = socialData.facebookId
  registrationData.user.googleId = socialData.googleId
  registrationData.user.email = socialData.email || null
}
