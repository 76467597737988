import type { RequestFileDto } from '~/types/common'
import { mapFormFileToRequest } from '~/types/common'
import type { FormSettingDto } from '~/types/admin'

export interface RequestSettingDto<DataType = unknown> {
  data: DataType
  file?: RequestFileDto | null
}

export function mapFormSettingToRequest<DataType = unknown>(formData: FormSettingDto<DataType>): RequestSettingDto<DataType> {
  return {
    data: formData.data,
    file: mapFormFileToRequest(formData.file),
  }
}
