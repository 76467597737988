import type { DataCollectionMapperOptions, UnknownObject } from '~/types/common'
import { mapFromDataCollection } from '~/mappers/common/map-from-data-collection.mapper'

export function mapFromDataCollections(valueToMap: UnknownObject, sources: DataCollectionMapperOptions) {
  if (!isObject(valueToMap)) {
    return
  }

  for (const [propertyPath, dataCollectionMapperOption] of Object.entries(sources)) {
    const propertyPathParts = propertyPath.split('.')
    const firstLevelPropertyName = propertyPathParts.shift()!

    if (propertyPathParts.length === 0) {
      const subValueToMap = valueToMap[firstLevelPropertyName]

      if (!subValueToMap) {
        continue
      }

      if (typeof dataCollectionMapperOption === 'function') {
        dataCollectionMapperOption(subValueToMap)
        continue
      }

      valueToMap[propertyPath] = mapFromDataCollection(subValueToMap as (string | number), dataCollectionMapperOption)
      continue
    }

    const subPropertyPath = propertyPathParts.join('.')
    const subSources = { [subPropertyPath]: dataCollectionMapperOption }

    if (firstLevelPropertyName === '*' && Array.isArray(valueToMap)) {
      for (const subValueToMap of valueToMap as UnknownObject[]) {
        mapFromDataCollections(subValueToMap, subSources)
      }
      continue
    }

    const subValueToMap = valueToMap[firstLevelPropertyName] as UnknownObject
    mapFromDataCollections(subValueToMap, subSources)
  }
}

function isObject(obj: unknown): boolean {
  return typeof obj === 'object' && obj !== null
}
