import type { CancelToken } from 'axios'
import { client } from '~/ports/client'
import type { DataCollectionMapperOptions } from '~/types/common'
import { mapFromDataCollections } from '~/mappers/common/map-from-data-collections.mapper'

export function ApiShow<ShowType>(options: DataCollectionMapperOptions = {}) {
  return function <T extends { new (...args: any[]): {} }>(constructor: T) {
    constructor.prototype.show = async (id: string, cancelToken?: CancelToken): Promise<ShowType | null> => {
      if (!constructor.prototype.apiPrefix || !constructor.prototype.apiOptions.responseItemName) {
        throw new Error('@Api needed parameters is not defined')
      }

      let response
      try {
        response = await client.$axios.$get(`${constructor.prototype.apiPrefix}/${id}`, { cancelToken })
      } catch (e) {
        return null
      }

      const result = response[constructor.prototype.apiOptions.responseItemName]

      if (Object.keys(options).length) {
        mapFromDataCollections(result, options)
      }

      return result
    }
  }
}
