import type {
  BodyTypeDto,
  BrandDto,
  CategoryDto,
  CommonCarClassDto,
  FormFileDto,
} from '~/types/common'
import {
  emptyFormFile,
  mapFileToForm,
} from '~/types/common'
import type { ModelDto } from '~/types/admin'

export interface FormModelDto {
  name: string
  category: CategoryDto | null
  carClass: CommonCarClassDto | null
  image: FormFileDto
  brand: BrandDto
  minPrice: number | null
  maxPrice: number | null
  salesLeader: boolean
  bodyTypes: BodyTypeDto[]
  aliases: string[]
}

export function emptyFormModelDto(brand: BrandDto): FormModelDto {
  return {
    carClass: null,
    category: null,
    image: emptyFormFile(),
    name: '',
    brand,
    minPrice: null,
    maxPrice: null,
    salesLeader: false,
    bodyTypes: [],
    aliases: [],
  }
}

export function mapModelToForm(model: ModelDto): FormModelDto {
  return {
    ...emptyFormModelDto(model.brand),
    name: model.name,
    image: mapFileToForm(model.image),
    category: model.category || null,
    carClass: model.carClass || null,
    minPrice: model.minPrice ? Math.round(model.minPrice / 100) : null,
    maxPrice: model.maxPrice ? Math.round(model.maxPrice / 100) : null,
    salesLeader: model.salesLeader,
    bodyTypes: model.bodyTypes,
    aliases: [...model.aliases],
  }
}
