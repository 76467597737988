import type { RequestFileDto } from '~/types/common'
import { mapFormFileToRequest } from '~/types/common'
import type { FormModelDto } from '~/types/admin'

export interface RequestModelDto {
  name: string
  brand: string
  category?: string
  image?: RequestFileDto | null
  carClass?: string | null
  minPrice?: number
  maxPrice?: number
  salesLeader: boolean
  bodyTypes: string[]
  aliases: string[],
}

export function mapFormModelToRequestModel(formData: FormModelDto): RequestModelDto {
  return {
    brand: formData.brand.id,
    carClass: formData.carClass ? formData.carClass.id : null,
    category: formData.category ? formData.category.id : undefined,
    name: formData.name,
    image: mapFormFileToRequest(formData.image),
    minPrice: formData.minPrice ? formData.minPrice * 100 : undefined,
    maxPrice: formData.maxPrice ? formData.maxPrice * 100 : undefined,
    salesLeader: formData.salesLeader,
    bodyTypes: formData.bodyTypes.map(item => item.id),
    aliases: [...formData.aliases],
  }
}
