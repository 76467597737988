import { Component, Vue } from 'vue-property-decorator'

@Component
export default class RegistrationPopupMixin extends Vue {
  showRegistrationPopup: boolean = false

  toggleRegistrationPopup(): void {
    this.showRegistrationPopup = !this.showRegistrationPopup
  }
}
