import type { FilterCharacteristicValuesDto } from '~/types/client'
import type {
  BaseCharacteristicDto,
  CheckboxCharacteristicDto, ColorCharacteristicDto, MultiChoiceCharacteristicDto,
  NumberCharacteristicDto, SingleChoiceCharacteristicDto,
} from '~/types/common'
import {
  CharacteristicTypesEnum,
} from '~/types/common'

export interface FilterCharacteristicDto<T extends BaseCharacteristicDto = BaseCharacteristicDto> {
  characteristic: T
  values: FilterCharacteristicValuesDto<T>
}

export function isNumberFilterCharacteristicDto<T extends BaseCharacteristicDto = BaseCharacteristicDto>(
  filterCharacteristic: FilterCharacteristicDto<T>,
): filterCharacteristic is FilterCharacteristicDto<NumberCharacteristicDto<T>> {
  return filterCharacteristic.characteristic.dtype === CharacteristicTypesEnum.number
}

export function isCheckboxFilterCharacteristicDto<T extends BaseCharacteristicDto = BaseCharacteristicDto>(
  filterCharacteristic: FilterCharacteristicDto<T>,
): filterCharacteristic is FilterCharacteristicDto<CheckboxCharacteristicDto<T>> {
  return filterCharacteristic.characteristic.dtype === CharacteristicTypesEnum.checkbox
}

export function isColorFilterCharacteristicDto<T extends BaseCharacteristicDto = BaseCharacteristicDto>(
  filterCharacteristic: FilterCharacteristicDto<T>,
): filterCharacteristic is FilterCharacteristicDto<ColorCharacteristicDto<T>> {
  return filterCharacteristic.characteristic.dtype === CharacteristicTypesEnum.color
}

export function isSingleChoiceFilterCharacteristicDto<T extends BaseCharacteristicDto = BaseCharacteristicDto>(
  filterCharacteristic: FilterCharacteristicDto<T>,
): filterCharacteristic is FilterCharacteristicDto<SingleChoiceCharacteristicDto<T>> {
  return filterCharacteristic.characteristic.dtype === CharacteristicTypesEnum.singleChoice
}

export function isMultiChoiceFilterCharacteristicDto<T extends BaseCharacteristicDto = BaseCharacteristicDto>(
  filterCharacteristic: FilterCharacteristicDto<T>,
): filterCharacteristic is FilterCharacteristicDto<MultiChoiceCharacteristicDto<T>> {
  return filterCharacteristic.characteristic.dtype === CharacteristicTypesEnum.multiChoice
}
