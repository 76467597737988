

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataCollectionGetter } from '~/decorators/data-collection-getter.decorator'
import { Ref } from '~/node_modules/vue-property-decorator'
import DeviceDetectService from '~/services/common/device-detect.service'
import type { ExternalBannerDto } from '~/types/common'
import { DataCollectionTypeEnum, ExternalBannerTypeEnum } from '~/types/common'

@Component
export default class AdsBannerComponent extends Vue {
  @Prop({ required: true })
  readonly bannerKey!: string

  @Ref()
  readonly bannerContentElement?: HTMLElement

  headNodes: HTMLElement[] = []

  @DataCollectionGetter(DataCollectionTypeEnum.external_banner)
  readonly banners!: ExternalBannerDto[]

  readonly isInternalRequest = DeviceDetectService.isPrerenderer()

  get isDebug(): boolean {
    if (!this.$route) {
      return false
    }

    return this.$route.hash === '#debug-banners'
  }

  get calculatedBannerSize(): string | null {
    if (!this.banner) {
      return null
    }

    let style = ''

    if (this.banner.width !== undefined) {
      style += `width:${this.banner.width}px;`
    }

    if (this.banner.height !== undefined) {
      style += `height:${this.banner.height}px;`
    }

    return style
  }

  get banner(): ExternalBannerDto | null {
    return this.banners.find(banner => banner.key === this.bannerKey) || null
  }

  get show(): boolean {
    if (this.isInternalRequest) {
      return false
    }

    if (!this.banner) {
      return false
    }

    return this.banner.type === this.typeToDisplay && this.banner.active
  }

  get typeToDisplay(): ExternalBannerTypeEnum {
    return DeviceDetectService.isDesktop() ? ExternalBannerTypeEnum.DESKTOP : ExternalBannerTypeEnum.MOBILE
  }

  async loadScript() {
    return await new Promise<void>((resolve) => {
      if (document.getElementById('admixer-loader-script')) {
        return resolve()
      }

      const script = document.createElement('script')
      script.src = 'https://cdn.admixer.net/scripts3/loader2.js'
      script.id = 'admixer-loader-script'
      script.async = true
      script.defer = true
      script.setAttribute('data-inv', '//inv-nets.admixer.net/')
      script.setAttribute('data-r', 'single')
      script.setAttribute('data-sender', 'admixer')
      script.setAttribute('data-bundle', 'desktop')

      script.onload = () => {
        resolve()
      }

      document.body.appendChild(script)
    })
  }

  async mounted() {
    if (!this.banner || !this.banner.active) {
      return
    }

    if (!this.bannerContentElement) {
      return
    }

    await this.loadScript()

    const scripts = Array.from(this.bannerContentElement.getElementsByTagName('script'))

    for (const script of scripts) {
      const embed = document.createElement('script')
      for (const attribute of Array.from(script.attributes)) {
        embed.setAttribute(attribute.name, attribute.value)
      }
      embed.innerHTML = script.innerHTML

      this.bannerContentElement.appendChild(embed)
      script.remove()
    }

    if (!this.banner.headCode) {
      return
    }

    const headCodeNode = document.createElement('div')
    headCodeNode.innerHTML = this.banner.headCode
    for (const headNode of Array.from(headCodeNode.children)) {
      this.insertNodeToHead(headNode)
    }

    headCodeNode.remove()
  }

  insertNodeToHead(node: Element) {
    const embed = document.createElement(node.tagName)
    for (const attribute of Array.from(node.attributes)) {
      embed.setAttribute(attribute.name, attribute.value)
    }

    embed.innerHTML = node.innerHTML

    document.head.appendChild(embed)
    this.headNodes.push(embed)
  }

  beforeDestroy() {
    for (const headNode of this.headNodes) {
      headNode.remove()
    }

    this.headNodes = []
  }
}
