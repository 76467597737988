import type { DataCollectionMapperOptions } from '~/types/common'
import { DataCollectionTypeEnum } from '~/types/common'

export const categoryDataCollectionOptions: DataCollectionMapperOptions = {
  'items.*.bodyTypeList': DataCollectionTypeEnum.car_body_type,
  'items.*.characteristicList': DataCollectionTypeEnum.characteristic,
  'items.*.childList': DataCollectionTypeEnum.category,
  'items.*.modelList': DataCollectionTypeEnum.car_model,
  'items.*.carClassList': DataCollectionTypeEnum.car_class,
}
