import type { Middleware } from '@nuxt/types'

const OnlyDealerMiddleware: Middleware = async ({ store, app }) => {
  const isDealer: boolean = store.getters['client/auth/isDealer']

  if (isDealer) {
    return
  }

  await app.router?.push({ name: 'client-profile' })
}

export default OnlyDealerMiddleware
