















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Ref } from '~/node_modules/vue-property-decorator'
import { SvgIdUniquelizer } from '~/modules/common/svg/service/svg-id-uniquelizer'

@Component
export default class SvgIcon extends Vue {
  @Prop({ required: true })
  readonly name!: string

  @Prop({ default: null })
  readonly width!: string | null | number

  @Prop({ default: null })
  readonly height!: string | null | number

  @Prop({ default: null })
  readonly color!: string | null

  @Prop({ default: null })
  readonly hoverClass!: string | null

  @Prop({ default: null })
  readonly notHoverClass!: string | null

  @Ref('svg')
  readonly refSvg?: SVGElement

  svgComponent: unknown | null = null
  hovered = false

  @Prop({ default: null })
  emitName: null | string | undefined

  async created() {
    // @ts-ignore
    this.svgComponent = await import(`~/assets/svg/${this.name}.svg?inline`)

    this.$nextTick(() => {
      if (!this.refSvg) {
        return
      }

      if (this.width) {
        this.refSvg.style.width = this.width as string
      }

      if (this.height) {
        this.refSvg.style.height = this.height as string
      }

      if (this.color) {
        this.refSvg.style.color = this.color
      }

      this.refSvg.removeAttribute('width')
      this.refSvg.removeAttribute('height')

      const svgIdUniquelizer = new SvgIdUniquelizer(this.refSvg, this._uid.toString())
      svgIdUniquelizer.replace()

      if (this.emitName && this.emitName !== 'undefined') {
        this.$emit(this.emitName)
      }
    })
  }
}
