


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { i18n } from '~/ports/i18n'
import { PreloaderColor } from '~/configs/client'

@Component
export default class Preloader extends Vue {
  @Prop({ default: '' })
  title!: string

  @Prop({ default: PreloaderColor.GRAY })
  color!: PreloaderColor

  mounted() {
    if (window.preloadersAmount === undefined) {
      window.preloadersAmount = 0
    }

    window.preloadersAmount++
  }

  beforeDestroy() {
    window.preloadersAmount!--
  }

  get i18n() {
    return i18n.i18n
  }
}
