









import { Vue, Component, Prop } from 'vue-property-decorator'
import AppMenuItem from './item.vue'
import type { MenuGroup } from '~/types/common'

@Component({
  components: {
    AppMenuItem,
  },
})
export default class Item extends Vue {
  @Prop({ required: true })
  group!: MenuGroup
}
