


























































































































  import { Component, Mixins, Ref } from 'vue-property-decorator'
  import { VBtn } from '~/components/client/ui'
  import { Emit, Prop } from '~/node_modules/vue-property-decorator'
  import type { ProfileDealerRoleDto } from '~/types/common'
  import ProfileMenuSidebarMixin from '~/components/client/profile-menu/profile-menu-sidebar.mixin'

@Component({
  components: {
    VBtn,
  },
})
  export default class ProfileMenuSidebar extends Mixins(ProfileMenuSidebarMixin) {
  @Prop({ default: false })
  readonly showMenu!: boolean

  @Ref('menu-main')
  readonly menuMain!: HTMLElement

  @Ref('menu-inner')
  readonly menuInner!: HTMLElement

  @Ref('menu-logout')
  readonly menuLogout!: HTMLElement

  navigationHeight: number = 0

  chooseBackMethod() {
    this.dealerRoles.length && this.activeDealers.length
        ? this.toggleInnerMenu()
        : this.closeProfileMenu()
    this.changeMenuHeight()
  }

  @Emit('closeDropdownMenu')
  @Emit('closeSideBar')
  handleClick() {
  }

  @Emit('closeProfileMenu')
  closeProfileMenu() {}

  mounted() {
    this.changeMenuHeight()
  }

  chooseRoleMenu(role?: ProfileDealerRoleDto | null) {
    if (!role) {
      this.onClientTab()
      this.changeMenuHeight()

      return
    }

    this.onDealerTab(role)
    this.changeMenuHeight()
  }

  changeMenuHeight() {
    this.$nextTick(() => {
      if (!this.menuInner || !this.menuMain || !this.menuLogout) {
        return
      }

      this.navigationHeight = this.changeVisibleInnerMenu ? this.menuInner.clientHeight : this.menuMain.clientHeight

      if (!this.changeVisibleMenuWithRole) {
        this.navigationHeight += this.menuLogout.clientHeight
      }
    })
  }
  }
