import type { ApiInterface } from '~/contracts/crud/api.interface'
import type { CarViewsHistoryDto, RequestCarViewsHistoryDto } from '~/types/client'
import { Api } from '~/decorators'
import { client } from '~/ports/client'

interface CarViewsHistoryService extends ApiInterface {
}

@Api('/secure/car-views-history', {
  responseItemName: 'history',
})
class CarViewsHistoryService {
  async show(): Promise<CarViewsHistoryDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      return await client.$axios.$get(this.apiPrefix)
    } catch (e) {
      return null
    }
  }

  async update(data: RequestCarViewsHistoryDto): Promise<CarViewsHistoryDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      return await client.$axios.$put(this.apiPrefix, data)
    } catch (e) {
      return null
    }
  }
}

export default new CarViewsHistoryService()
