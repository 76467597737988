import type { I18n as I18nInterface } from '~/types/common'

class I18n {
  private _i18n: I18nInterface = { t: (_key, _values) => '' }

  set(i18n: I18nInterface) {
    this._i18n = i18n
  }

  get i18n() {
    return this._i18n
  }
}

const i18n = new I18n()

export { i18n }
