import type { SelectOptionDto } from '~/types/common'
import type { DealerTypeEnum } from '~/types/common/dealer/dealer-type.enum'

export interface SelectedOptionsDealerDto {
  active: boolean[]
  approved: boolean[]
  activities: SelectOptionDto[]
  cities: SelectOptionDto[]
  regions: SelectOptionDto[]
  name: string
  email: string
  phone: string
  legalEntityCode: string
  type: DealerTypeEnum[]
}

export function emptySelectedOptionsDealerDto(): SelectedOptionsDealerDto {
  return {
    active: [],
    approved: [],
    activities: [],
    cities: [],
    regions: [],
    name: '',
    email: '',
    phone: '',
    legalEntityCode: '',
    type: [],
  }
}
