







































































































































































  import { Component, Watch, Mixins } from 'vue-property-decorator'
  import SideBar from '~/components/client/side-bar/side-bar.vue'
  import ProfileMenu from '~/components/client/profile-menu/profile-menu.vue'
  import { Colors, iconSizes } from '~/configs/client'
  import type { CarTypeEnum } from '~/types/client'
  import { ButtonSizeEnum, ButtonTypeEnum } from '~/types/client'
  import { ClientAuthStoreModule } from '~/store/client/auth'
  import FavoritesCountButton from '~/components/client/header/favorites-count-button.vue'
  import ComparisonCountButton from '~/components/client/header/comparison-count-button.vue'
  import AddAdvertisementButton from '~/components/client/header/add-advertisement-button.vue'
  import DeviceDetectService from '~/services/common/device-detect.service'
  import AdsBannerComponent from '~/components/client/ads-banner.vue'
  import RegistrationPopupMixin from '~/mixins/registration-popup.mixin'
  import RegistrationPopup from '~/components/client/header/registration-popup.vue'

@Component({
  components: {
    SideBar,
    ProfileMenu,
    AdsBannerComponent,
    FavoritesCountButton,
    ComparisonCountButton,
    RegistrationPopup,
    AddAdvertisementButton,
  },
})
  export default class HeaderLanding extends Mixins(RegistrationPopupMixin) {
  readonly colors = Colors
  readonly iconSizes = iconSizes
  readonly buttonTypes = ButtonTypeEnum
  readonly buttonSizes = ButtonSizeEnum
  readonly testDriveKey: string = 'testDriveAbility=true'
  readonly isMobile: boolean = DeviceDetectService.isMobile()
  readonly isInternalRequest = DeviceDetectService.isPrerenderer()

  // TODO: when the ability to pull it from the admin via the backend will be added
  headerLinks = [
    // {
    //   id: '1',
    //   label: 'Вживані авто',
    //   type: 'main',
    //   url: '/used-cars',
    // },
    // {
    //   id: '2',
    //   label: 'Нові авто',
    //   type: 'main',
    //   url: '/new-cars',
    // },
    {
      id: '3',
      label: 'Дилерські центри',
      type: 'main',
      url: '/dealer-centers',
    },
    {
      id: '4',
      label: 'Трейдери',
      type: 'main',
      url: '/traders',
    },
    {
      id: '5',
      label: 'Про компанію',
      type: 'main',
      url: '/about-us',
    },
    {
      id: '6',
      label: 'Зв\'язатись з нами',
      type: 'main',
      url: '/contacts',
    },
    {
      id: '7',
      label: 'Новини',
      type: 'main',
      url: '/news',
    },
    {
      id: '8',
      label: 'Тарифи',
      type: 'main',
      url: '/tariffs',
    },

  ]

  profileMenuEnabled: boolean = false
  registrationDropdownOpen: boolean = false

  currentUrlType: CarTypeEnum | null = null

    @ClientAuthStoreModule.Getter('isLoggedIn')
    readonly isLoggedIn!: boolean

    @ClientAuthStoreModule.Getter('isDealer')
    readonly isDealer!: boolean

  toggleEnabledProfileMenu(): void {
    this.profileMenuEnabled = !this.profileMenuEnabled
  }

  toggleRegistrationDropdown(): void {
    this.registrationDropdownOpen = !this.registrationDropdownOpen
  }

  openSideBar(): void {
    this.$bus.$emit('open-side-bar')
  }

  @Watch('$route.path', { immediate: true })
  changeRoute() {
    if (this.$route.name !== 'client-type-query') {
      this.currentUrlType = null

      return
    }

    this.currentUrlType = !this.$route.path.includes(this.testDriveKey)
      ? this.$route.params.type as CarTypeEnum
      : null
  }
  }
