import type {
  FilterSelectedOptionsCarDto,
  SelectedOptionsDealersDto,
} from '~/types/client'
import {
  emptySelectedOptionsDealersDto,
  emptyFilterSelectedOptionsCarDto,
} from '~/types/client'
import type { SelectedOptionsNewsDto, SortOptionDto } from '~/types/common'
import { SortingTypeEnum, emptySelectedOptionsNewsDto } from '~/types/common'
import { defaultCarSortOption, defaultNewsSortOption, HIDDEN_SORT_OPTIONS } from '~/configs/client'

export interface SearchDataDto<T> {
  selectedOptions: T
  page: number
  sortOption: SortOptionDto
}

export function emptyCarSearchDataDto(): SearchDataDto<FilterSelectedOptionsCarDto> {
  return {
    page: 1,
    sortOption: defaultCarSortOption(),
    selectedOptions: emptyFilterSelectedOptionsCarDto(),
  }
}

export function emptyDealerSearchDataDto(): SearchDataDto<SelectedOptionsDealersDto> {
  return {
    page: 1,
    selectedOptions: emptySelectedOptionsDealersDto(),
    sortOption: {
      direction: SortingTypeEnum.DESC,
      key: HIDDEN_SORT_OPTIONS.CARS_AMOUNT,
    },
  }
}

export function emptyNewsSearchDataDto(): SearchDataDto<SelectedOptionsNewsDto> {
  return {
    page: 1,
    sortOption: defaultNewsSortOption(),
    selectedOptions: emptySelectedOptionsNewsDto(),
  }
}
