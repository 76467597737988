
















import { Vue, Component } from 'vue-property-decorator'
import { socialLinkList } from '~/configs/client/social-link-list.config'

@Component
export default class SocialSmall extends Vue {
  readonly socialLinkList = socialLinkList
}
