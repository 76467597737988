import type { GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import type { Dictionary, RootState, ServerValidationErrorsState } from '~/types/common'
import { emptyServerValidationErrorsState } from '~/types/common'
import { createServerValidationError } from '~/types/common/server-validation-error.dto'
import { toast } from '~/ports/toast'
import { i18n } from '~/ports/i18n'

export const state = (): ServerValidationErrorsState => emptyServerValidationErrorsState()

export const mutations: MutationTree<ServerValidationErrorsState> = {
  setErrors(state: ServerValidationErrorsState, errors: Dictionary<string>) {
    state.errors = {}

    for (const errorKey in errors) {
      const label = errors[errorKey]
      const serverValidationError = createServerValidationError(label)
      state.errors[errorKey] = serverValidationError

      serverValidationError.timeoutId = window.setTimeout(() => {
        const errorKeyLabel = i18n.i18n.t(`serverValidationKeys.${errorKey}`)

        if (errorKeyLabel === errorKey) {
          Vue.rollbar.error(`${errorKey} does not exist in translations`)
        }

        toast.toast.error(errorKeyLabel === errorKey ? label : `${errorKeyLabel}: ${label}`)
        serverValidationError.cleared = true
        delete serverValidationError.timeoutId
      }, 500)
    }
  },
  clearKey(state: ServerValidationErrorsState, key: string) {
    if (!(key in state.errors)) {
      return
    }
    const serverValidationError = state.errors[key]
    serverValidationError.cleared = true

    if (!serverValidationError.timeoutId) {
      return
    }

    window.clearTimeout(serverValidationError.timeoutId)
    delete serverValidationError.timeoutId
  },
}

export const getters: GetterTree<ServerValidationErrorsState, RootState> = {
  errorLabelByKey(state: ServerValidationErrorsState) {
    return (key: string) => key in state.errors ? state.errors[key].label : null
  },
  isFailed(state: ServerValidationErrorsState) {
    return (key: string) => key in state.errors && !state.errors[key].cleared
  },
}
