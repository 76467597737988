import type { Plugin } from '@nuxt/types'
import type { CityNameFormatter } from '~/modules/common/location/type/city-name-formatter.type'
import { cityNameFormatter } from '~/modules/common/location/formatter/city-name.formatter'

declare module 'vue/types/vue' {

  interface Vue {
    $cityNameFormatter: CityNameFormatter,
  }
}

const cityNameFormatterPlugin: Plugin = function (_context, inject) {
  inject('cityNameFormatter', cityNameFormatter)
}

export default cityNameFormatterPlugin
