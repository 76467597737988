class Gmaps {
  private _$promise!: () => Promise<void>

  setPromise(promise: () => Promise<void>) {
    this._$promise = promise
  }

  get $promise() {
    return this._$promise
  }
}

const gmaps = new Gmaps()

export { gmaps }
