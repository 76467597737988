






import { Component, Vue } from 'vue-property-decorator'
import Preloader from '~/components/common/directives/preloader.vue'

@Component({
  components: { Preloader },
})
export default class LazyLoadingPreloader extends Vue {
}
