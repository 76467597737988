import type {
  CharacteristicValueListDto,
  FormFileDto,
  MultiChoiceCharacteristicDto,
} from '~/types/common'
import {
  CharacteristicTypesEnum,
  emptyCharacteristicValueListDto,
  emptyFormFile,
  EquipmentStatusesEnum,
  mapCharacteristicValueLists,
  mapFileToForm,
  copyFormImageListDto,
  emptyFormImageListDto,
  mapFormImageListDto,
} from '~/types/common'
import type {
  EquipmentDto,
  ModificationDto,
  WithFormCharacteristicValuesDto,
  WithFormImageListDto,
} from '~/types/admin'

export interface FormEquipmentDto extends WithFormCharacteristicValuesDto, WithFormImageListDto{
  modification: ModificationDto
  name: string
  published: boolean
  image: FormFileDto
}

export function emptyFormEquipmentDto(modification: ModificationDto): FormEquipmentDto {
  const category = modification.serie.generation.model.category

  const characteristics = category ? category.characteristicList.filter(characteristic => characteristic.option) : []

  characteristics.forEach((characteristic) => {
    if (characteristic.dtype !== CharacteristicTypesEnum.multiChoice) {
      return
    }

    const modificationCharacteristicValue = modification.characteristicValues
      .find(value => value.characteristic.id === characteristic.id) as CharacteristicValueListDto<MultiChoiceCharacteristicDto> | undefined

    if (!modificationCharacteristicValue || !modificationCharacteristicValue.value) {
      return
    }

    (characteristic as MultiChoiceCharacteristicDto).values.forEach((value) => {
      value.locked = modificationCharacteristicValue.value!.some(lockedValue => lockedValue.id === value.id)
    })
  })

  const characteristicValues = characteristics
    .filter((characteristic) => {
      if (characteristic.dtype === CharacteristicTypesEnum.multiChoice) {
        return (characteristic as MultiChoiceCharacteristicDto).values.some(value => !value.locked)
      }

      return !modification.characteristicValues.some(value => value.characteristic.id === characteristic.id)
    })
    .map(characteristic => emptyCharacteristicValueListDto(characteristic))

  return {
    modification,
    characteristicValues,
    name: '',
    published: false,
    image: emptyFormFile(),
    imageList: emptyFormImageListDto(),
  }
}

export function mapEquipmentToForm(equipment: EquipmentDto): FormEquipmentDto {
  const formData = emptyFormEquipmentDto(equipment.modification)

  formData.name = equipment.name
  formData.image = mapFileToForm(equipment.image)
  formData.published = equipment.status === EquipmentStatusesEnum.PUBLIC

  mapCharacteristicValueLists(formData.characteristicValues, equipment.characteristicValues)
  mapFormImageListDto(formData.imageList, equipment.imageList)

  return formData
}

export function mapEquipmentToCopyForm(equipment: EquipmentDto, copyLabel: string): FormEquipmentDto {
  const formData = emptyFormEquipmentDto(equipment.modification)

  const formImage = emptyFormFile()

  formImage.newFile = equipment.image ? { existedFile: { ...equipment.image } } : null

  formData.name = `${copyLabel} ${equipment.name}`
  formData.image = formImage

  mapCharacteristicValueLists(formData.characteristicValues, equipment.characteristicValues)
  copyFormImageListDto(formData.imageList, equipment.imageList)

  return formData
}
