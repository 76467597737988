import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type { FilterSelectedOptionsCarDto, SearchDataDto } from '~/types/client'
import { emptyLocationRadius, FilterGroupsEnum, FilterKeysEnum } from '~/types/common'

export class LocationDecodeStrategy extends StringDecodeStrategyAbstract<FilterSelectedOptionsCarDto> {
  // TODO: checking int and float move to ArrayPattern
  // loc=lon:26.9961638&lat:49.43366229999999&radius:70
  // loc=lon:(\d*).(\d*)&lat:(\d*).(\d*)&radius:(\d*)
  readonly pattern = `^${FilterGroupsEnum.LOCATION}=${FilterKeysEnum.LON}:(-?\\d*).(-?\\d*)&${FilterKeysEnum.LAT}:(-?\\d*).(-?\\d*)&${FilterKeysEnum.RADIUS}:(-?\\d*)`
  // readonly pattern = `^${FilterGroupsEnum.LOCATION}=${FilterKeysEnum.LON}:${this.numberArrayPattern}&${FilterKeysEnum.LAT}:${this.numberArrayPattern}&${FilterKeysEnum.RADIUS}:(\\d*)`

  process(subject: SearchDataDto<FilterSelectedOptionsCarDto>, searchItemString: string): void {
    const locationData = emptyLocationRadius()
    const locationArray = searchItemString.split('=')[1].split('&')

    for (const item of locationArray) {
      if (RegExp(`^${FilterKeysEnum.LON}:(-?\\d*).(-?\\d*)`).test(item)) {
        locationData.lon = parseFloat(item.split(':')[1])

        continue
      }

      if (RegExp(`^${FilterKeysEnum.LAT}:(-?\\d*).(-?\\d*)`).test(item)) {
        locationData.lat = parseFloat(item.split(':')[1])

        continue
      }

      if (RegExp(`^${FilterKeysEnum.RADIUS}:(-?\\d*)`).test(item)) {
        locationData.radius = parseInt(item.split(':')[1])
      }
    }

    subject.selectedOptions.location = locationData
  }
}
