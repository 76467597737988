import type {
  CreateBaseContentBlockDto,
  RequestContentBlockDto,
  RequestImageContentBlockDto,
  RequestTextContentBlockDto,
} from '~/types/common'
import {
  ContentBlockTypeEnum,
  isFormImageContentBlock,
  isFormTextContentBlock,
  mapFormFileToRequest,
} from '~/types/common'

export interface UpdateBaseContentBlockDto extends CreateBaseContentBlockDto {
  id: string
  isRemove?: boolean
}

export function mapUpdateFormContentToRequestDto(contentBlock: UpdateBaseContentBlockDto): RequestContentBlockDto<UpdateBaseContentBlockDto> | null {
  if (isFormTextContentBlock(contentBlock)) {
    return {
      id: contentBlock.id,
      type: ContentBlockTypeEnum.TEXT,
      text: contentBlock.text,
      position: contentBlock.position,
      isRemove: contentBlock.isRemove,
    } as RequestTextContentBlockDto<UpdateBaseContentBlockDto>
  }

  if (isFormImageContentBlock(contentBlock)) {
    return {
      id: contentBlock.id,
      type: ContentBlockTypeEnum.IMAGE,
      position: contentBlock.position,
      image: contentBlock.image ? mapFormFileToRequest(contentBlock.image) : undefined,
      caption: contentBlock.caption,
      isRemove: contentBlock.isRemove,
    } as RequestImageContentBlockDto<UpdateBaseContentBlockDto>
  }

  return null
}
