










































import { Vue, Component, Emit } from 'vue-property-decorator'
import PhoneNumberLink from '~/components/common/phone-number-link.vue'
import { IconEnum } from '~/types/client'
import { Colors } from '~/configs/client'

@Component({
  components: { PhoneNumberLink },
})
export default class RegistrationPopup extends Vue {
  readonly iconEnum = IconEnum
  readonly colors = Colors

  @Emit('toggleRegistrationPopup')
  toggleRegistrationPopup() {}

  handleClick() {
      this.toggleRegistrationPopup()
  }
}
