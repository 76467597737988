








import { Component, Vue } from 'vue-property-decorator'
import { closedIndexMeta } from '~/configs/client/robots-meta'

@Component({
  head(this: EmptyLayout) {
    return {
      meta: [
        closedIndexMeta,
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
})
export default class EmptyLayout extends Vue {
    $i18n: any
}
