























































































import { Component, Emit, Vue } from 'vue-property-decorator'
import { VBtn } from '~/components/client/ui'
import ProfileMenu from '~/components/client/profile-menu/profile-menu.vue'
import FavoritesCountButton from '~/components/client/header/favorites-count-button.vue'
import { Colors, iconSizes } from '~/configs/client'
import { ClientAuthStoreModule } from '~/store/client/auth'
import { ButtonSizeEnum, ButtonTypeEnum } from '~/types/client'
import { DataCollectionGetter } from '~/decorators/data-collection-getter.decorator'
import type { HeaderLinkDto } from '~/types/common'
import { DataCollectionTypeEnum, SideBarTypesEnum } from '~/types/common'
import AddAdvertisementButton from '~/components/client/header/add-advertisement-button.vue'
import ComparisonCountButton from '~/components/client/header/comparison-count-button.vue'
import SupportInfo from '~/components/client/header/support-info.vue'
import DeviceDetectService from '~/services/common/device-detect.service'
import { breakpoints } from '~/configs/common/breakpoints'
import ProfilePhoto from '~/components/client/profile/profile-photo.vue'
import ProfileMenuSidebar from '~/components/client/profile-menu/profile-menu-sidebar.vue'

@Component({
  components: {
    ProfileMenuSidebar,
    SupportInfo,
    ComparisonCountButton,
    AddAdvertisementButton,
    VBtn,
    ProfileMenu,
    FavoritesCountButton,
    ProfilePhoto,
  },
})
export default class SideBar extends Vue {
  readonly iconSizes = iconSizes
  readonly colors = Colors
  readonly buttonTypes = ButtonTypeEnum
  readonly buttonSizes = ButtonSizeEnum
  readonly isInternalRequest = DeviceDetectService.isPrerenderer()

  dropdownMenuEnabled: boolean = false
  showSideBar: boolean = false

  @ClientAuthStoreModule.Getter('isLoggedIn')
  readonly isLoggedIn!: boolean

  @ClientAuthStoreModule.Getter('isDealer')
  readonly isDealer!: boolean

  @DataCollectionGetter(DataCollectionTypeEnum.header_link)
  readonly headerLinks!: HeaderLinkDto[]

  created() {
    window.addEventListener('resize', this.handleResize)
    this.$bus.$on('open-side-bar', (target: SideBarTypesEnum) => {
      if (target === SideBarTypesEnum.PROFILE_MENU) {
        this.dropdownMenuEnabled = true
      }

      this.showSideBar = true
      this.$isAllowBodyScroll(false)
    })
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    if (window.innerWidth >= breakpoints.MD) {
      this.dropdownMenuEnabled = false
      this.handleClick()
    }
  }

  @Emit('closeSideBar')
  handleClick() {
    this.showSideBar = false
    this.$isAllowBodyScroll(true)
  }

  toggleEnabledDropdownMenu(): void {
    this.dropdownMenuEnabled = !this.dropdownMenuEnabled
  }
}
