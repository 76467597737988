import type { Middleware } from '@nuxt/types'
import PWAService from '~/services/client/pwa.service'

const UserSessionsMiddleware: Middleware = () => {
  const now = new Date()
  const sessionDate = PWAService.getLastSessionDate()
  const lastSessionDate = sessionDate ? new Date(sessionDate) : null

  if (!lastSessionDate) {
    PWAService.updateSessionDate()
    PWAService.setDefaultSession()
    PWAService.changeSessionIncrementStatus(true)

    return
  }

  const diffTimeMs = Math.abs(now.getTime() - lastSessionDate.getTime())
  const hours = Number((diffTimeMs / (1000 * 60 * 60)).toFixed(1))

  if (hours >= 1) {
    PWAService.incrementUserSessions()
    PWAService.changeSessionIncrementStatus(true)
    PWAService.updateSessionDate()

    return
  }

  PWAService.updateSessionDate()
}

export default UserSessionsMiddleware
