import type {
  ColorCharacteristicDto,
  ColorCharacteristicItemDto,
  FormBaseCharacteristicDto,
} from '~/types/admin'
import {
  emptyFormBaseCharacteristic,
  mapBaseCharacteristicToForm,
} from '~/types/admin'
import type { SectionDto } from '~/types/common'
import { CharacteristicTypesEnum } from '~/types/common'

export interface FormColorCharacteristicDto extends FormBaseCharacteristicDto {
  values: ColorCharacteristicItemDto[]
}

export function emptyFormColorCharacteristic(section: SectionDto): FormColorCharacteristicDto {
  return {
    ...emptyFormBaseCharacteristic(CharacteristicTypesEnum.color, section),
    values: [],
  }
}

export function mapColorCharacteristicToForm(characteristic: ColorCharacteristicDto): FormColorCharacteristicDto {
  return {
    ...mapBaseCharacteristicToForm(characteristic),
    values: [...characteristic.values],
  }
}
