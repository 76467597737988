import type { FormImageListDto as CommonFormImageListDto, FileDto } from '~/types/common'
import {
  emptyFormImageListDto as commonEmptyFormImageListDto,
  FormImageListItemStateEnum,
  mapFormImageListDto as commonMapFormImageListDto,
  copyFormImageListDto as commonCopyFormImageListDto,
} from '~/types/common'
import type { ImageListDto } from '~/types/client'

export type FormImageListDto = CommonFormImageListDto

export function emptyFormImageListDto(): FormImageListDto {
  return commonEmptyFormImageListDto()
}

export function mapFormImageListDto(imageList: ImageListDto<FileDto>): FormImageListDto {
  const formImageList = commonEmptyFormImageListDto()
  commonMapFormImageListDto(formImageList, imageList)

  return formImageList as FormImageListDto
}

export function copyFormImageListDto(
  formImageListDto: FormImageListDto,
  imageListDto: ImageListDto<FileDto>,
  imageState:FormImageListItemStateEnum = FormImageListItemStateEnum.NEW,
) {
  commonCopyFormImageListDto(formImageListDto, imageListDto, imageState)
}
