import { SortingTypeEnum } from '~/types/common'
import type { CarSortOptionDto, SortOptionDto } from '~/types/common'
import { i18n } from '~/ports/i18n'

export const carSortOptions = (): CarSortOptionDto[] => {
  return [
    {
      label: i18n.i18n.t('sort_by.created_ZA'),
      key: 'publishedAt',
      direction: SortingTypeEnum.DESC,
    },
    {
      label: i18n.i18n.t('sort_by.created_AZ'),
      key: 'publishedAt',
      direction: SortingTypeEnum.ASC,
    },
    {
      label: i18n.i18n.t('sort_by.price_AZ'),
      key: 'price',
      direction: SortingTypeEnum.ASC,
    },
    {
      label: i18n.i18n.t('sort_by.price_ZA'),
      key: 'price',
      direction: SortingTypeEnum.DESC,
    },
    {
      label: i18n.i18n.t('sort_by.millage_AZ'),
      key: 'mileage',
      direction: SortingTypeEnum.ASC,
    },
    {
      label: i18n.i18n.t('sort_by.millage_ZA'),
      key: 'mileage',
      direction: SortingTypeEnum.DESC,
    },
    {
      label: i18n.i18n.t('sort_by.year_AZ'),
      key: 'year',
      direction: SortingTypeEnum.ASC,
    },
    {
      label: i18n.i18n.t('sort_by.year_ZA'),
      key: 'year',
      direction: SortingTypeEnum.DESC,
    },
  ]
}

export const carSortOptionsForPropulsion = (): CarSortOptionDto[] => {
  return [
    {
      label: 'propulsing',
      key: 'isPropulsing',
      direction: SortingTypeEnum.DESC,
    }, {
      label: 'propulsing start date',
      key: 'promotionCreatedAt',
      direction: SortingTypeEnum.DESC,
    },
  ]
}

export enum HIDDEN_SORT_OPTIONS {
  LOCATION = 'location',
  REGION_LOCATION = 'regionLocation',
  CARS_AMOUNT = 'carsAmount',
  LIKES_COUNT = 'likesCount',
}

export const defaultCarSortOption = (): CarSortOptionDto => carSortOptions()[0]

export function areCarSortOptionsEqual(option1: SortOptionDto, option2: SortOptionDto) {
  return option1.key === option2.key && option1.direction === option2.direction
}
