import { StatisticEnum } from '~/types/client'

export type StatisticDto = {
  [StatisticEnum.UNIQUE_VIEWS]: number
  [StatisticEnum.COMPARSION_TOTAL]: number
  [StatisticEnum.FAVORITE_TOTAL]: number
  [StatisticEnum.MAP_TOTAL]: number
  [StatisticEnum.PHONE_TOTAL]: number
  id?: string
}

export function emptyStatisticDto(): StatisticDto {
  return {
    [StatisticEnum.UNIQUE_VIEWS]: 0,
    [StatisticEnum.COMPARSION_TOTAL]: 0,
    [StatisticEnum.FAVORITE_TOTAL]: 0,
    [StatisticEnum.MAP_TOTAL]: 0,
    [StatisticEnum.PHONE_TOTAL]: 0,
  }
}
