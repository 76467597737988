import { IconEnum } from '~/types/client/ui'
import type { MenuGroup, ProfileDealerRoleDto } from '~/types/common'
import { DealerRolesEnum } from '~/types/common'

export function profileDropdownMenuDealer(deaderRole: ProfileDealerRoleDto): MenuGroup[] {
  return [
    {
      items: [
        {
          name: 'client-profile-dealer-id-add-advertisement',
          title: 'profile-menu.navigation.add_car',
          icon: IconEnum.addCircleRed,
          params: { id: deaderRole.dealer.id },
          hide: !deaderRole.dealer.approved,
          id: 'add-advertisement-sidebar',
        },
        {
          name: 'client-profile-dealer-id',
          title: 'profile-menu.navigation.my_advertisements',
          icon: IconEnum.car1Red,
          params: { id: deaderRole.dealer.id },
        },
        {
          name: 'client-profile-dealer-id-test-drive-requests',
          title: 'profile-menu.navigation.test_drive_application',
          icon: IconEnum.testDriveCar,
          params: { id: deaderRole.dealer.id },
        },
        {
          name: 'client-profile-dealer-id-members',
          title: 'profile-menu.navigation.management-managers',
          icon: IconEnum.addedAdvertisementsRed,
          hide: deaderRole.role !== DealerRolesEnum.DEALER_ADMIN,
          params: { id: deaderRole.dealer.id },
        },
        {
          name: 'client-profile-dealer-id-actions',
          title: 'profile-menu.navigation.actions',
          icon: IconEnum.actionsRed,
          hide: deaderRole.role !== DealerRolesEnum.DEALER_ADMIN,
          params: { id: deaderRole.dealer.id },
        },
        {
          name: 'client-profile-dealer-id-edit',
          title: 'profile-menu.navigation.settings',
          icon: IconEnum.settingsRed,
          hide: deaderRole.role !== DealerRolesEnum.DEALER_ADMIN,
          params: { id: deaderRole.dealer.id },
        },
      ],
    },
  ]
}
