

























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Colors, iconSizes } from '~/configs/client'
import { ButtonSizeEnum, ButtonTypeEnum } from '~/types/client'
import { ClientAuthStoreModule } from '~/store/client/auth'
import { VBtn } from '~/components/client/ui'
import type { ProfileDealerRoleDto } from '~/types/common'
import { DealerTypeEnum } from '~/types/common/dealer/dealer-type.enum'

@Component({
  components: {
    VBtn,
  },
})
export default class AddAdvertisementButton extends Vue {
  readonly iconSizes = iconSizes
  readonly colors = Colors
  readonly buttonTypes = ButtonTypeEnum
  readonly buttonSizes = ButtonSizeEnum

  isShowModal:boolean = false

  @Prop({ default: false, type: Boolean })
  readonly inMobileMenu!: Boolean

  @Prop({ required: true })
  readonly id!: string

  @ClientAuthStoreModule.Getter('isLoggedIn')
  readonly isLoggedIn!: boolean

  @ClientAuthStoreModule.Getter('dealerRoles')
  readonly dealerRoles!: ProfileDealerRoleDto[]

  get userIsNotAuthOrIsNotDealer() {
    return !this.isLoggedIn || (this.isLoggedIn && !this.dealerRoles.length)
  }

  get activeDealers() {
    return this.dealerRoles.filter(dealerRole => dealerRole.dealer.active && dealerRole.dealer.approved)
  }

  addAdvertisementAs(dealerRole: ProfileDealerRoleDto): string {
    return this.$t(dealerRole.dealer.type === DealerTypeEnum.TRADER
      ? 'as-trader'
      : 'as-dealer', { name: dealerRole.dealer.name })
  }

  clickHandler() {
    if (this.userIsNotAuthOrIsNotDealer) {
      this.$router.push({ name: 'client-login', query: { redirectUrl: 'profile/add-advertisement' } })

      return
    }

    this.isShowModal = !this.isShowModal
  }

  closeModal() {
    this.isShowModal = false
  }

  closeModalAndSideBar() {
    this.closeModal()

    if (this.inMobileMenu) {
      this.$emit('close-menu')
    }
  }
}
