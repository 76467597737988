import type { CreateElement, VNode } from 'vue'
import Preloader from '~/components/common/lazy-loading-preloader.vue'
import type { UnknownObject } from '~/types/common'
import DeviceDetectService from '~/services/common/device-detect.service'

function isLazyLoadingPossible(): boolean {
  return !DeviceDetectService.isPrerenderer() && 'IntersectionObserver' in window
}

export default function lazyLoadComponent(
  componentFactory: () => Promise<typeof import('*.vue')>,
  loadingStyles: UnknownObject = {},
): unknown {
  let resolveComponent: (value?: unknown) => void

  return () => ({
    delay: 0,
    component: new Promise((resolve) => { resolveComponent = resolve }),
    loading: {
      created() {
        if (!isLazyLoadingPossible()) {
          return
        }

        componentFactory().then(resolveComponent)
      },
      mounted() {
        if (!isLazyLoadingPossible()) {
          return
        }

        const that = this as UnknownObject
        const observer = new IntersectionObserver((entries) => {
          if (entries[0].intersectionRatio <= 0) {
            return
          }

          observer.unobserve(that.$el as Element)
          componentFactory().then(resolveComponent)
        })
        observer.observe(that.$el as Element)
      },
      render(createElement: CreateElement): VNode {
        return createElement(Preloader, { style: loadingStyles })
      },
    },
  })
}
