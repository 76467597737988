import type { RequestSerieDto, SerieDto } from '~/types/admin'
import type { BodyTypeDto, GenerationDto } from '~/types/common'

export interface FormSerieDto {
  generation: GenerationDto
  bodyType: BodyTypeDto | null
  name: string
}

export function emptyFormSerie(generation: GenerationDto): FormSerieDto {
  return {
    generation,
    name: '',
    bodyType: null,
  }
}

export function mapSerieToForm(serie: SerieDto): FormSerieDto {
  const formData = emptyFormSerie(serie.generation)

  formData.name = serie.name
  formData.bodyType = serie.bodyType

  return formData
}

export function mapFormSerieToRequest(formData: FormSerieDto): RequestSerieDto {
  return {
    bodyType: formData.bodyType!.id,
    generation: formData.generation.id,
    name: formData.name,
  }
}
