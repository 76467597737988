

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VBtn from '~/components/client/ui/buttons/v-btn.vue'
import { Colors, iconSizes } from '~/configs/client'
import { ButtonTypeEnum, DirectionEnum } from '~/types/client'
import DeviceDetectService from '~/services/common/device-detect.service'
import { UserConsentTypeEnum } from '~/types/common/user-consent'

@Component({
  components: {
    VBtn,
  },
})
export default class VFixedModal extends Vue {
  readonly buttonTypes = ButtonTypeEnum
  readonly colors = Colors
  readonly iconSizes = iconSizes
  readonly isMobile: boolean = DeviceDetectService.isMobile()
  readonly userConsentTypes = UserConsentTypeEnum

  show: boolean = false
  opening: boolean = false

  @Prop({ required: true })
  readonly id!: string

  @Prop({ default: DirectionEnum.DOWN })
  readonly direction!: DirectionEnum

  @Prop({ default: false })
  readonly closeAllBeforeOpen!: boolean

  @Prop({ default: true })
  closeOutside!: boolean

  @Prop({ default: false })
  readonly allowBodyScrollOnMobile!: boolean

  @Prop({ default: null })
  readonly closeBtnId?: string | null

  get isConsentModal() {
    return this.id === UserConsentTypeEnum.BETA || this.id === UserConsentTypeEnum.COOKIES
  }

  @Watch('show')
  changeShowStatus(newValue: boolean) {
    if (!this.isMobile || this.isConsentModal) {
      return
    }
    this.$bus.$emit('toggle-slider-transform', newValue)
  }

  mounted() {
    this.$bus.$on('fixed-modal-close-all', () => {
      this.handleClose()
    })

    this.$bus.$on(`fixed-modal-open.${this.id}`, () => {
      this.opening = true
      if (this.id === 'callback') {
        this.closeOutside = false
      }
      this.handleOpen()
    })

    this.$bus.$on(`fixed-modal-close.${this.id}`, () => {
      this.handleClose()
    })

    this.$bus.$on(`fixed-modal-toggle.${this.id}`, () => {
      if (this.show) {
        this.handleClose()

        return
      }

      this.handleOpen()
    })
  }

  handleClose() {
    if (!this.show) {
      return
    }

    this.show = false

    this.opening || this.$nextTick(() => this.$emit('closed'))

    this.$isAllowBodyScroll(true)
  }

  closeModalOutside() {
    if (!this.closeOutside) {
      return
    }

    this.handleClose()
  }

  handleOpen() {
    if (this.closeAllBeforeOpen) {
      this.$bus.$emit('fixed-modal-close-all')
    }
    this.show = true
    this.opening = false

    if (window.innerWidth <= 575 && !this.allowBodyScrollOnMobile) {
      this.$isAllowBodyScroll(false)
    }

    this.$emit('opened')
  }
}
