import type {
  Dictionary,
  FileDto,
  FormImageListDto, MultiSizeImageDto,
  RequestImageListItemDto,
} from '~/types/common'
import {
  FormImageListItemStateEnum,
} from '~/types/common'

export interface RequestImageListDto {
  new?: RequestImageListItemDto[]
  update?: Dictionary<RequestImageListItemDto>
  remove?: string[]
}

export function mapFormImageListToRequest(formData: FormImageListDto): RequestImageListDto {
  const requestListData: RequestImageListDto = {}

  formData.images.forEach((listItem, position) => {
    switch (listItem.state) {
      case FormImageListItemStateEnum.NEW:
        requestListData.new = requestListData.new || []
        const file = listItem.newFile
          ? { tempFile: listItem.newFile.id }
          : { existedFile: listItem.existedFile ? (listItem.existedFile as FileDto | MultiSizeImageDto).id : undefined }

        requestListData.new.push({
          position,
          file: {
            ...file,
          },
        })
        break
      case FormImageListItemStateEnum.REMOVED:
        requestListData.remove = requestListData.remove || []
        requestListData.remove.push(listItem.id!)
        break
      case FormImageListItemStateEnum.UPDATED:
        requestListData.update = requestListData.update || {}
        requestListData.update[listItem.id!] = {
          file: listItem.newFile ? { tempFile: listItem.newFile.id } : undefined,
          position,
        }
        break
    }
  })

  return requestListData
}
