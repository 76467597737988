import { authConfigurations as configurations } from '~/plugins/authenticate/configs/auth'
import type { AuthTypesEnum, ProfileDto, UnknownObject } from '~/types/common'
import CacheStorageService from '~/services/common/cache-storage.service'
import { profileDataCollectionOptions } from '~/configs/common/data-collection-option/profile'
import { mapFromDataCollections } from '~/mappers/common/map-from-data-collections.mapper'

class LocalProfileService {
  public getProfile(authType: AuthTypesEnum): ProfileDto | null {
    const profile = CacheStorageService.read<ProfileDto>(configurations[authType].localstorageProfile)

    if (profile) {
      mapFromDataCollections(<unknown>profile as UnknownObject, profileDataCollectionOptions)
    }

    return profile
  }

  public setProfile(profile: ProfileDto | null, authType: AuthTypesEnum) {
    CacheStorageService.write(configurations[authType].localstorageProfile, profile)
  }
}

const localProfileServiceInstance = new LocalProfileService()
export default localProfileServiceInstance
