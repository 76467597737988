export interface QuestionAnswerDto {
  question: string
  answer: string
}

export function emptyQuestionAnswer(): QuestionAnswerDto {
  return {
    answer: '',
    question: '',
  }
}
