import type { AxiosInstance } from 'axios'
import { VueAuthenticate } from 'vue-authenticate'
import { client } from '~/ports/client'
import authClient from '~/ports/auth'
import { AuthTypesEnum } from '~/types/common'
import type { ConfirmationDto, ProfileDto, PublicProfileDto, RequestUserDto, SocialProvidersEnum } from '~/types/common'
import { Api, DataCollection } from '~/decorators'
import type { ApiInterface } from '~/contracts/crud/api.interface'
import { profileDataCollectionOptions } from '~/configs/common/data-collection-option/profile'
import { socialConnectProviders } from '~/configs/common/social-connect-providers'
import { TooManyRequestsException, UserAlreadyExistsException } from '~/exceptions'
import InvalidJwtException from '~/exceptions/invalid-jwt.exception'
import CacheStorageService from '~/services/common/cache-storage.service'
import { confirmationStorageKey } from '~/configs/common/confirmation-storage-key'
import { publicProfileDataCollectionOptions } from '~/configs/common/data-collection-option/public-profile'

interface ProfileService extends ApiInterface {
}

@Api('/secure/profile', {
  responseItemName: 'profile',
})
class ProfileService {
  @DataCollection(profileDataCollectionOptions)
  async get(): Promise<ProfileDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      const response = await client.$axios.$get(this.apiPrefix)

      return response[this.apiOptions.responseItemName]
    } catch (error) {
      if ('response' in error && error.response.status === 401) {
        throw new InvalidJwtException()
      }

      return null
    }
  }

  async validateToken() {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      await client.$axios.$get(`${this.apiPrefix}/check`)
    } catch (error) {
      if ('response' in error && error.response && error.response.status === 401) {
        throw new InvalidJwtException()
      }

      throw error
    }
  }

  @DataCollection(profileDataCollectionOptions)
  async update(data: RequestUserDto): Promise<ProfileDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      const response = await client.$axios.$put(this.apiPrefix, data)

      return response[this.apiOptions.responseItemName]
    } catch (e) {
      return null
    }
  }

  async socialConnect(provider: SocialProvidersEnum): Promise<ProfileDto> {
    const vueAuth = new VueAuthenticate(client.$axios as AxiosInstance, {
      providers: socialConnectProviders,
    })

    try {
      const response = await vueAuth.link(provider) as { profile: ProfileDto }

      return response.profile
    } catch (error) {
      if ('response' in error && error.response.code === 422) {
        throw new UserAlreadyExistsException(error.response.error)
      }

      throw error
    }
  }

  async socialDisconnect(provider: SocialProvidersEnum): Promise<boolean> {
    if (!this.apiPrefix) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      await client.$axios.$delete(`${this.apiPrefix}/social/${provider}`)

      return true
    } catch (e) {
      return false
    }
  }

  async changePhone(phone: string, code: string): Promise<ProfileDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      const response = await client.$axios.$put(`${this.apiPrefix}/change-phone/${phone}`, { code })
      authClient.$auth.setToken(response.token, AuthTypesEnum.CLIENT)
      await authClient.$auth.setAxiosToken(AuthTypesEnum.CLIENT)

      return response[this.apiOptions.responseItemName]
    } catch (e) {
      return null
    }
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      await client.$axios.$post(`${this.apiPrefix}/change-password`, { oldPassword, newPassword })

      return true
    } catch (e) {
      return false
    }
  }

  async sendRemovingConfirmation() {
    if (!this.apiPrefix) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      const response = await client.$axios.$get(`${this.apiPrefix}/send-remove-confirmation`)

      const confirmation: ConfirmationDto = response.confirmation

      if (!confirmation.token) {
        return
      }

      CacheStorageService.write(confirmationStorageKey, confirmation)
    } catch (error) {
      if ('response' in error && error.response.status === 429) {
        throw new TooManyRequestsException(error.response.data.error)
      }

      throw error
    }
  }

  async remove(code: string): Promise<boolean> {
    if (!this.apiPrefix) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      await client.$axios.$delete(this.apiPrefix, { data: { code } })

      return true
    } catch (e) {
      return false
    }
  }

  async restoreAfterRemoving() {
    if (!this.apiPrefix) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      await client.$axios.$put(`${this.apiPrefix}/restore`)

      return true
    } catch (e) {
      return false
    }
  }

  @DataCollection(publicProfileDataCollectionOptions)
  async getPublicProfile(publicCode: string): Promise<PublicProfileDto | null> {
    try {
      const response = await client.$axios.$get(`/profile/public/${publicCode}`)

      return response.profile
    } catch (e) {
      return null
    }
  }
}

export default new ProfileService()
