export enum ErrorTypesEnum {
  ALPHA = 'alpha',
  ALPHA_NUM = 'alphaNum',
  BETWEEN = 'between',
  DIGITS = 'digits',
  EMAIL = 'email',
  MAX_LENGTH = 'maxLength',
  MIN_LENGTH = 'minLength',
  NUMERIC = 'numeric',
  REGEX = 'regex',
  REQUIRED = 'required',
  REQUIRED_IF = 'requiredIf',
  SAME_AS_PASSWORD = 'sameAsPassword',
  INTEGER = 'integer',
  DESCRIPTION = 'description',
}
