import type { FormFileDto } from '~/types/common'

export interface FileDto {
  id: string
  path: string
}

export function mapFormToFile(form: FormFileDto): FileDto {
  const path = form.newFile?.tempFile ? form.newFile.tempFile.path : (form.file?.path ?? '')

  return {
    id: '',
    path,
  }
}
