import type {
  FileDto,
  FormFileDto,
  MultiSizeImageDto,
  RequestLocationDto,
} from '~/types/common'
import {
  emptyFormFile,
  emptyRequestLocation,
  mapActivitiesToRequest,
  mapFileToForm,
  mapLocationToRequest,
} from '~/types/common'
import type { DealerDto } from '~/types/client'
import type { DealerTypeEnum } from '~/types/common/dealer/dealer-type.enum'

export interface FormDealerDto {
  email: string | null
  legalEntityCode: string | null
  name: string | null
  website: string | null
  multibrandLogo: FormFileDto<MultiSizeImageDto | FileDto>
  logo: FormFileDto<MultiSizeImageDto | FileDto>
  bannerImage: FormFileDto<MultiSizeImageDto | FileDto>
  phone: string | null
  location: RequestLocationDto | null
  activities: string[]
  type: DealerTypeEnum | null
  description?: string | null
}

export function emptyFormDealer(): FormDealerDto {
  return {
    email: null,
    legalEntityCode: null,
    name: null,
    website: null,
    phone: null,
    location: emptyRequestLocation(),
    activities: [],
    type: null,
    bannerImage: emptyFormFile(),
    logo: emptyFormFile(),
    multibrandLogo: emptyFormFile(),
    description: null,
  }
}

export function mapDealerDataToForm(dealerData: DealerDto): FormDealerDto {
  return {
    email: dealerData.email || null,
    legalEntityCode: dealerData.legalEntityCode || null,
    name: dealerData.name,
    website: dealerData.website,
    multibrandLogo: mapFileToForm(dealerData.multibrandLogo),
    logo: mapFileToForm(dealerData.logo),
    bannerImage: mapFileToForm(dealerData.bannerImage),
    phone: dealerData.phone,
    location: mapLocationToRequest(dealerData.location),
    activities: mapActivitiesToRequest(dealerData.activities),
    type: dealerData.type,
    description: dealerData.description || null,
  }
}
