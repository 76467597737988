import type { Middleware } from '@nuxt/types'
import { Container } from 'typescript-ioc'
import { RouteNavigatorService } from '~/plugins/route-navigator/service/route-navigator.service'
import { urlToSearchDataMapper } from '~/mappers/client/search-url/url-to-search-data.mapper'
import { CanonicalUrlService } from '~/modules/common/canonical-url/service/canonical-url.service'

const redirect = (rns: RouteNavigatorService, redirectLink: string) => {
  let metaStatusCodeNode = document.querySelector('meta[name="prerender-status-code"]')

  if (!metaStatusCodeNode) {
    metaStatusCodeNode = document.createElement('meta')
  }

  metaStatusCodeNode.setAttribute('name', 'prerender-status-code')
  metaStatusCodeNode.setAttribute('content', '301')

  let metaHeaderNode = document.querySelector('meta[name="prerender-header"]')

  if (!metaHeaderNode) {
    metaHeaderNode = document.createElement('meta')
  }

  metaHeaderNode.setAttribute('name', 'prerender-header')
  metaHeaderNode.setAttribute('content', `Location: ${redirectLink}`)

  document.head.appendChild(metaStatusCodeNode)
  document.head.appendChild(metaHeaderNode)

  rns.redirect({ path: redirectLink })
}

const FilterTryPrettyUrlMiddleware: Middleware = ({ route, app }) => {
  const canonUrlService = Container.get(CanonicalUrlService)

  const searchData = urlToSearchDataMapper(route.path)

  const regex = /page-(\d+)/
  const match = route.path.match(regex)
  const page = match ? `/${match[0]}` : ''

  const canon = canonUrlService.tryCanonLink(route.params.type, searchData)

  if (route.path.split(';').length > 2 && canon) {
    const rns = new RouteNavigatorService(app.router!)
    const redirectLink = rns.resolve(canon.to).href + page

    redirect(rns, redirectLink)
  }
}

export default FilterTryPrettyUrlMiddleware
