import { CharacteristicTypesEnum } from '~/types/common'
import type {
  CharacteristicDto,
  CharacteristicValueListDto,
  ColorCharacteristicDto,
  MultiChoiceCharacteristicDto,
  SingleChoiceCharacteristicDto,
} from '~/types/common'
import type { BaseCharacteristicDto } from '~/types/client'
import DataCollectionService from '~/api/common/data-collection.service'

export function characteristicValuesMapper(characteristicValues?: CharacteristicValueListDto[]) {
  if (!characteristicValues) {
    return
  }

  const characteristicsMap = DataCollectionService.collections.characteristic!.maps.id
  const featuresMap = DataCollectionService.collections.features!.maps.id

  const valuesToRemove = []

  for (const characteristicValue of characteristicValues) {
    const characteristicId = <unknown>characteristicValue.characteristic as string

    if (characteristicId in characteristicsMap) {
      characteristicValue.characteristic = characteristicsMap[characteristicId] as CharacteristicDto
    } else if (characteristicId in featuresMap) {
      characteristicValue.characteristic = featuresMap[characteristicId] as CharacteristicDto
    }

    if (typeof characteristicValue.characteristic !== 'object') {
      valuesToRemove.push(characteristicValue)
      continue
    }

    if (!characteristicValue.value) {
      continue
    }

    switch (characteristicValue.characteristic.dtype) {
      case CharacteristicTypesEnum.multiChoice:
        const valueIds = <unknown>characteristicValue.value as string[]
        const multiChoiceCharacteristicValue = (characteristicValue as CharacteristicValueListDto<MultiChoiceCharacteristicDto<BaseCharacteristicDto>>)

        multiChoiceCharacteristicValue.value = multiChoiceCharacteristicValue.characteristic.values.filter(choiceItem => valueIds.includes(choiceItem.id))
        break
      case CharacteristicTypesEnum.singleChoice:
        const valueId = characteristicValue.value as string
        const singleChoiceCharacteristicValue = (characteristicValue as CharacteristicValueListDto<SingleChoiceCharacteristicDto<BaseCharacteristicDto>>)

        singleChoiceCharacteristicValue.value = singleChoiceCharacteristicValue.characteristic.values.find(choiceItem => valueId === choiceItem.id)!
        break

      case CharacteristicTypesEnum.color:
        const colorCharacteristicValue = characteristicValue as CharacteristicValueListDto<ColorCharacteristicDto>
        if (!colorCharacteristicValue.value) {
          break
        }

        const colorId = <unknown>colorCharacteristicValue.value as string

        colorCharacteristicValue.value = colorCharacteristicValue.characteristic.values.find(colorItem => colorId === colorItem.id)!

        break
    }
  }

  for (const valueToRemove of valuesToRemove) {
    characteristicValues.splice(characteristicValues.indexOf(valueToRemove), 1)
  }
}
