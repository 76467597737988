





















































































































































































  import { Component, Mixins, Watch } from 'vue-property-decorator'
  import SideBar from '~/components/client/side-bar/side-bar.vue'
  import ProfileMenu from '~/components/client/profile-menu/profile-menu.vue'
  import { Colors, iconSizes } from '~/configs/client'
  import type { CarTypeEnum } from '~/types/client'
  import { ButtonSizeEnum, ButtonTypeEnum } from '~/types/client'
  import { ClientAuthStoreModule } from '~/store/client/auth'
  import FavoritesCountButton from '~/components/client/header/favorites-count-button.vue'
  import ComparisonCountButton from '~/components/client/header/comparison-count-button.vue'
  import AddAdvertisementButton from '~/components/client/header/add-advertisement-button.vue'
  import DeviceDetectService from '~/services/common/device-detect.service'
  import HeaderLinksMixin from '~/mixins/header-links.mixin'
  import type { HeaderLinkDto } from '~/types/common'
  import AdsBannerComponent from '~/components/client/ads-banner.vue'
  import RegistrationPopup from '~/components/client/header/registration-popup.vue'
  import RegistrationPopupMixin from '~/mixins/registration-popup.mixin'

@Component({
  components: {
    AddAdvertisementButton,
    ComparisonCountButton,
    FavoritesCountButton,
    SideBar,
    ProfileMenu,
    AdsBannerComponent,
    RegistrationPopup,
  },
})
  export default class HeaderMain extends Mixins(HeaderLinksMixin, RegistrationPopupMixin) {
  readonly iconSizes = iconSizes
  readonly colors = Colors
  readonly buttonTypes = ButtonTypeEnum
  readonly buttonSizes = ButtonSizeEnum
  readonly isInternalRequest = DeviceDetectService.isPrerenderer()
  readonly testDriveKey: string = 'isCertificated=true'
  readonly isMobile: boolean = DeviceDetectService.isMobile()

  profileMenuEnabled: boolean = false
  registrationDropdownOpen: boolean = false

  currentUrlType: CarTypeEnum | null = null

  @ClientAuthStoreModule.Getter('isLoggedIn')
  readonly isLoggedIn!: boolean

  @ClientAuthStoreModule.Getter('isDealer')
  readonly isDealer!: boolean

  isHeaderLinkActive(link: HeaderLinkDto): boolean {
    if (this.$route.name !== 'client-type-query') {
      return false
    }

    const hasTestDriveFilter = link.url.includes(this.testDriveKey)

    if (!this.currentUrlType) {
      return hasTestDriveFilter
    }

    return link.url.includes(this.currentUrlType) && !hasTestDriveFilter
  }

  toggleEnabledProfileMenu(): void {
    this.profileMenuEnabled = !this.profileMenuEnabled
  }

  toggleRegistrationDropdown(): void {
    this.registrationDropdownOpen = !this.registrationDropdownOpen
  }

  openSideBar(): void {
    this.$bus.$emit('open-side-bar')
  }

  @Watch('$route.path', { immediate: true })
  changeRoute() {
    if (this.$route.name !== 'client-type-query') {
      this.currentUrlType = null

      return
    }

    this.currentUrlType = !this.$route.path.includes(this.testDriveKey) ? this.$route.params.type as CarTypeEnum : null
  }
  }
