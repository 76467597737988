export enum Colors {
  RED = '#EC2123',
  RED_1 = '#DF4142',
  RED_2 = '#FFEDED',

  BLACK = '#282828',
  BLACK_1 = '#1C1C1D',
  BLACK_2 = '#221F21',

  GRAY = '#777777',
  GRAY_1 = '#929292',
  GRAY_2 = '#B1B1B1',
  GRAY_3 = '#A4A4A4',
  GRAY_4 = '#C5C7C8',
  GRAY_5 = '#DEDEDE',

  WHITE = '#FFFFFF',
  WHITE_1 = '#F6F6F6',
  WHITE_2 = '#E0DEDE',
  WHITE_3 = '#EBEBEB',

  BROWN = '#7A6455',

  BLUE = '#334070',
  BLUE_1 = '#2D3E50',
  BLUE_2 = '#546171',

  PINK = '#FFEDED',

  GREEN = '#38973B',

  YELLOW = '#EF9E00',
  YELLOW_1 = '#FF9110',
}
