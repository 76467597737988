import type { Plugin } from '@nuxt/types'
import type { AxiosInstance } from 'axios'
import { VueAuthenticate } from 'vue-authenticate'
import type { AuthTypesEnum } from '~/types/common'
import AuthService from '~/plugins/authenticate/service/auth.service'
import { vueAuthenticateProviders } from '~/plugins/authenticate/vue-authenticate-providers'
import type { AuthenticateContract } from '~/plugins/authenticate/type'
import localStorageServiceProvider from '~/services/common/storage/local-storage-service.provider'

declare module 'vue/types/vue' {
  interface Vue {
    $authenticate(type?: AuthTypesEnum): AuthenticateContract,

    $t(key: string, vars?: object): string,
  }
}

const authenticate: Plugin = function ({ store, $axios, app }, inject) {
  const vueAuth = new VueAuthenticate($axios as AxiosInstance, {
    providers: vueAuthenticateProviders,
  })
  inject('authenticate', (type: AuthTypesEnum) => new AuthService(type, store, $axios, vueAuth, app.$routeNavigator, localStorageServiceProvider()))
}

export default authenticate
