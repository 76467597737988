import type { StorageContract } from '~/services/common/storage/storage.contract'

/** TODO: Deprecated - look into nuxt/modules/common/storage */

class LocalStorageService implements StorageContract {
  get<T = unknown>(key: string): T | null {
    return <unknown>localStorage.getItem(key) as T || null
  }

  set(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  remove(key: string): void {
    localStorage.removeItem(key)
  }

  getKeysByRegExp(keyExpression: RegExp): string[] {
    const keys = Object.keys(localStorage)

    return keys.filter(item => keyExpression.test(item))
  }

  getSize(key: string): number {
    const value = localStorage.getItem(key)
    const size = value ? key.length + value.length : 0

    return size * 2 / 1024
  }
}

export default new LocalStorageService()
