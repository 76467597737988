import type { Plugin } from '@nuxt/types'

declare module 'vue/types/vue' {
  interface Vue {
    $serverUrlFormatter(path: string | null): string,
  }
}

const serverUrlFormatter: Plugin = function (_context, inject) {
  inject('serverUrlFormatter', (path: string | null) => {
    return path
  })
}

export default serverUrlFormatter
