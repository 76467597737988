import type { RawLocation } from 'vue-router'
import type { VueRouter } from '~/node_modules/vue-router/types/router'
import type { ResolvedRouteDto } from '~/plugins/route-navigator/type'
import type { Dictionary } from '~/types/common'

export class RouteNavigatorService {
  constructor(
    private readonly router: VueRouter,
  ) {
  }

  resolve(location: RawLocation): ResolvedRouteDto {
    return this.router.resolve(location)
  }

  getParams(location: RawLocation): Dictionary<string> {
    return this.resolve(location).resolved.params
  }

  pushState(location: RawLocation): void {
    window.history.pushState({}, '', this.resolve(location).href)
  }

  redirect(rawLocation: RawLocation): void {
    location.assign(this.resolve(rawLocation).href)
  }
}
