export const addAdvertisementFormPercents = {
  vinPlate: 20,
  brandModelYear: 14,
  price: 6.3,
  mileage: 6.3,
  location: 1.4,
  imageList: 14,
  video: 5,
  features: 5,
  description: 14,
  characteristics: 14,
}
