import type { CarDto, FullCarDto } from '~/types/client'

export interface CarState {
  currentCar: FullCarDto | CarDto | null
  recommendedCars: CarDto[] | null
}

export function emptyCarState(): CarState {
  return {
    currentCar: null,
    recommendedCars: null,
  }
}
