import type { DataCollectionTypeEnum } from '~/types/common'
import DataCollectionService from '~/api/common/data-collection.service'

type StringOrNumber = string | number

export function mapFromDataCollection<T = unknown | unknown[]>(
  value: T extends unknown[] ? StringOrNumber[] : StringOrNumber,
  dataCollectionType: DataCollectionTypeEnum,
): T {
  const dataCollection = DataCollectionService.collections[dataCollectionType]

  if (!dataCollection) {
    throw new Error(`There is no collection with type ${dataCollectionType}`)
  }

  const isArray = Array.isArray(value)
  const itemsToMap = Array.isArray(value) ? value : [value]

  const mappedResult: (T | undefined)[] = []
  for (const itemToMap of itemsToMap) {
    if (itemToMap === null) {
      continue
    }

    if (typeof itemToMap === 'object' && DataCollectionService.dataCollectionItemIdFieldKey in itemToMap) {
      // eslint-disable-next-line no-console
      console.warn('Double mapping. Item to map is already mapped', { itemToMap, dataCollectionType })

      mappedResult.push(itemToMap)
      continue
    }

    const mappedItem = dataCollection!.maps.id[itemToMap] as (T | undefined)

    if (!mappedItem) {
      // eslint-disable-next-line no-console
      console.warn(`There is no entity with this id in ${dataCollection.type}`, itemToMap)
    }

    mappedResult.push(mappedItem)
  }

  return (isArray ? mappedResult : mappedResult.shift()) as T
}
