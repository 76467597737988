import AdvertisementListLocalAbstract from '~/services/client/local-list/advertisement-list-local.abstract'
import CarService from '~/api/client/car.service'
import type { AdvertisementDto } from '~/types/client'

class AnonFavoriteListService extends AdvertisementListLocalAbstract {
  cacheKey = 'favorite-list'
  maxLength = parseInt(process.env.maxFavoriteAdvertisements!, 10)
  maxLengthError = 'favorite_list.favorite-list-to-many-advertisements'

  protected async getAdvertisements(idsToFetch: string[]): Promise<AdvertisementDto[]> {
    return await CarService.listByIdsPublicOrArchive(idsToFetch) as AdvertisementDto[]
  }
}

export default new AnonFavoriteListService()
