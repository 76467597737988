import type { CharacteristicValueListDto } from '~/types/common'
import { CharacteristicTypesEnum } from '~/types/common'
import type { ParseCharacteristicValueStrategyInterface } from '~/plugins/formatter/characteristic-value/parse-characteristic-value-strategy/parse-characteristic-value-strategy.interface'

class ParseTextCharacteristicValueStrategy implements ParseCharacteristicValueStrategyInterface {
  support(type: CharacteristicTypesEnum): boolean {
    return type === CharacteristicTypesEnum.text
  }

  parse(valueList: CharacteristicValueListDto): string {
    return valueList.value ? String(valueList.value) : '--'
  }
}

export default new ParseTextCharacteristicValueStrategy()
