import dayjs from 'dayjs'
import type { FormDealerActionDto } from '~/types/common'

export interface RequestDealerActionDto {
  startAt: number,
  endAt: number,
  title: string,
  terms: string,
  description: string,
  advertisements: string[],
  dealer: string,
}

export function mapFormDealerActionToRequest(formData: FormDealerActionDto): RequestDealerActionDto {
  return {
    startAt: dayjs(formData.startAt!).startOf('day').unix(),
    endAt: dayjs(formData.endAt!).endOf('day').unix(),
    title: formData.title!,
    terms: formData.terms!,
    description: formData.description!,
    advertisements: formData.advertisements,
    dealer: formData.dealer.id,
  }
}
