import dayjs from 'dayjs'
import type { DealerRolesEnum, FormEditDealerRoleDto } from '~/types/common'

export interface RequestEditDealerRoleDto {
  role: DealerRolesEnum
  deputy: string | null
  deputyFrom: number | null
  deputyTo: number | null
}

export function mapFromEditDealerRoleToRequest(formData: FormEditDealerRoleDto): RequestEditDealerRoleDto {
  const hasDeputy = Boolean(formData.deputy && formData.deputy.id)

  return {
    role: formData.role,
    deputy: hasDeputy ? formData.deputy!.id : null,
    deputyFrom: hasDeputy && formData.deputyFrom ? dayjs(formData.deputyFrom).startOf('day').unix() : null,
    deputyTo: hasDeputy && formData.deputyTo ? dayjs(formData.deputyTo).endOf('day').unix() : null,
  }
}
