export enum CharacteristicCode {
  COLOR = 'color',
  ENGINE_VOLUME = 'engine_volume',
  GEARBOX_TYPE = 'gearbox_type',
  DRIVE_TYPE = 'drive_type',
  SEATING = 'seating',
  BROUGHT_FROM = 'brought_from',
  CONDITION = 'condition',
  COLOR_TYPE = 'color_type',
  BATTERY_CAPACITY = 'battery_capacity',
}
