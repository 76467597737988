import type { Plugin } from '@nuxt/types'
import type { WithCarName } from '~/types/common'

declare module 'vue/types/vue' {
  interface Vue {
    $carNameFormatter(car: WithCarName): string,
  }
}

const carNameFormatter: Plugin = function (_context, inject) {
  inject('carNameFormatter', (car: WithCarName) => {
    return `${car.model.brand.name} ${car.model.name} ${car.year}`.trim()
  })
}

export default carNameFormatter
