import type { Plugin } from '@nuxt/types'
import dayjs from 'dayjs'
import 'dayjs/locale/uk'

declare module 'vue/types/vue' {
  interface Vue {
    $timestampFormatter(timestamp: number | null, format?: string): string,
  }
}

const timestampFormatter: Plugin = function (_context, inject) {
  inject('timestampFormatter', (timestamp: number | null, format: string = 'DD.MM.YYYY') => {
    if (timestamp === null) {
      return ''
    }

    return dayjs.unix(timestamp).locale('uk').format(format)
  })
}

export default timestampFormatter
