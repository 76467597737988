import type { Middleware } from '@nuxt/types'
import { AuthTypesEnum } from '~/types/common'
import InvalidJwtException from '~/exceptions/invalid-jwt.exception'

const Auth: Middleware = async ({ app, route }) => {
  if (app.$authenticate(AuthTypesEnum.ADMIN).checkToken()) {
    try {
      await app.$authenticate(AuthTypesEnum.ADMIN).setAxiosToken()
      await app.$authenticate(AuthTypesEnum.ADMIN).refreshUserData()
    } catch (error) {
      if (error instanceof InvalidJwtException) {
        app.$authenticate().logout()

        return
      }

      throw error
    }

    if (route.name !== 'admin-login') {
      return
    }

    await app.router?.push({ name: 'admin' })

    return
  }

  if (route.name === 'admin-login') {
    return
  }

  await app.router?.push({ name: 'admin-login' })
}

export default Auth
