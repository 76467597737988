import type {
  FormBaseCharacteristicDto,
  TextCharacteristicDto,
} from '~/types/admin'
import {
  emptyFormBaseCharacteristic,
  mapBaseCharacteristicToForm,
} from '~/types/admin'
import type { SectionDto } from '~/types/common'
import { CharacteristicTypesEnum } from '~/types/common'

export interface FormTextCharacteristicDto extends FormBaseCharacteristicDto {
  minLength: number | null
  maxLength: number | null
}

export function emptyFormTextCharacteristic(section: SectionDto): FormTextCharacteristicDto {
  return {
    ...emptyFormBaseCharacteristic(CharacteristicTypesEnum.text, section),
    filter: false,
    minLength: null,
    maxLength: null,
  }
}

export function mapTextCharacteristicToForm(characteristic: TextCharacteristicDto) : FormTextCharacteristicDto {
  return {
    ...mapBaseCharacteristicToForm(characteristic),
    filter: false,
    minLength: characteristic.minLength || null,
    maxLength: characteristic.maxLength || null,
  }
}
