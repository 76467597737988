export enum SocialLinkCodeEnum {
  FACEBOOK = 'facebook',
  TELEGRAM = 'telegram',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  TWITTER = 'twitter',
  X = 'x',
  LINKEDIN = 'linkedin',
  TIKTOK = 'tiktok',
}
