
















import { Vue, Component } from 'vue-property-decorator'
import { ButtonTypeEnum } from '~/types/client'
import VBtn from '~/components/client/ui/buttons/v-btn.vue'

@Component({
  components: {
    VBtn,
  },
})
export default class AdminError404 extends Vue {
  readonly buttonTypes = ButtonTypeEnum
}
