import type { EditableDefaultRowDto } from '~/types/admin/editable/editable-default-row.dto'

export type EditableCarClassDto = EditableDefaultRowDto & {
  id: string
}

export function emptyEditableCarClassDto(): EditableCarClassDto {
  return {
    id: '',
    name: '',
  }
}
