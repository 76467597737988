import type { Dictionary } from '~/types/common'

export class SvgIdUniquelizer {
  private readonly replacedIdMap: Dictionary<string> = {}
  private readonly anchorSearcher: RegExp = /url\(#(.*)\)/

  constructor(
    private readonly svg: SVGElement,
    private readonly uniqueKey: string,
  ) {
  }

  public replace() {
    this.replaceIds(this.svg)
    this.replaceRefs(this.svg)
  }

  private replaceIds(element: Element): void {
    for (const child of Array.from(element.children)) {
      this.replaceIds(child)
    }

    this.replaceId(element)
  }

  private replaceId(element: Element): void {
    if (!element.id || this.isRootElement(element)) {
      return
    }

    element.id = this.generateNewId(element.id)
  }

  private generateNewId(oldId: string): string {
    const newId = `${oldId}-${this.uniqueKey}`
    this.replacedIdMap[oldId] = newId

    return newId
  }

  private replaceRefs(element: Element): void {
    for (const child of Array.from(element.children)) {
      this.replaceRefs(child)
    }

    this.replaceRef(element)
  }

  private replaceRef(element: Element): void {
    for (const attr of Array.from(element.attributes)) {
      if (!this.isAttributeSupported(attr)) {
        continue
      }

      const oldId = this.extractOldIdFromAttribute(attr)
      const newId = this.mapOldIdToNewId(oldId)

      attr.value = SvgIdUniquelizer.generateNewValue(newId)
    }
  }

  private isAttributeSupported(attribute: Attr): boolean {
    if (!['fill', 'stroke'].includes(attribute.name.toLowerCase())) {
      return false
    }

    return this.anchorSearcher.test(attribute.value)
  }

  private extractOldIdFromAttribute(attribute: Attr): string {
    const matchResults = attribute.value.match(this.anchorSearcher)!

    if (!matchResults || !matchResults[1]) {
      throw new Error('The value of svg element attribute does not contain anchor')
    }

    return matchResults[1]
  }

  private mapOldIdToNewId(oldId: string): string {
    const newId = this.replacedIdMap[oldId]
    if (!newId) {
      throw new Error(`There is no such replaced oldId: ${oldId}`)
    }

    return newId
  }

  private static generateNewValue(newId: string): string {
    return `url(#${newId})`
  }

  private isRootElement(element: Element): boolean {
    return element === this.svg
  }
}
