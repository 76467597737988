import type {
  CharacteristicValueListDto,
  ColorItemDto,
} from '~/types/common'
import {
  CharacteristicTypesEnum,
} from '~/types/common'
import type { ParseCharacteristicValueStrategyInterface } from '~/plugins/formatter/characteristic-value/parse-characteristic-value-strategy/parse-characteristic-value-strategy.interface'

class ParseColorCharacteristicValueStrategy implements ParseCharacteristicValueStrategyInterface {
  support(type: CharacteristicTypesEnum): boolean {
    return type === CharacteristicTypesEnum.color
  }

  parse(valueList: CharacteristicValueListDto): string {
    const color = <unknown>valueList.value as ColorItemDto

    return color ? color.name : '--'
  }
}

export default new ParseColorCharacteristicValueStrategy()
