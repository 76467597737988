import type { Middleware } from '@nuxt/types'

const PageMiddleware: Middleware = ({ app, route }) => {
  const url = route.path
  const getPageNumberFromUrl = (url: string): number => {
    // Regular expression to match the page number in the URL
    const pageNumberRegex = /page-(\d+)/

    // Extract the page number using the regular expression
    const matches = url.match(pageNumberRegex)

    if (matches && matches.length > 1) {
      return parseInt(matches[1])
    }

    return 1
  }

  if (url.includes('page-1') && getPageNumberFromUrl(url) === 1) {
    let finalUrl = url.replace(/\/page-1/g, '/')

    finalUrl = finalUrl.replace(/\/page-1/, '').replace(/\/\//g, '/')

    app?.router?.push({ path: finalUrl })
  }
}

export default PageMiddleware
