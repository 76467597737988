import DataCollectionService from '~/api/common/data-collection.service'
import type { Dictionary, UnknownObject } from '~/types/common'

export default class JsonService {
  static stringify(value: unknown, compress: boolean = true): string {
    const map: Dictionary<string> = {}

    if (compress) {
      value = JsonService.compressKeys(value, map)

      return JSON.stringify({ map: JsonService.revertMap(map), value })
    }

    return JSON.stringify(value, (_key, value) => JsonService.revertDataCollections(value))
  }

  static parse(text: string): unknown {
    let data = null
    try {
      data = JSON.parse(text)
    } catch (e) {
      return data
    }

    if (!data) {
      return data
    }

    const map = data.map
    const compressedValue = data.value

    return JsonService.decompressKeys(compressedValue, map)
  }

  static clone(value: unknown): unknown {
    return JsonService.parse(JsonService.stringify(value))
  }

  private static revertDataCollections(value: UnknownObject): unknown | null {
    return (DataCollectionService.dataCollectionItemIdFieldKey in value && value[DataCollectionService.dataCollectionItemIdFieldKey] as string in value)
      ? value[value[DataCollectionService.dataCollectionItemIdFieldKey] as string]
      : null
  }

  private static compressKeys(value: unknown, map: Dictionary<string>): unknown {
    if (typeof value !== 'object' || value === null) {
      return value
    }

    if (Array.isArray(value)) {
      return value.map(value => JsonService.compressKeys(value, map))
    }

    const compressedValue: UnknownObject = {}

    const revertingDataCollectionResult = JsonService.revertDataCollections(value as UnknownObject)
    if (revertingDataCollectionResult) {
      return revertingDataCollectionResult
    }

    for (const key in value) {
      const newKey = JsonService.getNewMapKey(key, map)

      compressedValue[newKey] = JsonService.compressKeys((value as Dictionary<string>)[key], map)
    }

    return compressedValue
  }

  private static decompressKeys(compressedValue: unknown, map: Dictionary<string>): unknown {
    if (typeof compressedValue !== 'object' || compressedValue === null) {
      return compressedValue
    }

    if (Array.isArray(compressedValue)) {
      return compressedValue.map(value => JsonService.decompressKeys(value, map))
    }

    const decompressedValue: Dictionary<unknown> = {}

    for (const key in compressedValue) {
      const oldKey = map[key]

      decompressedValue[oldKey] = JsonService.decompressKeys((compressedValue as Dictionary<string>)[key], map)
    }

    return decompressedValue
  }

  private static getNewMapKey(propertyKey: string, map: Dictionary<string>): string {
    if (propertyKey in map) {
      return map[propertyKey]
    }

    for (let nCharTry = 1; nCharTry <= propertyKey.length; nCharTry++) {
      const newCharKey = propertyKey.substr(0, nCharTry)

      if (!Object.values(map).includes(newCharKey)) {
        map[propertyKey] = newCharKey

        return newCharKey
      }

      for (let nNumberTry = 0; nNumberTry < 10; nNumberTry++) {
        const newNumberKey = newCharKey + nNumberTry

        if (!Object.values(map).includes(newNumberKey)) {
          map[propertyKey] = newNumberKey

          return newNumberKey
        }
      }
    }

    const newKey = propertyKey + Math.floor(Math.random() * 1000)

    map[propertyKey] = newKey

    return newKey
  }

  private static revertMap(map: Dictionary<string>): Dictionary<string> {
    const revertedMap: Dictionary<string> = {}
    for (const key in map) {
      revertedMap[map[key]] = key
    }

    return revertedMap
  }
}
