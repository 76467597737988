import type {
  FileDto,
  FormImageListItemDto,
  ImageListDto,
  ImageListItemDto,
} from '~/types/common'
import {
  FormImageListItemStateEnum,
} from '~/types/common'

export type FormImageListDto = ImageListDto<FormImageListItemDto>

export function emptyFormImageListDto(): FormImageListDto {
  return { images: [] }
}

export function copyFormImageListDto(
  formImageListDto: FormImageListDto,
  imageListDto: ImageListDto<ImageListItemDto<FileDto>>,
  imageState:FormImageListItemStateEnum = FormImageListItemStateEnum.NEW,
) {
  mapFormImageListDto(formImageListDto, imageListDto)

  formImageListDto.images.forEach((image) => {
    if (!image.file) {
      return
    }
    image.existedFile = image.file as FileDto
    image.file = undefined
    image.state = imageState
  })
}

export function mapFormImageListDto(
  formImageListDto: FormImageListDto,
  imageListDto: ImageListDto<ImageListItemDto<FileDto>>,
) {
  imageListDto.images.sort((current, next) => current.position - next.position)
  const formattedImages = imageListDto.images.map((listItem) => {
    return {
      id: listItem.id,
      position: listItem.position,
      file: listItem.file,
      newFile: null,
      existedFile: null,
      state: FormImageListItemStateEnum.NOT_CHANGED,
    }
  })
  const otherImages = formImageListDto.images.filter(item => (item.newFile || item.file) && item.state !== FormImageListItemStateEnum.REMOVED)

  formImageListDto.images = [...otherImages, ...formattedImages]
}
