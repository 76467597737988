import type { FullUserDto, ModeratingCarDto, WithFormCharacteristicValuesDto, WithFormImageListDto } from '~/types/admin'
import type {
  BodyTypeDto,
  CarMarksDto,
  CategoryDto,
  ContactUserDto,
  CurrencyDto, EquipmentDto,
  FuelTypeDto, LocationDto,
  ModelDto,
  StatusLogDto,
  WithDate, WithVinPlateNumber,
} from '~/types/common'
import {
  emptyCharacteristicValueListDto,
  emptyFormImageListDto,
  mapCharacteristicValueLists,
  mapFormImageListDto,
} from '~/types/common'

export interface FormModeratingCarDto extends WithDate, WithFormCharacteristicValuesDto, WithFormImageListDto, WithVinPlateNumber {
  id: string
  category: CategoryDto,
  moderateLockBy: FullUserDto
  moderateLockAt: number
  currentStatusLog: StatusLogDto
  statusLogs: StatusLogDto[]
  bodyType: BodyTypeDto
  fuelType: FuelTypeDto
  model: ModelDto
  year: number
  location: LocationDto
  mileage: number|null
  marks: CarMarksDto
  relevance: number
  currency: CurrencyDto
  price: number
  contactUser: ContactUserDto
  publishedAt: number
  isNew: boolean
  description?: string
  equipment?: EquipmentDto
  organic: boolean|null
}

export function mapModeratingCarToForm(car: ModeratingCarDto): FormModeratingCarDto {
  const imageList = emptyFormImageListDto()
  mapFormImageListDto(imageList, car.imageList)

  const characteristicValues = car.category.characteristicList.map(characteristic => emptyCharacteristicValueListDto(characteristic))
  mapCharacteristicValueLists(characteristicValues, car.characteristicValues)

  return {
    imageList,
    characteristicValues,
    equipment: car.equipment,
    vinCode: car.vinCode,
    plateNumber: car.plateNumber,
    description: car.description,
    id: car.id,
    category: car.category,
    isNew: car.isNew,
    publishedAt: car.publishedAt!,
    contactUser: car.contactUser,
    currency: car.currency,
    price: car.price,
    model: car.model,
    year: car.year,
    location: car.location!,
    mileage: car.mileage !== undefined ? car.mileage : null,
    relevance: car.relevance,
    marks: car.marks,
    bodyType: car.bodyType,
    fuelType: car.fuelType,
    createdAt: car.createdAt,
    updatedAt: car.updatedAt,
    currentStatusLog: car.currentStatusLog,
    moderateLockAt: car.moderateLockAt,
    moderateLockBy: car.moderateLockBy,
    statusLogs: car.statusLogs,
    organic: car.organic !== undefined ? car.organic : null,
  }
}
