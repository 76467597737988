import type { SortingTypeEnum, UnknownObject, FilterStatsDto } from '~/types/common'

export interface FilterableDto<T> {
  items: T[]
  total: number
  currentPage: number
  perPage: number
  orderBy: {
    [P in keyof T]?: SortingTypeEnum
  }
  stats: FilterStatsDto
}

export function emptyFilterableDto<T = UnknownObject>(): FilterableDto<T> {
  return {
    items: [],
    total: 0,
    currentPage: 0,
    perPage: 0,
    orderBy: {},
    stats: {},
  }
}
