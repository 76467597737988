export enum FilterKeysEnum {
  PAGE = 'page',
  CATEGORY = 'category',
  BODY_TYPE = 'bodyType',
  FUEL_TYPE = 'fuelType',
  IS_NEW = 'isNew',
  IS_CERTIFICATED = 'isCertificated',
  IS_SOLD = 'isSold',
  PRICE = 'price',
  MILEAGE = 'mileage',
  YEAR = 'year',
  BRAND = 'brand',
  MODEL = 'model',
  TEST_DRIVE_ABILITY = 'testDriveAbility',
  AVAILABLE_IN_DEALER = 'availableInDealer',
  REGION = 'region',
  CITY = 'city',
  RADIUS = 'radius',
  LON = 'lon',
  LAT = 'lat',
  ACTIVITIES = 'activities',
  NAME = 'name',
  TAGS = 'tags',
  CONTENT = 'content',
}
