import type { Middleware } from '@nuxt/types'

const OnlyAuthMiddleware: Middleware = async ({ app, route }) => {
  if (app.$authenticate().checkToken()) {
    return
  }

  await app.router?.push({ name: 'client-login', query: { redirectUrl: route.fullPath } })
}

export default OnlyAuthMiddleware
