






import { Vue, Component, Prop } from 'vue-property-decorator'
import { ClientAuthStoreModule } from '~/store/client/auth'
import type { UserDataDto } from '~/types/common'
import { ImageFormatEnum } from '~/types/common'

@Component
export default class ProfilePhoto extends Vue {
  @ClientAuthStoreModule.Getter('isLoggedIn')
  readonly isLoggedIn!: boolean

  @ClientAuthStoreModule.Getter('userData')
  userData!: UserDataDto

  @Prop({ default: true })
  readonly useLink!: Boolean

  get photo(): string {
    return this.userData.photo ? this.$serverUrlFormatter(this.userData.photo.pathList.medium) + '.' + ImageFormatEnum.WEBP : require('~/assets/images/icons/photo.png')
  }

  profilePhotoAction() {
    if (this.useLink) {
      this.$router.push({ name: 'client-profile' })

      return
    }

    this.$emit('click')
  }
}
