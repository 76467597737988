import { client } from '~/ports/client'
import type { CreateInterface, FilterableListInterface, ShowInterface, UpdateInterface } from '~/contracts/crud'
import type { ApiInterface } from '~/contracts/crud/api.interface'
import { Api, ApiCreate, ApiFilterableList, ApiShow, ApiUpdate, DataCollection } from '~/decorators'
import type { FilterUserDto, FullUserDto, RequestUserDto } from '~/types/admin'
import type { LoginResponseDTO } from '~/types/common'
import { DataCollectionTypeEnum } from '~/types/common'
import type { UserRegistrationDto } from '~/types/client'

interface UserService extends ApiInterface,
  FilterableListInterface<FullUserDto, FilterUserDto>,
  ShowInterface<FullUserDto>,
  CreateInterface<FullUserDto, UserRegistrationDto>,
  UpdateInterface<FullUserDto, RequestUserDto> {
}

@ApiFilterableList<FullUserDto, FilterUserDto>()
@ApiShow<FullUserDto>({ 'location.city': DataCollectionTypeEnum.city })
@ApiUpdate<FullUserDto, RequestUserDto>({ 'location.city': DataCollectionTypeEnum.city })
@ApiCreate<FullUserDto, UserRegistrationDto>({ 'location.city': DataCollectionTypeEnum.city })
@Api('/admin/users', {
  responseItemListName: 'users',
  responseItemName: 'user',
})
class UserService {
  async loginAs(id: string): Promise<LoginResponseDTO | null> {
    if (!this.apiPrefix) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      return await client.$axios.$get(`${this.apiPrefix}/${id}/login-as`)
    } catch (e) {
      return null
    }
  }

  async sendInvite(id: string): Promise<boolean> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      await client.$axios.$put(`${this.apiPrefix}/${id}/invite`)

      return true
    } catch (e) {
      return false
    }
  }

  @DataCollection({ 'location.city': DataCollectionTypeEnum.city })
  async resetFailLogins(id: string): Promise<FullUserDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      const response = await client.$axios.$put(`${this.apiPrefix}/${id}/reset-fail-logins`)

      return response[this.apiOptions.responseItemName]
    } catch (e) {
      return null
    }
  }

  @DataCollection({ 'location.city': DataCollectionTypeEnum.city })
  async toggleActive(id: string, active: boolean): Promise<FullUserDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      const response = await client.$axios.$put(`${this.apiPrefix}/${id}/toggle-active/${active.toString()}`)

      return response[this.apiOptions.responseItemName]
    } catch (e) {
      return null
    }
  }
}

export default new UserService()
