import Vue from 'vue'
import type { DirectiveBinding } from 'vue/types/options'
import NoDataComponent from '~/components/common/directives/no-data.vue'
import JsonService from '~/services/common/json.service'

const dataInfoHtmlClass = 'no-data-info'

function getNoDataComponent(title: string): Element {
  const NoDataComponentClass = Vue.extend(NoDataComponent)
  const instance = new NoDataComponentClass()
  instance.$mount()

  if (title) {
    instance.$props.title = title
  }

  const element = instance.$el
  element.classList.add(dataInfoHtmlClass)

  return element
}

function isValueNotEmpty(value: any): boolean {
  if (value === null || value === undefined) {
    return false
  }

  if (typeof value === 'number') {
    return true
  }

  if (Array.isArray(value)) {
    return value.length > 0
  }

  if (typeof value === 'object') {
    return JsonService.stringify(value) !== JsonService.stringify({})
  }

  return !!value
}

function isTitleChanged(binding: DirectiveBinding) {
  return binding.value
      && binding.oldValue
      && binding.value.title
      && binding.oldValue.title
      && binding.value.title !== binding.oldValue.title
}

function getDataInfoElements(element: Element): Element[] {
  return Array.from(element.getElementsByClassName(dataInfoHtmlClass)).filter(child => child.parentElement === element)
}

Vue.directive('no-data', {
  update: (el, binding) => {
    const dataInfoElements = getDataInfoElements(el)

    if (isValueNotEmpty(binding.value.value) || isTitleChanged(binding)) {
      dataInfoElements[0] && dataInfoElements[0].remove()
      el.classList.remove('no-data-enabled')

      return
    }

    el.classList.add('no-data-enabled')

    if (dataInfoElements.length) {
      return
    }

    el.append(getNoDataComponent(binding.value.title))
  },

  bind: (el: HTMLElement, binding: DirectiveBinding) => {
    const dataInfoElements = getDataInfoElements(el)

    if (isValueNotEmpty(binding.value.value) || isTitleChanged(binding)) {
      return
    }

    el.classList.add('no-data-enabled')

    if (dataInfoElements.length) {
      return
    }

    el.append(getNoDataComponent(binding.value.title))
  },
})
