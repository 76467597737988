import type { Vue } from 'vue/types/vue'
import { createDecorator } from 'vue-class-component'

export function QueryStringParam(queryParam?: string) {
  return createDecorator((options, key) => {
    options.computed = options.computed || {}
    options.computed[key] = {
      cache: false,
      get(this: Vue) {
        return this.$route.query[queryParam || key] || null
      },
    }
  })
}

export function QueryBoolParam(queryParam?: string) {
  return createDecorator((options, key) => {
    options.computed = options.computed || {}
    options.computed[key] = {
      cache: false,
      get(this: Vue) {
        return (queryParam || key) in this.$route.query
      },
    }
  })
}
