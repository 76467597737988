



























import { Component, Prop, Vue } from 'vue-property-decorator'
import type { AdminMenuItem, UserRoleEnum } from '~/types/common'
import { AdminAuthStoreModule } from '~/store/admin/auth'

@Component
export default class Item extends Vue {
  @Prop({ required: true })
  readonly item!: AdminMenuItem

  @AdminAuthStoreModule.Getter('grantedRoles')
  readonly grantedRoles!: UserRoleEnum[]

  get isRoleGranted(): boolean {
    if (!this.item.roles || !this.item.roles.length) {
      return true
    }

    return this.grantedRoles.some(role => this.item.roles!.includes(role))
  }

  get iconPath() {
    return require(`~/assets/images/icons/${this.item.icon}.svg`)
  }

  isCurrent(name: string): boolean {
    return this.$route.name === name
  }
}
