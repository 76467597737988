export enum MetadataPageKeyEnum {
  'catalog-new' = 'catalog-new',
  'catalog-used' = 'catalog-used',
  'catalog-certificated' = 'catalog-certificated',
  'detail-search' = 'detail-search',
  'registration' = 'registration',
  'account' = 'account',
  'comparision' = 'comparision',
  'login' = 'login',
  'favorite-adv' = 'favorite-adv',
  'contact-us' = 'contact-us',
  'privacy-policy' = 'privacy-policy',
  'personal-data' = 'personal-data',
  'main' = 'main',
  'used-car' = 'used-car',
  'catalog-all' = 'catalog-all',
  'cookies-policy' = 'cookies-policy',
  'dealer' = 'dealer',
  'trader' = 'trader',
  'new-car-characteristic' = 'new-car-characteristic',
  'new-car-photo' = 'new-car-photo',
  'new-car-video' = 'new-car-video',
  'new-car-test-drive' = 'new-car-test-drive',
  'new-car-dealer' = 'new-car-dealer',
  'used-car-characteristic' = 'used-car-characteristic',
  'used-car-photo' = 'used-car-photo',
  'used-car-video' = 'used-car-video',
  'used-car-test-drive' = 'used-car-test-drive',
  'advantages-users' = 'advantages-users',
  'advantages-business' = 'advantages-business',
  'about-us' = 'about-us',
  'password-recovery' = 'password-recovery',
  'new-car' = 'new-car',
  'available-models' = 'available-models',
  'full-news' = 'full-news',
  'news' = 'news',
  'brands' = 'brands',
  'dealer-list' = 'dealer-list',
  'trader-list' = 'trader-list',
  'add-advertisement' = 'add-advertisement',
  'seller' = 'seller',
  'certification-info' = 'certification-info',
  'news-author' = 'news-author',
}
