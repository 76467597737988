import {
  PasswordRecoveryMethodEnum,
} from '~/types/client'

export interface ResetPasswordDto {
  method: PasswordRecoveryMethodEnum
  code: string | null
  phone?: string
  newPassword: string
  email?: string
  token?: string
}

export function getEmptyResetPasswordDTO(): ResetPasswordDto {
  return {
    method: PasswordRecoveryMethodEnum.PHONE,
    code: null,
    phone: '',
    newPassword: '',
    email: '',
    token: '',
  }
}
