import type { ConditionItemDto, MetadataDto, QuestionAnswerDto } from '~/types/common'

export type FormMetadataDto = {
  metaTitle?: string
  metaDescription?: string
  metaHeader?: string
  metaType?: string
  additionalText?: string
  conditionList: ConditionItemDto[]
  questionAnswerList: QuestionAnswerDto[]
}

export function emptyFormMetadataDto(): FormMetadataDto {
  return {
    conditionList: [],
    questionAnswerList: [],
  }
}

export function mapMetadataToForm(input: MetadataDto): FormMetadataDto {
  return {
    metaDescription: input.metaDescription,
    metaTitle: input.metaTitle,
    metaType: input.metaType,
    metaHeader: input.metaHeader,
    additionalText: input.additionalText,
    conditionList: input.conditionList,
    questionAnswerList: input.questionAnswerList.map((qa) => {
      return { ...qa }
    }),
  }
}
