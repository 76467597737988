import type {
  BodyTypeDto,
  FilterBrandModelGroupDto,
  CarCategoryDto,
  FilterCharacteristicDto, FilterMarksDto,
} from '~/types/client'
import {
  emptyFilterBrandModelGroupDto,
} from '~/types/client'
import type { BrandDto, FuelTypeDto, LocationRadiusDto, ModelDto, RegionDto, CityDto } from '~/types/common'

export interface FilterSelectedOptionsCarDto {
  isNew: boolean|null
  isSold: boolean|null
  isCertificated: boolean|null
  marks: FilterMarksDto
  category: CarCategoryDto|null
  bodyTypes: BodyTypeDto[]
  fuelTypes: FuelTypeDto[]
  brandModelGroups: FilterBrandModelGroupDto[]
  filterCharacteristics: FilterCharacteristicDto[]
  region: RegionDto[]
  city: CityDto[]
  dealer: string[]
  author: string[]
  location: LocationRadiusDto | null
  isCanonical: boolean
  brands: BrandDto[]
  models: ModelDto[]
}

export function emptyFilterSelectedOptionsCarDto(): FilterSelectedOptionsCarDto {
  return {
    isNew: null,
    isSold: null,
    isCertificated: null,
    marks: {},
    category: null,
    bodyTypes: [],
    fuelTypes: [],
    brandModelGroups: [emptyFilterBrandModelGroupDto()],
    filterCharacteristics: [],
    region: [],
    city: [],
    dealer: [],
    author: [],
    location: null,
    isCanonical: false,
    brands: [],
    models: [],
  }
}

export function clearFilterSelectedOptionsCarDto(selectedOptions: FilterSelectedOptionsCarDto) {
  selectedOptions.isNew = null
  selectedOptions.isSold = null
  selectedOptions.isCertificated = null
  selectedOptions.marks = {}
  selectedOptions.category = null
  selectedOptions.bodyTypes = []
  selectedOptions.fuelTypes = []
  selectedOptions.brandModelGroups = [emptyFilterBrandModelGroupDto()]
  selectedOptions.filterCharacteristics = []
  selectedOptions.region = []
  selectedOptions.city = []
  selectedOptions.location = null
  selectedOptions.isCanonical = false
  selectedOptions.brands = []
  selectedOptions.models = []
}
