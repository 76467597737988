import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type {
  BrandDto,
  FilterSelectedOptionsCarDto,
  ModelDto,
  SearchDataDto,
} from '~/types/client'
import {
  emptyFilterBrandModelGroupDto,
} from '~/types/client'
import { FilterGroupsEnum, FilterKeysEnum } from '~/types/common'
import DataCollectionService from '~/api/common/data-collection.service'

export class BrandModelDecodeStrategy extends StringDecodeStrategyAbstract<FilterSelectedOptionsCarDto> {
  readonly pattern = `^${FilterGroupsEnum.BRAND_MODEL}=(\\w+:${this.slugAndNumberRangePattern}(,${this.slugAndNumberRangePattern})*&?)+$`
  private brandModelIsDefault = true

  process(subject: SearchDataDto<FilterSelectedOptionsCarDto>, searchItemString: string): void {
    const brandModelGroupData = emptyFilterBrandModelGroupDto()
    const searchItemStrings = searchItemString.split('=')

    for (searchItemString of searchItemStrings) {
      const brandModelGroupArray = searchItemString.split('&')
      for (const item of brandModelGroupArray) {
        if (RegExp(`^${FilterKeysEnum.PRICE}:${this.numberRangePattern}$`).test(item)) {
          brandModelGroupData.price = this.parsePriceRange(item.split(':')[1])

          continue
        }

        if (RegExp(`^${FilterKeysEnum.MILEAGE}:${this.numberRangePattern}$`).test(item)) {
          brandModelGroupData.mileage = this.parseRange(item.split(':')[1])

          continue
        }

        if (RegExp(`^${FilterKeysEnum.YEAR}:${this.numberRangePattern}$`).test(item)) {
          brandModelGroupData.year = this.parseRange(item.split(':')[1])

          continue
        }

        if (RegExp(`^${FilterKeysEnum.BRAND}:${this.slugPattern}$`).test(item)) {
          const slug = item.split(':')[1]
          brandModelGroupData.brand = DataCollectionService.collections.car_brand!.maps.slug[slug] as BrandDto

          continue
        }

        if (RegExp(`^${FilterKeysEnum.MODEL}:${this.slugPattern}$`).test(item)) {
          const slug = item.split(':')[1]
          brandModelGroupData.model = DataCollectionService.collections.car_model!.maps.slug[slug] as ModelDto
        }
      }
    }

    if (this.brandModelIsDefault) {
      this.brandModelIsDefault = false
      subject.selectedOptions.brandModelGroups.splice(0, 1, brandModelGroupData)

      return
    }

    subject.selectedOptions.brandModelGroups.push(brandModelGroupData)
  }
}
