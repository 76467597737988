import type { FileDto } from '~/types/common'

export interface FormFileDto<FileType = FileDto> {
  file?: FileType
  newFile: { tempFile?: FileType, existedFile?: FileType } | null
}

export function emptyFormFile<FileType = FileDto>(): FormFileDto<FileType> {
  return {
    newFile: null,
  }
}

export function mapFileToForm<FileType = FileDto>(file?: FileType): FormFileDto<FileType> {
  return {
    file,
    newFile: null,
  }
}
