export enum CharacteristicSectionEnum {
  commons = 'commons',
  comfort = 'comfort',
  exterior = 'exterior',
  technicals = 'technicals',
  profitability = 'profitability',
  safety = 'safety',
  interior = 'interior',
  others = 'others',
  features = 'features',
}
