




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PhoneNumberLink extends Vue {
  @Prop({ required: true })
  readonly phone!: string | number

  get normalizedPhone(): string {
    let phone = typeof this.phone === 'number' ? this.phone.toString() : this.phone

    if (phone.length === 12) {
      phone = `+${phone}`
    }

    return phone
  }

  get formattedPhone(): string {
    return this.normalizedPhone
      .replace(/(\+\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 ($2) $3-$4-$5')
  }

  get phoneLink(): string {
    return `tel:${this.normalizedPhone}`
  }
}
