import type {
  FileDto,
  FormFileDto,
  MultiSizeImageDto,
  RequestLocationDto,
  UserDataDto,
} from '~/types/common'
import {
  emptyFormFile, mapFileToForm,
  mapLocationToRequest,
} from '~/types/common'

export interface FormUserDto {
  firstName: string | null
  lastName: string | null
  email: string | null
  photo: FormFileDto<MultiSizeImageDto | FileDto>
  location: RequestLocationDto | null
}

export function emptyFormUser(): FormUserDto {
  return {
    email: null,
    firstName: null,
    lastName: null,
    location: null,
    photo: emptyFormFile(),
  }
}

export function mapUserToForm(user: UserDataDto): FormUserDto {
  return {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    location: user.location ? mapLocationToRequest(user.location) : null,
    photo: mapFileToForm(user.photo),
  }
}
