import type { PromotionConfigDto } from '~/types/common'
import type { RequestPromotionConfigDto } from '~/types/admin'

export interface FormPromotionConfigDto {
  code: string
  price: number
}

export function emptyPromotionConfigToForm(): FormPromotionConfigDto {
  return {
    code: '',
    price: 0,
  }
}

export function mapPromotionConfigToForm(promotionConfig: PromotionConfigDto): FormPromotionConfigDto {
  return {
    code: promotionConfig.code,
    price: promotionConfig.price / 100,
  }
}

export function mapFormPromotionConfigToRequest(formData: FormPromotionConfigDto): RequestPromotionConfigDto {
  return {
    price: Math.round(formData.price * 100),
  }
}
