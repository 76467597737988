export interface CarMarksDto {
  showPlateNumber: boolean
  showVinCode: boolean
  bidding: boolean
  trade: boolean
  tested: boolean
  testDriveAbility: boolean
  availableInDealer: boolean
  hasPaidDataInApi?: boolean
}

export function getEmptyCarMarks(): CarMarksDto {
  return {
    showPlateNumber: false,
    showVinCode: false,
    bidding: false,
    trade: false,
    tested: false,
    testDriveAbility: false,
    availableInDealer: false,
  }
}
