import type { Toasted } from '~/plugins/toasted/type'

class Toast {
  private _toast!: Toasted

  set(toast: Toasted) {
    this._toast = toast
  }

  get toast() {
    return this._toast
  }
}

const toast = new Toast()

export { toast }
