import type { TagCategoryDto } from '~/types/admin'
import type { TagDto } from '~/types/common'

export interface FormTagDto {
  id: string
  title?: string
  category?: TagCategoryDto|null
}

export function tagToFormDtoMapper(tag: TagDto, categories: TagCategoryDto[]): FormTagDto {
  return {
    id: tag.id,
    title: tag.title,
    category: tag.category ? categories.find(category => category.id === tag.category!.id) : null,
  }
}

export function emptyFormTagDto(): FormTagDto {
  return {
    id: '',
    title: '',
    category: null,
  }
}
