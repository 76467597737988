import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type { FilterSelectedOptionsCarDto, SearchDataDto } from '~/types/client'
import DataCollectionService from '~/api/common/data-collection.service'
import type { FuelTypeDto } from '~/types/common'
import { FilterKeysEnum } from '~/types/common'

export class FuelTypeDecodeStrategy extends StringDecodeStrategyAbstract<FilterSelectedOptionsCarDto> {
  readonly pattern = `^${FilterKeysEnum.FUEL_TYPE}=(${this.slugPattern},?)+$`

  process(subject: SearchDataDto<FilterSelectedOptionsCarDto>, searchItemString: string): void {
    const fuelTypeSlugs = searchItemString.split('=')[1].split(',')

    for (const slug of fuelTypeSlugs) {
      const fuelType = <unknown>DataCollectionService.collections.car_fuel_type!.maps.slug[slug] as FuelTypeDto

      if (!fuelType) {
        continue
      }

      subject.selectedOptions.fuelTypes.push(fuelType)
    }
  }
}
