import type { RequestExternalBannerDto } from '~/types/admin'
import type { ExternalBannerDto } from '~/types/common'

export interface FormExternalBannerDto extends ExternalBannerDto {
}

export function mapExternalBannerToFrom(banner: ExternalBannerDto): FormExternalBannerDto {
  return { ...banner }
}

export function mapFormExternalBannerRoRequest(formData: FormExternalBannerDto): RequestExternalBannerDto {
  return {
    active: formData.active,
    sourceCode: formData.sourceCode,
  }
}
