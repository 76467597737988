import type {
  RequestCharacteristicValueListDto,
  RequestFileDto, RequestImageListDto,
} from '~/types/common'
import {
  EquipmentStatusesEnum, mapCharacteristicValueListToRequest, mapFormFileToRequest, mapFormImageListToRequest,
} from '~/types/common'
import type { FormEquipmentDto } from '~/types/admin'

export interface RequestEquipmentDto {
  name: string
  modification: string
  image?: RequestFileDto | null
  status: EquipmentStatusesEnum
  characteristicValues: RequestCharacteristicValueListDto
  imageList: RequestImageListDto
}

export function mapFromEquipmentToRequest(formEquipment: FormEquipmentDto): RequestEquipmentDto {
  return {
    name: formEquipment.name,
    status: formEquipment.published ? EquipmentStatusesEnum.PUBLIC : EquipmentStatusesEnum.DRAFT,
    modification: formEquipment.modification.id,
    characteristicValues: mapCharacteristicValueListToRequest(formEquipment.characteristicValues),
    imageList: mapFormImageListToRequest(formEquipment.imageList),
    image: mapFormFileToRequest(formEquipment.image),
  }
}
