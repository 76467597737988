import { Container } from 'typescript-ioc'
import type { CarDto, FullCarDto, LinkDto, CarCategoryDto } from '~/types/client'
import { CarTypeEnum, isFullCar } from '~/types/client'
import { i18n } from '~/ports/i18n'
import AdvertisementSlugService from '~/services/common/slug/advertisement-slug.service'
import { StatusCodeEnum } from '~/types/common'
import { CanonicalUrlService } from '~/modules/common/canonical-url/service/canonical-url.service'
import { closedIndexLinkRobotsContent } from '~/configs/client/robots-meta'

export function getCarTabLinks(car: CarDto | FullCarDto, useSavedSearchParams: string): LinkDto[] {
  return [
    getCarIndexTabLink(car, useSavedSearchParams),
    getCarPhotoTabLink(car, useSavedSearchParams),
    getCarCharacteristicsTabLink(car, useSavedSearchParams),
    getCarVideoTabLink(car, useSavedSearchParams),
    getCarTestDriveTabLink(car, useSavedSearchParams),
    getCarContactTabLink(car, useSavedSearchParams),
    getSimilarCarTabLink(car, useSavedSearchParams),
  ]
}

export function getCarIndexTabLink(car: CarDto, useSavedSearchParams: string): LinkDto {
  return {
    text: i18n.i18n.t('car_page.children.all'),
    to: {
      name: 'client-car-slug',
      params: {
        slug: AdvertisementSlugService.create(car),
        useSavedSearchParams: String(useSavedSearchParams),
      },
    },
    robots: closedIndexLinkRobotsContent,
  }
}

export function getCarPhotoTabLink(car: CarDto, useSavedSearchParams: string): LinkDto {
  return {
    text: i18n.i18n.t('car_page.children.photo'),
    to: {
      name: 'client-car-slug-photo',
      params: {
        slug: AdvertisementSlugService.create(car),
        useSavedSearchParams: String(useSavedSearchParams),
      },
    },
    robots: closedIndexLinkRobotsContent,
  }
}

export function getCarCharacteristicsTabLink(car: CarDto, useSavedSearchParams: string): LinkDto {
  return {
    text: i18n.i18n.t('car_page.children.characteristics'),
    to: {
      name: 'client-car-slug-characteristics',
      params: {
        slug: AdvertisementSlugService.create(car),
        useSavedSearchParams: String(useSavedSearchParams),
      },
    },
    robots: closedIndexLinkRobotsContent,
  }
}

export function getCarVideoTabLink(car: CarDto, useSavedSearchParams: string): LinkDto {
  return {
    text: i18n.i18n.t('car_page.children.video'),
    to: {
      name: 'client-car-slug-video',
      params: {
        slug: AdvertisementSlugService.create(car),
        useSavedSearchParams: String(useSavedSearchParams),
      },
    },
    hide: isFullCar(car) && !(car.video && (car.video.youtubeId || car.video.file)),
  }
}

export function getCarTestDriveTabLink(car: CarDto, useSavedSearchParams: string): LinkDto {
  return {
    text: i18n.i18n.t('car_page.children.test_drive'),
    to: {
      name: 'client-car-slug-test-drive',
      params: {
        slug: AdvertisementSlugService.create(car),
        useSavedSearchParams: String(useSavedSearchParams),
      },
    },
    hide: isFullCar(car) && !car.marks.testDriveAbility,
    robots: closedIndexLinkRobotsContent,
  }
}

export function getCarContactTabLink(car: CarDto, useSavedSearchParams: string): LinkDto {
  return {
    text: i18n.i18n.t('car_page.children.contact'),
    to: {
      name: 'client-car-slug-contact',
      params: {
        slug: AdvertisementSlugService.create(car),
        useSavedSearchParams: String(useSavedSearchParams),
      },
    },
    hide: isFullCar(car) && (!car.dealerRole || car.status.code === StatusCodeEnum.ARCHIVED),
    robots: closedIndexLinkRobotsContent,
  }
}

export function getSimilarCarTabLink(car: CarDto, useSavedSearchParams: string): LinkDto {
  const canonicalUrlService = Container.get(CanonicalUrlService)
  const catalogType: CarTypeEnum = car.isNew ? CarTypeEnum.NEW : CarTypeEnum.USED
  const region = car.location ? car.location.city.region : undefined
  const city = car.location ? car.location.city : undefined
  const bodyType = car.bodyType
  const category = bodyType.category as CarCategoryDto
  const model = car.model
  const brand = model.brand
  const isCertificated = car.isCertificated

  return {
    text: i18n.i18n.t('car_page.children.similar'),
    to: {
      name: 'client-type-query',
      params: {
        type: catalogType,
        ...canonicalUrlService.buildParams({
          region,
          city,
          category,
          bodyType,
          brand,
          model,
          isCertificated,
        }),
        useSavedSearchParams: String(useSavedSearchParams),
      },
    },
    hide: isFullCar(car) && car.status.code !== StatusCodeEnum.ARCHIVED,
  }
}
