import type {
  CarDto,
  CarSearchResultDto,
} from '~/types/client'
import {
  emptyCarSearchResult,
} from '~/types/client'
import type { StorageContract } from '~/services/common/storage/storage.contract'
import DynamicStorageService from '~/services/common/storage/dynamic-storage.service'
import { maxSavedPagesQuantity, searchResultReloadTrigger } from '~/configs/client/limits'
import { mapFromDataCollections } from '~/mappers/common/map-from-data-collections.mapper'
import CarService from '~/api/client/car.service'
import { carListDataCollectionOptions } from '~/configs/common/data-collection-option/car-list'
import type { UnknownObject } from '~/types/common'

class CarSearchResultService {
  private readonly resultStorageKey = 'search_results'
  private readonly currentCarStorageKey = 'current_car'

  private _searchResult: CarSearchResultDto = emptyCarSearchResult()
  private _items: CarDto[] = []
  private _currentCarId!: string
  private _currentCar?: CarDto
  private _currentPage!: number | null

  constructor(private readonly storage: StorageContract) {
    this._currentCarId = (this.storage.get(this.currentCarStorageKey) || '') as string
    this._currentPage = this.getPageByCarId(this._currentCarId)
  }

  get searchResult(): CarSearchResultDto | null {
    if (!this._items.length) {
      this.loadSearchResultFromStorage()
    }

    return this._searchResult
  }

  set searchResult(value: CarSearchResultDto | null) {
    if (!value) {
      return
    }

    this._searchResult = value
    this._items.splice(0, this._items.length, ...(Object.values(value.items).flat()))
    this.storage.set(this.resultStorageKey, value)
  }

  get currentCarId(): string {
    return this._currentCarId
  }

  set currentCarId(value: string) {
    this._currentCarId = value
    this._currentPage = this.getPageByCarId(this._currentCarId)
    this._currentCar = this._items.find(item => item.id === this._currentCarId)
    this.storage.set(this.currentCarStorageKey, value)
  }

  get items(): CarDto[] {
    if (!this._items.length) {
      this.loadSearchResultFromStorage()
    }

    return this._items.filter(car => car.id !== this.currentCarId)
  }

  get currentCar(): CarDto | null {
    if (!this.items.length) {
      return null
    }

    if (!this._currentCar) {
      this._currentCar = this.items.find(item => item.id === this._currentCarId)
    }

    return this._currentCar || null
  }

  async increment(): Promise<void> {
    const searchResult = this.searchResult

    if (!searchResult) {
      return
    }

    const totalPages = searchResult.query.perPage ? Math.ceil(searchResult.total / searchResult.query.perPage) : 0
    const savedPages = Object.keys(searchResult.items).sort()
    const currentPage = this._currentPage || parseInt(savedPages[savedPages.length - 1])
    const nextPage = savedPages.length ? currentPage + 1 : 1

    if (nextPage > totalPages || savedPages.includes(nextPage.toString())) {
      return
    }

    searchResult.query.page = nextPage

    const response = await CarService.list(searchResult.query)

    if (!response || !response.items.length) {
      return
    }

    if (savedPages.length >= maxSavedPagesQuantity) {
      delete searchResult.items[parseInt(savedPages[0])]
    }

    searchResult.items[nextPage] = response.items
    this.searchResult = searchResult
  }

  async decrement(): Promise<void> {
    const searchResult = this.searchResult

    if (!searchResult) {
      return
    }

    const savedPages = Object.keys(searchResult.items).sort()
    const currentPage = this._currentPage || parseInt(savedPages[0])
    const prevPage = savedPages.length ? currentPage - 1 : 1

    if (prevPage < 1 || savedPages.includes(prevPage.toString())) {
      return
    }

    searchResult.query.page = prevPage

    const response = await CarService.list(searchResult.query)

    if (!response || !response.items.length) {
      return
    }

    if (savedPages.length >= maxSavedPagesQuantity) {
      delete searchResult.items[parseInt(savedPages[savedPages.length - 1])]
    }

    searchResult.items[prevPage] = response.items

    this.searchResult = searchResult
  }

  getNextCar(): CarDto | null {
    if (!this._currentCarId) {
      return null
    }

    const currentIndex = this._items.findIndex(car => car.id === this._currentCarId)
    const lastIndex = this._items.length - 1

    if (currentIndex >= lastIndex - searchResultReloadTrigger) {
      this.increment()
    }

    if (currentIndex < 0 || currentIndex >= this._items.length - 1) {
      return null
    }

    return this._items[currentIndex + 1]
  }

  getPrevCar(): CarDto | null {
    if (!this._currentCarId) {
      return null
    }

    const currentIndex = this._items.findIndex(car => car.id === this._currentCarId)

    if (currentIndex <= searchResultReloadTrigger) {
      this.decrement()
    }

    if (currentIndex <= 0) {
      return null
    }

    return this._items[currentIndex - 1]
  }

  reset() {
    this._items = []
    this._currentPage = null
    this._searchResult = emptyCarSearchResult()

    this.storage.remove(this.resultStorageKey)
  }

  private loadSearchResultFromStorage(): void {
    const searchResult = <CarSearchResultDto | null> this.storage.get(this.resultStorageKey)

    if (!searchResult) {
      return
    }

    for (const page in searchResult.items) {
      for (const item of searchResult.items[page]) {
        mapFromDataCollections(item as unknown as UnknownObject, carListDataCollectionOptions)
      }
    }

    this._searchResult = searchResult
    this._items = Object.values(searchResult.items).flat()
  }

  private getPageByCarId(carId: string): number | null {
    if (!carId) {
      return null
    }

    for (const [page, cars] of Object.entries(this._searchResult.items)) {
      const currentCarIndex = cars.findIndex(car => car.id === carId)

      if (currentCarIndex < 0) {
        continue
      }

      return parseInt(page)
    }

    return null
  }
}

const carSearchResultInstance = new CarSearchResultService(DynamicStorageService)

export default carSearchResultInstance
