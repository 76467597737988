import storageAvailable from 'storage-available'
import type { StorageContract } from '~/services/common/storage/storage.contract'
import LocalStorageService from '~/services/common/storage/local-storage.service'
import DynamicStorageService from '~/services/common/storage/dynamic-storage.service'

/** TODO: Deprecated - look into nuxt/modules/common/storage */

export default function localStorageServiceProvider(): StorageContract {
  if (storageAvailable('localStorage')) {
    return LocalStorageService
  }

  return DynamicStorageService
}
