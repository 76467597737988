export interface NumberRangeDto {
  min: number | null
  max: number | null
}

export function emptyFilterNumberRangeDto(): NumberRangeDto {
  return { min: null, max: null }
}

export function isFilterNumberRangeDtoEmpty(range: NumberRangeDto): boolean {
  return range.min === null && range.max === null
}
