import type { CharacteristicValueListDto, ChoiceItemDto } from '~/types/common'
import { CharacteristicTypesEnum } from '~/types/common'
import type { ParseCharacteristicValueStrategyInterface } from '~/plugins/formatter/characteristic-value/parse-characteristic-value-strategy/parse-characteristic-value-strategy.interface'

class ParseMultiChoiceCharacteristicValueStrategy implements ParseCharacteristicValueStrategyInterface {
  support(type: CharacteristicTypesEnum): boolean {
    return type === CharacteristicTypesEnum.multiChoice
  }

  parse(valueList: CharacteristicValueListDto): string {
    return (valueList.value as ChoiceItemDto[]).map(value => value.name).join(', ')
  }
}

export default new ParseMultiChoiceCharacteristicValueStrategy()
