import dayjs from 'dayjs'
import type { DealerActionDto, DealerDto } from '~/types/common'

export interface FormDealerActionDto {
  startAt: string | null,
  endAt: string | null,
  title: string | null,
  terms: string | null,
  description: string | null,
  advertisements: string[],
  dealer: DealerDto,
}

export function emptyFormDealerAction(dealer: DealerDto): FormDealerActionDto {
  return {
    dealer,
    advertisements: [],
    startAt: null,
    endAt: null,
    description: null,
    terms: null,
    title: null,
  }
}

export function mapDealerActionToForm(dealerAction: DealerActionDto): FormDealerActionDto {
  return {
    dealer: dealerAction.dealer,
    advertisements: dealerAction.advertisements.map(advertisement => advertisement.id),
    startAt: dayjs.unix(dealerAction.startAt).format('YYYY/MM/DD'),
    endAt: dayjs.unix(dealerAction.endAt).format('YYYY/MM/DD'),
    title: dealerAction.title,
    terms: dealerAction.terms,
    description: dealerAction.description,
  }
}
