import type {
  BaseContentBlockDto,
  FormContentBlockDto,
  FormFileDto,
  FormVideoDto,
  UpdateBaseContentBlockDto,
} from '~/types/common'
import { emptyFormFile, emptyFormVideo, mapFileToForm, mapVideoToFormVideo, NewsStatusCodeEnum } from '~/types/common'
import type { FilterDto, FormMarketirsementDto, FullNewsDto } from '~/types/admin'
import { emptyFormMarketirsementDto, mapMarketirsementToFormDto } from '~/types/admin'

export interface FormNewsDto {
  title?: string
  subtitle?: string
  newsAuthorId?: string
  content: BaseContentBlockDto[]
  newContent: FormContentBlockDto[]
  updatedContent: FormContentBlockDto<UpdateBaseContentBlockDto>[]
  tags: string[]
  statusCode: NewsStatusCodeEnum
  filter?: FilterDto
  isHot?: boolean
  image?: FormFileDto
  video?: FormVideoDto
  marketirsement?: FormMarketirsementDto | null
}

export function emptyFormNewsDto(): FormNewsDto {
  return {
    title: '',
    subtitle: '',
    newsAuthorId: '',
    content: [],
    newContent: [],
    updatedContent: [],
    tags: [],
    statusCode: NewsStatusCodeEnum.DRAFT,
    filter: {},
    isHot: false,
    image: emptyFormFile(),
    video: emptyFormVideo(),
    marketirsement: emptyFormMarketirsementDto(),
  }
}

export function mapNewsToFormDto(news: FullNewsDto): FormNewsDto {
  return {
    title: news.title,
    subtitle: news.subtitle,
    newsAuthorId: news.newsAuthor?.id,
    content: news.content,
    newContent: [],
    updatedContent: [],
    tags: news.tags.map(tag => tag.title),
    statusCode: news.status,
    filter: news.filter,
    isHot: news.isHot,
    image: mapFileToForm(news.image),
    video: mapVideoToFormVideo(news.video || null),
    marketirsement: news.marketirsement ? mapMarketirsementToFormDto(news.marketirsement) : emptyFormMarketirsementDto(),
  }
}
