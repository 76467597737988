import type { CurrencyDto } from '~/types/common'
import CurrencyConverterService from '~/services/common/currency-converter.service'
import { formatPrice } from '~/plugins/formatter/price.formatter'
import { i18n } from '~/ports/i18n'

export function currencyFormatter(
  price: number,
  sourceCurrency: CurrencyDto,
  targetCurrency: CurrencyDto,
  round: boolean = true,
  isAddPrefix: boolean = false,
) {
  const convertedPrice = CurrencyConverterService.convert(price, sourceCurrency, targetCurrency) / 100
  const value = formatPrice(round ? Math.round(convertedPrice) : convertedPrice)

  if (targetCurrency.prefixed || isAddPrefix) {
    return `${targetCurrency.symbol} ${value}`
  }

  return `${value} ${i18n.i18n.t(targetCurrency.code)}`
}
