import type { CharacteristicValueListDto, ChoiceItemDto } from '~/types/common'
import { CharacteristicTypesEnum } from '~/types/common'
import type { ParseCharacteristicValueStrategyInterface } from '~/plugins/formatter/characteristic-value/parse-characteristic-value-strategy/parse-characteristic-value-strategy.interface'

class ParseSingleChoiceCharacteristicValueStrategy implements ParseCharacteristicValueStrategyInterface {
  support(type: CharacteristicTypesEnum): boolean {
    return type === CharacteristicTypesEnum.singleChoice
  }

  parse(valueList: CharacteristicValueListDto): string {
    return (<unknown>valueList.value as ChoiceItemDto).name
  }
}

export default new ParseSingleChoiceCharacteristicValueStrategy()
