import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type { FilterSelectedOptionsCarDto, SearchDataDto } from '~/types/client'
import { FilterKeysEnum } from '~/types/common'

export class IsSoldDecodeStrategy extends StringDecodeStrategyAbstract<FilterSelectedOptionsCarDto> {
  readonly pattern = `^${FilterKeysEnum.IS_SOLD}=${this.conditionPattern}$`

  process(subject: SearchDataDto<FilterSelectedOptionsCarDto>, _searchItemString: string): void {
    const data = _searchItemString.split('=')

    subject.selectedOptions.isSold = data.pop() === 'true'
  }
}
