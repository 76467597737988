import type {
  FormNumberCharacteristicDto,
  RequestBaseCharacteristicDto,
} from '~/types/admin'
import {
  mapFormBaseCharacteristicToRequest,
} from '~/types/admin'

export interface RequestNumberCharacteristicDto extends RequestBaseCharacteristicDto {
  min: number | null
  max: number | null
  unit: string | null
  pluralizable: boolean
}

export function mapFormNumberCharacteristicToRequest(formData: FormNumberCharacteristicDto): RequestNumberCharacteristicDto {
  return {
    ...mapFormBaseCharacteristicToRequest(formData),
    min: formData.min,
    max: formData.max,
    unit: formData.unit,
    pluralizable: formData.pluralizable,
  }
}
