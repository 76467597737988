import type { SelectOptionDto } from '~/types/common'

export interface SelectedOptionsFeedbackDto {
  subject: string
  phone: string
  name: string
  statuses: SelectOptionDto[]
}

export function emptySelectedOptionsFeedback(): SelectedOptionsFeedbackDto {
  return {
    statuses: [],
    subject: '',
    phone: '',
    name: '',
  }
}
