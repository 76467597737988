
























































































































































































































  import { Component, Mixins, Ref } from 'vue-property-decorator'
  import { VBtn } from '~/components/client/ui'
  import { Emit, Prop } from '~/node_modules/vue-property-decorator'
  import ProfileMenuMixin from '~/components/client/profile-menu/profile-menu.mixin'
  import VImg from '~/components/common/v-img/v-img.vue'
  import DeviceDetectService from '~/services/common/device-detect.service'
  import AddAdvertisementButton from '~/components/client/header/add-advertisement-button.vue'
  import ComparisonCountButton from '~/components/client/header/comparison-count-button.vue'
  import FavoritesCountButton from '~/components/client/header/favorites-count-button.vue'
  import type {
    ProfileDealerRoleWithTestDriveCountsDto,
  } from '~/types/common/auth/profile-dealer-role-with-test-drive-counts.dto'
  import { breakpoints } from '~/configs/common/breakpoints'
  import { LoadingWrapper } from '~/decorators'

@Component({
  components: {
    VBtn,
    VImg,
    AddAdvertisementButton,
    ComparisonCountButton,
    FavoritesCountButton,
  },
})
  export default class ProfileMenu extends Mixins(ProfileMenuMixin) {
  @Prop({ default: false })
  readonly showMenu!: boolean

  readonly isMobile: boolean = DeviceDetectService.isMobile()

  @Ref('menu-inner')
  readonly menuInner!: HTMLElement

  @Ref('menu-guest')
  readonly menuGuest!: HTMLElement

  @Ref('menu-bottom')
  readonly menuBottom!: HTMLElement

  @Ref('become-dealer')
  readonly becomeDealer!: HTMLElement

  @Ref('menu-links-block')
  readonly menuLinksBlock!: HTMLElement

  @Ref('profile-roles-menu')
  readonly profileRolesMenu!: HTMLElement

  navigationHeight: number = 0

  isMobileView: boolean = false
  loading: boolean = false

  @Emit('closeDropdownMenu')
  @Emit('closeSideBar')
  handleClick() {
  }

  handleClickWithPopup() {
    this.handleClick()
    if (!this.isLoggedIn) {
      this.toggleRegistrationPopup()
    }
  }

  @Emit('closeProfileMenu')
  closeProfileMenu() {
  }

  @Emit('toggleRegistrationPopup')
  toggleRegistrationPopup() {
  }

  mounted() {
    this.changeMenuHeight()
    this.checkMobileView()

    window.addEventListener('resize', this.checkMobileView)
    document.body.style.overflow = 'hidden'
  }

  get profileInitials() {
    if (!this.userData) {
      return ''
    }

    return this.userData.firstName[0] + (this.userData.lastName && this.userData.lastName[0])
  }

  @LoadingWrapper()
  async beforeMount() {
    await this.fillDealerRoleTestDriveCounts()
    const currentDealerRoleId = localStorage.getItem('currentDealerRoleId')
    if (!currentDealerRoleId) {
      return
    }
    const dealer = this.dealerRoleById(currentDealerRoleId)
    this.chooseRoleMenu(dealer)
  }

  destroyed() {
    window.removeEventListener('resize', () => {
      this.checkMobileView()
    })
    document.body.style.overflow = 'auto'
  }

  chooseRoleMenu(role?: ProfileDealerRoleWithTestDriveCountsDto | null) {
    if (!role) {
      this.onClientTab()
      this.changeMenuHeight()

      return
    }

    this.onDealerTab(role)
    this.changeMenuHeight()
  }

  checkMobileView() {
    this.isMobileView = window.innerWidth < breakpoints.XSP
  }

  changeMenuHeight() {
    this.$nextTick(() => {
      if (!this.menuInner || !this.menuBottom) {
        return
      }

      this.navigationHeight = this.menuInner.clientHeight
      this.navigationHeight += this.menuBottom.clientHeight

      if (this.menuGuest) {
        this.navigationHeight += this.menuGuest.clientHeight
      }

      if (this.becomeDealer) {
        this.navigationHeight += this.becomeDealer.clientHeight
      }

      if (this.profileRolesMenu) {
        this.navigationHeight += this.profileRolesMenu.clientHeight
      }
    })
  }
  }
