import { emptyCarSearchDataDto, getEmptyCharacteristicValueList } from '~/types/client'
import type { CarCategoryDto, FilterSelectedOptionsCarDto, SearchDataDto } from '~/types/client'
import DataCollectionService from '~/api/common/data-collection.service'
import type { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import { PageDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/page-decode.strategy'
import { OrderDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/order-decode.strategy'
import { CategoryDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/category-decode.strategy'
import { BodyTypeDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/body-type-decode.strategy'
import { BrandModelDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/brand-model-decode.strategy'
import { CharacteristicsDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/characteristics-decode.strategy'
import { TestDriveAbilityStrategy } from '~/services/client/search-string/string-decode-strategy/test-drive-ability.strategy'
import { RegionDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/region-decode.strategy'
import { CityDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/city-decode.strategy'
import { LocationDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/location-decode.strategy'
import { FuelTypeDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/fuel-type-decode.strategy'
import { IsNewDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/is-new-decode.strategy'
import { IsAvailableInDealerDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/is-available-in-dealer-decode.strategy'
import { IsSoldDecodeStrategy } from '~/services/client/search-string/string-decode-strategy/is-sold-decode.strategy'
import {
  IsCertificatedDecodeStrategy,
} from '~/services/client/search-string/string-decode-strategy/is-certificated-decode.strategy'

export function urlToSearchDataMapper(url: string): SearchDataDto<FilterSelectedOptionsCarDto> {
  const strategies: StringDecodeStrategyAbstract<FilterSelectedOptionsCarDto>[] = [
    new PageDecodeStrategy(),
    new OrderDecodeStrategy(),
    new CategoryDecodeStrategy(),
    new BodyTypeDecodeStrategy(),
    new FuelTypeDecodeStrategy(),
    new BrandModelDecodeStrategy(),
    new CharacteristicsDecodeStrategy(),
    new TestDriveAbilityStrategy(),
    new RegionDecodeStrategy(),
    new CityDecodeStrategy(),
    new LocationDecodeStrategy(),
    new IsNewDecodeStrategy(),
    new IsCertificatedDecodeStrategy(),
    new IsSoldDecodeStrategy(),
    new IsAvailableInDealerDecodeStrategy(),
  ]

  const searchUrlArray = url.split(';')
  const searchData: SearchDataDto<FilterSelectedOptionsCarDto> = emptyCarSearchDataDto()

  searchData.selectedOptions.category = <unknown>DataCollectionService.collections.category!.items[0] as CarCategoryDto

  for (const searchItem of searchUrlArray) {
    const strategy = strategies.find(item => item.support(searchItem))
    if (!strategy) {
      continue
    }

    strategy.process(searchData, searchItem)
  }

  const selectedCharacteristics = searchData.selectedOptions.filterCharacteristics.map(item => item.characteristic.id)
  searchData.selectedOptions.filterCharacteristics = searchData.selectedOptions.filterCharacteristics.concat(
    getEmptyCharacteristicValueList(searchData.selectedOptions.category).filter(item => !selectedCharacteristics.includes(item.characteristic.id)),
  )

  return searchData
}
