import type { FormTagDto } from '~/types/common'

export interface RequestTagDto {
  title?: string
  category?: string|null
}

export function tagFormToRequestDtoMapper(tag: FormTagDto): RequestTagDto {
  return {
    title: tag.title,
    category: tag.category ? tag.category.id : tag.category,
  }
}

export function emptyRequestTagDto(): RequestTagDto {
  return {
    title: '',
    category: null,
  }
}
