export enum AdvertisementFormSectionEnum {
  BRAND_MODEL = 'brand-model',
  QUALITY = 'quality',
  PHOTO = 'photo',
  VIDEO = 'video',
  MAIN_INFO = 'main-info',
  FEATURES = 'features',
  CHARACTERISTICS = 'characteristics',
  DESCRIPTION = 'description',
  AUTH = 'auth',
  PROMOTION = 'promotion',
  CAR_NUMBER_VISIBLE = 'car-number-visible',
}
