













import { Vue, Component } from 'vue-property-decorator'
import { ClientAuthStoreModule } from '~/store/client/auth'
import { iconSizes } from '~/configs/client'
import AnonComparisonService from '~/services/client/local-list/anon-comparison.service'

@Component
export default class ComparisonCountButton extends Vue {
  readonly iconSize = iconSizes.sm_sm
  readonly anonComparisonIdList = AnonComparisonService.ids

  @ClientAuthStoreModule.Getter('isLoggedIn')
  readonly isLoggedIn!: boolean

  @ClientAuthStoreModule.Getter('comparisonIdList')
  readonly comparisonIdList!: string[]

  get amount(): number {
    return this.isLoggedIn ? this.comparisonIdList.length : this.anonComparisonIdList.length
  }

  get routeName(): string {
    return this.isLoggedIn ? 'client-profile-comparison' : 'client-anon-comparison'
  }
}
