import type { BrandDto, ModelDto } from '~/types/client'
import { emptyFilterNumberRangeDto, isFilterNumberRangeDtoEmpty } from '~/types/common/number-range.dto'
import type { NumberRangeDto } from '~/types/common/number-range.dto'
import type { CurrencyDto } from '~/types/common'

export interface FilterBrandModelGroupDto {
  brand: BrandDto|null
  model: ModelDto|null
  year: NumberRangeDto
  price: NumberRangeDto
  mileage: NumberRangeDto
  currency: CurrencyDto | null
}

export function emptyFilterBrandModelGroupDto(): FilterBrandModelGroupDto {
  return {
    brand: null,
    model: null,
    year: emptyFilterNumberRangeDto(),
    price: emptyFilterNumberRangeDto(),
    mileage: emptyFilterNumberRangeDto(),
    currency: null,
  }
}

export function isFilterBrandModelGroupDtoEmpty(brandModelGroup: FilterBrandModelGroupDto): boolean {
  return brandModelGroup.brand === null
    && brandModelGroup.model === null
    && isFilterNumberRangeDtoEmpty(brandModelGroup.price)
    && isFilterNumberRangeDtoEmpty(brandModelGroup.year)
    && isFilterNumberRangeDtoEmpty(brandModelGroup.mileage)
}
