import type {
  CharacteristicTypesEnum,
  FormFileDto,
  SectionDto,
} from '~/types/common'
import { emptyFormFile, mapFileToForm } from '~/types/common'
import type { BaseCharacteristicDto } from '~/types/admin'

export interface FormBaseCharacteristicDto {
  name: string | null
  label: string | null
  section: SectionDto
  active: boolean
  filter: boolean
  required: boolean
  showInShortView: boolean
  option: boolean
  useForICE: boolean
  useForElectric: boolean
  deletable: boolean
  logo: FormFileDto
  dtype: CharacteristicTypesEnum
}

export function emptyFormBaseCharacteristic(dtype: CharacteristicTypesEnum, section: SectionDto): FormBaseCharacteristicDto {
  return {
    dtype,
    section,
    name: null,
    label: null,
    active: true,
    filter: false,
    option: false,
    required: false,
    showInShortView: false,
    useForICE: true,
    useForElectric: true,
    logo: emptyFormFile(),
    deletable: true,
  }
}

export function mapBaseCharacteristicToForm(characteristic: BaseCharacteristicDto): FormBaseCharacteristicDto {
  return {
    section: characteristic.section,
    logo: mapFileToForm(characteristic.logo),
    name: characteristic.name,
    label: characteristic.label,
    dtype: characteristic.dtype,
    active: characteristic.active,
    filter: characteristic.filter,
    option: characteristic.option,
    required: characteristic.required,
    showInShortView: characteristic.showInShortView,
    useForICE: characteristic.useForICE,
    useForElectric: characteristic.useForElectric,
    deletable: characteristic.deletable,
  }
}
