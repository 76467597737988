import type { FormComplaintDto } from '~/types/client'

export interface RequestComplaintDto {
  advertisement: string
  category: string
  description: string
}

export function mapFormComplaintToRequest(formData: FormComplaintDto): RequestComplaintDto {
  return {
    advertisement: formData.advertisement!,
    category: formData.category!.id,
    description: formData.description!,
  }
}
