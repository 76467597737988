import dayjs from 'dayjs'
import type { DealerRoleDto, DealerRolesEnum } from '~/types/common'

export interface FormEditDealerRoleDto {
  id: string,
  role: DealerRolesEnum
  deputy: DealerRoleDto | null
  deputyFrom: string | null
  deputyTo: string | null
}

export function mapDealerRoleToFormEdit(dealerRole: DealerRoleDto) {
  return {
    id: dealerRole.id,
    role: dealerRole.role,
    deputy: dealerRole.deputy || null,
    deputyTo: dealerRole.deputyTo ? dayjs.unix(dealerRole.deputyTo).format('YYYY/MM/DD') : null,
    deputyFrom: dealerRole.deputyFrom ? dayjs.unix(dealerRole.deputyFrom).format('YYYY/MM/DD') : null,
  }
}
