









import type { VueConstructor } from 'vue'
import { Component, Vue } from 'vue-property-decorator'
import lazyLoadComponent from '~/plugins/lazy-loading'
import HeaderLanding from '~/components/client/header/header-landing.vue'

@Component({
  components: {
    HeaderLanding,
    AdsBanner: lazyLoadComponent(() => import('~/components/client/ads-banner.vue')) as VueConstructor,
    FooterMain: lazyLoadComponent(() => import('~/components/client/footer/footer-main.vue')) as VueConstructor,
  },
  head(this: LandingLayout) {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
})
export default class LandingLayout extends Vue {
  $i18n: any
}
