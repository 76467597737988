import type { Middleware } from '@nuxt/types'
import AdvertisementSlugService from '~/services/common/slug/advertisement-slug.service'
import CarService from '~/api/client/car.service'
import { getSimilarCarTabLink } from '~/configs/client'
import { RouteNavigatorService } from '~/plugins/route-navigator/service/route-navigator.service'

const CarIsArchivedOldMiddleware: Middleware = async ({ route, app }) => {
  const carId = AdvertisementSlugService.parseId(route.params.slug)

  if (!carId) {
    return
  }

  const statusLog = await CarService.statusLog(carId)

  if (statusLog?.isArchivedOld) {
    const car = await CarService.show(carId)

    if (car) {
      const similarLink = getSimilarCarTabLink(car, '')

      if (similarLink.to) {
        const rns = new RouteNavigatorService(app.router!)
        const redirectLink = rns.resolve(similarLink.to).href

        const metaStatusCodeNode = document.createElement('meta')
        const metaHeaderNode = document.createElement('meta')

        metaStatusCodeNode.setAttribute('name', 'prerender-status-code')
        metaStatusCodeNode.setAttribute('content', '301')

        metaHeaderNode.setAttribute('name', 'prerender-header')
        metaHeaderNode.setAttribute('content', `Location: ${redirectLink}`)

        document.head.appendChild(metaStatusCodeNode)
        document.head.appendChild(metaHeaderNode)

        rns.redirect({ path: redirectLink })
      }
    }
  }
}

export default CarIsArchivedOldMiddleware
