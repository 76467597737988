import type { FormFileDto } from '~/types/common'
import { emptyFormFile, mapFileToForm } from '~/types/common'
import type { MarketirsementDto } from '~/types/client'

export interface FormMarketirsementDto {
  image: FormFileDto
  link: string
}

export function mapMarketirsementToFormDto(marketirsement: MarketirsementDto): FormMarketirsementDto {
  return {
    image: mapFileToForm(marketirsement.image),
    link: marketirsement.link,
  }
}

export function emptyFormMarketirsementDto(): FormMarketirsementDto {
  return {
    image: emptyFormFile(),
    link: '',
  }
}
