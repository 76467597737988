import type { RegionDto, CityDto, NumberRangeDto, SortOptionDto, FuelTypeDto } from '~/types/common'
import type { BodyTypeDto, BrandDto, CarCategoryDto, ModelDto } from '~/types/client'
import type { GearboxTypeDto } from '~/types/common/gearbox-type'

export type CatalogCanonicalUrlParamsDto = {
  region?: RegionDto,
  city?: CityDto, //  add | null
  category?: CarCategoryDto,
  bodyType?: BodyTypeDto,
  brand?: BrandDto,
  model?: ModelDto,
  year?: NumberRangeDto,
  mileage?: NumberRangeDto,
  price?: NumberRangeDto,
  page?: number,
  order?: SortOptionDto,
  isCertificated?: boolean | null,
  gearBoxTypes?: GearboxTypeDto[],
  fuelType?: FuelTypeDto,
}

export interface NumberRangeKeys {
  year?: NumberRangeDto,
  mileage?: NumberRangeDto,
  price?: NumberRangeDto
}

export function emptyCatalogCanonicalUrlParamsDto(): CatalogCanonicalUrlParamsDto {
  return {
    region: undefined,
    city: undefined,
    category: undefined,
    bodyType: undefined,
    brand: undefined,
    model: undefined,
    year: undefined,
    mileage: undefined,
    price: undefined,
    page: undefined,
    order: undefined,
    isCertificated: undefined,
    gearBoxTypes: undefined,
  }
}
