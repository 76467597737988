import type {
  FormCheckboxCharacteristicDto,
  RequestBaseCharacteristicDto,
} from '~/types/admin'
import {
  mapFormBaseCharacteristicToRequest,
} from '~/types/admin'

export interface RequestCheckboxCharacteristicDto extends RequestBaseCharacteristicDto {
  trueLabel: string | null
  falseLabel: string | null
}

export function mapFormCheckboxCharacteristicToRequest(formData: FormCheckboxCharacteristicDto): RequestCheckboxCharacteristicDto {
  return {
    ...mapFormBaseCharacteristicToRequest(formData),
    trueLabel: formData.trueLabel,
    falseLabel: formData.falseLabel,
  }
}
