import type { Middleware } from '@nuxt/types'
import AdvertisementSlugService from '~/services/common/slug/advertisement-slug.service'
import CarViewHistoryService from '~/services/client/car-view-history.service'

const CarViewHistoryMiddleware: Middleware = ({ route }) => {
  CarViewHistoryService.init()
  const carId = AdvertisementSlugService.parseId(route.params.slug)

  if (!carId) {
    return
  }
  CarViewHistoryService.add(carId)
}

export default CarViewHistoryMiddleware
