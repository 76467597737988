import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import DeviceDetectService from '~/services/common/device-detect.service'

@Component
export default class ObserveVisibilityAbstract extends Vue {
  isVisible: boolean = false

  @Prop({ default: false, type: Boolean })
  readonly static!: boolean

  created() {
    if (!this.static && !DeviceDetectService.isPrerenderer()) {
      return
    }

    this.isVisible = true
  }

  mounted() {
    this.checkElementVisible()
  }

  updated() {
    this.checkElementVisible()
  }

  checkInView(): boolean {
    if (!this.$el.getBoundingClientRect) {
      return false
    }

    const rect = this.$el.getBoundingClientRect()

    const above = rect.bottom <= 0
    const below = rect.top - window.innerHeight >= 0
    const left = rect.right <= 0
    const right = rect.left - window.innerWidth >= 0

    return !above && !below && !left && !right
  }

  @Watch('static')
  checkElementVisible() {
    if (this.isVisible) {
      return
    }

    if (this.static || DeviceDetectService.isPrerenderer()) {
      this.isVisible = true

      return
    }

    this.isVisible = this.checkInView()
  }

  visibilityChanged(isVisible: boolean) {
    if (this.isVisible || this.static || DeviceDetectService.isPrerenderer()) {
      return
    }
    this.isVisible = isVisible
  }
}
