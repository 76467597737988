import type {
  ProfileDealerRoleDto,
  MessengerDataDto,
  UserRoleEnum,
  UserDataDto,
} from '~/types/common'
import type { OwnCarDto } from '~/types/client'
import type { BaseFavoriteListDto } from '~/types/client/favorite-list/base-favorite-list.dto'
import type { UserConsentDto } from '~/types/common/user-consent'

export type ProfileDto = UserDataDto & {
  isRemoved: boolean
  messengers: MessengerDataDto[]
  roles: UserRoleEnum[]
  username: string
  userOwnCars: OwnCarDto[]
  dealerRoles: ProfileDealerRoleDto[]
  isFacebookConnected: boolean
  isGoogleConnected: boolean
  favoriteLists: BaseFavoriteListDto[]
  grantedRoles: UserRoleEnum[]
  comparisonIdList: string[]
  id: string
  consents: UserConsentDto[]
}

export function emptyProfileDto(): ProfileDto {
  return {
    isRemoved: false,
    messengers: [],
    roles: [],
    username: '',
    userOwnCars: [],
    isFacebookConnected: false,
    isGoogleConnected: false,
    favoriteLists: [],
    comparisonIdList: [],
    grantedRoles: [],
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: null,
    id: '',
    dealerRoles: [],
    consents: [],
  }
}
