import { Container } from 'typescript-ioc'
import CurrencyConverterService from '~/services/common/currency-converter.service'
import { CurrencyService } from '~/modules/common/currency/service/currency.service'

class CurrencyPriceHelperConverterService {
  readonly currencyService = Container.get(CurrencyService)

  convert(price: number | null) {
    if (price === null) {
      return null
    }

    if (!this.currencyService.usdCurrency) {
      return null
    }

    return CurrencyConverterService.convert(price * 100, this.currencyService.usdCurrency, this.currencyService.defaultCurrency)
  }

  unconvert(price: number | null) {
    if (price === null) {
      return null
    }

    if (!this.currencyService.usdCurrency) {
      return null
    }

    return CurrencyConverterService.convert(price / 100, this.currencyService.defaultCurrency, this.currencyService.usdCurrency)
  }
}

export default new CurrencyPriceHelperConverterService()
