import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type { CarCategoryDto, FilterSelectedOptionsCarDto, SearchDataDto } from '~/types/client'
import DataCollectionService from '~/api/common/data-collection.service'
import { FilterKeysEnum } from '~/types/common'

export class CategoryDecodeStrategy extends StringDecodeStrategyAbstract<FilterSelectedOptionsCarDto> {
  readonly pattern = `^${FilterKeysEnum.CATEGORY}=[\\w-]+$`

  process(subject: SearchDataDto<FilterSelectedOptionsCarDto>, searchItemString: string): void {
    const categorySlug = searchItemString.split('=')[1]
    const category = DataCollectionService.collections.category!.maps.slug[categorySlug] as CarCategoryDto

    if (!category) {
      return
    }

    subject.selectedOptions.category = category
  }
}
