import type { FormTagCategoryDto } from '~/types/admin'

export interface RequestTagCategoryDto {
  title: string
}

export function mapFormTagCategoryDtoToRequest(category: FormTagCategoryDto): RequestTagCategoryDto {
  return {
    title: category.title,
  }
}
