export enum ButtonTypeEnum {
  BTN_RED = 'btn btn--red',
  BTN_BLUE = 'btn btn--blue',
  BTN_GRAY = 'btn btn--gray',
  BTN_WHITE = 'btn btn--white',
  BTN_RED_DASHED = 'btn btn--red-dashed',
  BTN_RED_DASHED_ICON = 'btn btn--red-dashed-icon',
  BTN_RED_DASHED_INVERSE_COLOR = 'btn btn--red-dashed-inverse-color',
  BTN_GRAY_DASHED = 'btn btn--gray-dashed',
  BTN_WHITE_DASHED = 'btn btn--white-dashed',
  BTN_BLACK_DASHED = 'btn btn--black-dashed',
  BTN_BLACK_DASHED_WHITE_BG = 'btn btn--black-dashed btn--white-background',
  BTN_GRAY_CLOSE = 'btn btn--close',
  BTN_GRAY_CLOSE_BLACK = 'btn btn--close-black',
  BTN_ICON = 'btn-icon',
  BTN_ICON_WHITE = 'btn-icon btn-icon--white',
  BTN_ICON_CIRCLE = 'btn-icon btn-icon--circle',
  BTN_ICON_SQUARE = 'btn-icon btn-icon--square',
  BTN_NO_DECORATION = 'btn-no-decoration',
  BTN_NO_DECORATION_UNDERLINE = 'btn-no-decoration btn-no-decoration--underline',
  BTN_NO_DECORATION_ICON = 'btn-no-decoration btn-no-decoration--icon',
  BTN_NO_DECORATION_RED_TEXT = 'btn-no-decoration btn-no-decoration--red-text',
  BTN_PULSE = 'btn-pulse',
  BTN_OUTLINE = 'btn-outline',
}
