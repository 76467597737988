










import { Vue, Component } from 'vue-property-decorator'
import PhoneNumberLink from '~/components/common/phone-number-link.vue'
import { DataCollectionGetter } from '~/decorators/data-collection-getter.decorator'
import { DataCollectionTypeEnum } from '~/types/common'
import type { ContactDto } from '~/types/client'
import { ContactContentTypeEnum } from '~/types/common/contact'
@Component({
  components: { PhoneNumberLink },
})
export default class SupportInfo extends Vue {
  @DataCollectionGetter(DataCollectionTypeEnum.contacts)
  readonly contacts!: ContactDto[]

  get phone(): string | null {
    const supportContact = this.contacts.find(item => item.type === ContactContentTypeEnum.support)

    return supportContact && supportContact.content.phones.length
            ? supportContact.content.phones[0]
            : null
  }
}
