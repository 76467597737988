import type { PluginFunction } from 'vue'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
// @ts-ignore
import vuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'
import SvgIcon from '~/components/common/svgicon.vue'

Vue.use(<unknown>Vuelidate as PluginFunction<any>)
Vue.use(vuelidateErrorExtractor, {
  template: templates.singleErrorExtractor.foundation6,
  i18n: 'validation',
})

Vue.component('FormWrapper', templates.FormWrapper)

Vue.component('Svgicon', SvgIcon)
