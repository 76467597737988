import type { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { namespace } from 'vuex-class'
import type {
  AuthState,
  LoginDataDTO,
  LoginResponseDTO,
  ProfileDto,
  RootState,
} from '~/types/common'
import {
  AuthTypesEnum,
  emptyAuthState, emptyProfileDto,
  UserRoleEnum,
} from '~/types/common'
import LocalProfileService from '~/services/common/local-profile.service'

export const state = (): AuthState => emptyAuthState()

export const actions: ActionTree<AuthState, RootState> = {
  async login(
    { commit }: ActionContext<AuthState, RootState>,
    { username, password }: LoginDataDTO,
  ) {
    const response: LoginResponseDTO = await this.$axios.$post('admin/login', { username, password })

    commit('setLoggedIn', true)

    return response
  },

  async logout({ commit }: ActionContext<AuthState, RootState>) {
    await this.$axios.$get('logout')
    commit('setLoggedIn', false)
  },
}

export const mutations: MutationTree<AuthState> = {
  setLoggedIn(state: AuthState, status: boolean) {
    state.isLoggedIn = status
  },

  setProfile(state: AuthState, profile: ProfileDto | null) {
    state.profile = profile || emptyProfileDto()
    LocalProfileService.setProfile(profile, AuthTypesEnum.ADMIN)
  },
}

export const getters: GetterTree<AuthState, RootState> = {
  isLoggedIn(state: AuthState): boolean {
    return state.isLoggedIn
  },

  username(state: AuthState): string {
    return state.profile.username
  },

  roles(state: AuthState): UserRoleEnum[] {
    return state.profile.roles
  },

  grantedRoles(state: AuthState): UserRoleEnum[] {
    const grantedRoles: UserRoleEnum[] = [...state.profile.roles]

    if (grantedRoles.includes(UserRoleEnum.MODERATOR)) {
      grantedRoles.push(UserRoleEnum.CONTENT_MANAGER)
    }

    if (grantedRoles.includes(UserRoleEnum.ADMIN)) {
      grantedRoles.push(UserRoleEnum.CONTENT_MANAGER, UserRoleEnum.MODERATOR)
    }

    return grantedRoles
  },
}

export const AdminAuthStoreModule = namespace('admin/auth')
