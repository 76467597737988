import type { Plugin } from '@nuxt/types'
import { currencyFormatter } from '~/modules/common/currency/formatter/currency.formatter'
import type { CurrencyFormatter } from '~/modules/common/currency/type/currency-formatter.type'

declare module 'vue/types/vue' {
  interface Vue {
    $currencyFormatter: CurrencyFormatter,
  }
}

const currencyFormatterPlugin: Plugin = function (_context, inject) {
  inject('currencyFormatter', currencyFormatter)
}

export default currencyFormatterPlugin
