import { Api, ApiRemove, ApiShow, ApiUpdate, DataCollection } from '~/decorators'
import type { ApiInterface } from '~/contracts/crud/api.interface'
import type { RemoveInterface, ShowInterface, UpdateInterface } from '~/contracts/crud'
import { carDataCollectionOptions } from '~/configs/common/data-collection-option/car'
import type { RequestCarDto, FullCarDto, ClientFullCarDto } from '~/types/client'
import { client } from '~/ports/client'
import type { StatusCodeEnum } from '~/types/common'
import { withCarSlugDataCollectionOptions } from '~/configs/common/data-collection-option/with-car-slug'

interface ClientCarService extends ApiInterface,
  ShowInterface<FullCarDto>,
  UpdateInterface<FullCarDto, RequestCarDto>,
  RemoveInterface {
}

@ApiUpdate<FullCarDto, RequestCarDto>(carDataCollectionOptions)
@ApiShow<FullCarDto>(carDataCollectionOptions)
@ApiRemove()
@Api('/secure/cars', {
  responseItemName: 'car',
  responseItemListName: 'cars',
})
class ClientCarService {
  @DataCollection(carDataCollectionOptions)
  async showOwn(id: string): Promise<ClientFullCarDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      const response = await client.$axios.$get(`${this.apiPrefix}/${id}/own`)

      return response[this.apiOptions.responseItemName]
    } catch (e) {
      return null
    }
  }

  @DataCollection(carDataCollectionOptions)
  async createDraft(data: RequestCarDto): Promise<FullCarDto | null> {
    return await this.create(data, 'draft')
  }

  @DataCollection(withCarSlugDataCollectionOptions)
  async createPublic(data: RequestCarDto): Promise<FullCarDto | null> {
    return await this.create(data, 'publish')
  }

  @DataCollection(carDataCollectionOptions)
  private async create(data: RequestCarDto, mode: string): Promise<FullCarDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$post(`${this.apiPrefix}-${mode}`, data)

    return response[this.apiOptions.responseItemName]
  }

  @DataCollection(carDataCollectionOptions)
  async republish(carId: string): Promise<FullCarDto | null> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      const response = await client.$axios.$put(`${this.apiPrefix}/${carId}/republish`)

      return response[this.apiOptions.responseItemName]
    } catch (e) {
      return null
    }
  }

  @DataCollection(carDataCollectionOptions)
  async updateStatus(carId: string, status: StatusCodeEnum): Promise<FullCarDto | null> {
    if (!this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      const response = await client.$axios.$put(`${this.apiPrefix}/${carId}/status/${status}`)

      return response[this.apiOptions.responseItemName]
    } catch (e) {
      return null
    }
  }
}

export default new ClientCarService()
