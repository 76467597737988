import { carOtherEquipmentDataCollectionOptions } from '~/configs/common/data-collection-option/car-other-equipment'
import { client } from '~/ports/client'
import type {
  AdvertisementCountDto,
  FilterableDto,
  FilterablePayloadDto,
  RequestStatusLogDto,
  UnknownObject,
  WithCarSlug,
} from '~/types/common'
import { getEmptyAdvertisementCount } from '~/types/common'
import { Api, ApiFilterableList, ApiShow, DataCollection } from '~/decorators'
import type { ApiInterface } from '~/contracts/crud/api.interface'
import type { FilterableListInterface, ShowInterface } from '~/contracts/crud'
import { carDataCollectionOptions } from '~/configs/common/data-collection-option/car'
import { carListDataCollectionOptions } from '~/configs/common/data-collection-option/car-list'
import type { CarDto, CarOtherEquipment, CarTypeEnum, FullCarDto } from '~/types/client'
import { mapFromDataCollections } from '~/mappers/common/map-from-data-collections.mapper'

interface CarService extends ApiInterface,
  ShowInterface<FullCarDto>,
  FilterableListInterface<CarDto, UnknownObject> {
}

@ApiFilterableList<CarDto, UnknownObject>(carListDataCollectionOptions)
@ApiShow<FullCarDto>(carDataCollectionOptions)
@Api('/cars', {
  responseItemName: 'car',
  responseItemListName: 'cars',
})
class CarService {
  @DataCollection(carDataCollectionOptions)
  async getBySlug(slug: string): Promise<WithCarSlug> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemName) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$get(`${this.apiPrefix}-by-slug/${slug}`)

    return response[this.apiOptions.responseItemName]
  }

  @DataCollection(carListDataCollectionOptions)
  async similarListById(id: string): Promise<CarDto[]> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemListName) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$get(`${this.apiPrefix}/${id}/similar`)

    return response[this.apiOptions.responseItemListName]
  }

  async statusLog(id: string): Promise<RequestStatusLogDto|null> {
    if (!this.apiPrefix || !this.apiOptions) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$get(`${this.apiPrefix}/${id}/statusLog`)

    const statusLog = 'statusLog'

    return response[statusLog]
  }

  async recommendedList(idList: string[], perPage: number = 12, pageNumber: number = 1): Promise<FilterableDto<CarDto> | null> {
    if (!idList.length) {
      return null
    }

    if (!this.apiPrefix || !this.apiOptions) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$post(`${this.apiPrefix}/recommending`, {
      perPage,
      pageNumber,
      idList,
    })

    response.items.forEach((item: UnknownObject) => mapFromDataCollections(item, carListDataCollectionOptions))

    return response
  }

  async bestOfferedList(perPage: number = 12, pageNumber: number = 1): Promise<FilterableDto<CarDto> | null> {
    if (!this.apiPrefix || !this.apiOptions) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$post(`${this.apiPrefix}/bestOffered`, {
      perPage,
      pageNumber,
    })

    response.items.forEach((item: UnknownObject) => mapFromDataCollections(item, carListDataCollectionOptions))

    return response
  }

  @DataCollection(carOtherEquipmentDataCollectionOptions)
  async otherEquipmentsList(id: string): Promise<CarOtherEquipment[]> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemListName) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$get(`${this.apiPrefix}/${id}/other-equipments`)

    return response[this.apiOptions.responseItemListName] || []
  }

  async getLastCarsByType(type: CarTypeEnum): Promise<CarDto[]> {
    if (!this.apiPrefix) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$get(`${this.apiPrefix}/get-last/${type}`)

    response.forEach((item: UnknownObject) => mapFromDataCollections(item, carListDataCollectionOptions))

    return response
  }

  async count(filterablePayload: FilterablePayloadDto): Promise<AdvertisementCountDto> {
    if (!this.apiPrefix) {
      throw new Error('@Api needed parameters is not defined')
    }

    const searchData = filterablePayload.search
    delete filterablePayload.search

    try {
      return await client.$axios.$patch(`${this.apiPrefix}/count`, searchData, {
        params: { ...filterablePayload },
      })
    } catch (e) {
      return getEmptyAdvertisementCount()
    }
  }

  @DataCollection(carListDataCollectionOptions)
  async listByIds(ids: string[]): Promise<CarDto[]> {
    if (!this.apiOptions || !this.apiOptions.responseItemListName) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$post('cars-by-ids', { ids })

    return response[this.apiOptions.responseItemListName]
  }

  @DataCollection(carListDataCollectionOptions)
  async listByIdsPublicOrArchive(ids: string[]): Promise<CarDto[]> {
    if (!this.apiOptions || !this.apiOptions.responseItemListName) {
      throw new Error('@Api needed parameters is not defined')
    }

    const response = await client.$axios.$post('cars-by-ids-public-or-archived', { ids })

    return response[this.apiOptions.responseItemListName]
  }

  async sendCreditFeedback(data: any): Promise<any> {
    try {
      await client.$axios.$post('/cars/credit', {
        name: data.name,
        phone: data.phone,
        carSlug: data.slug,
      })
    } catch (e: any) {
      throw new Error(e.message)
    }
  }
}

export default new CarService()
