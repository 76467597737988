import type { StepsFormWithAuthStatePayloadDto } from '~/types/client'
import { emptyStepsFormWithAuthStatePayload } from '~/types/client'

export interface TestDriveFormDto extends StepsFormWithAuthStatePayloadDto {
  carId: string | null,
  date: string | null,
  time: string | null,
}

export function emptyTestDriveForm(): TestDriveFormDto {
  return {
    ...emptyStepsFormWithAuthStatePayload(),
    carId: null,
    date: null,
    time: null,
    confirmationCodeButtonId: 'test-drive-step-two',
  }
}
