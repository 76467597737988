import type { PercentRangeInfoDto } from '~/types/client'

export const percentRangeInfoDefault: PercentRangeInfoDto[] = [
  {
    title: 'form-quality.add-adv.0-45.title',
    desc: 'form-quality.add-adv.0-45.desc',
    percentRangeMin: 0,
    percentRangeMax: 44.99,
  },
  {
    title: 'form-quality.add-adv.45-65.title',
    desc: 'form-quality.add-adv.45-65.desc',
    percentRangeMin: 45,
    percentRangeMax: 64.99,
  },
  {
    title: 'form-quality.add-adv.65-80.title',
    desc: 'form-quality.add-adv.65-80.desc',
    percentRangeMin: 65,
    percentRangeMax: 79.99,
  },
  {
    title: 'form-quality.add-adv.80-100.title',
    desc: 'form-quality.add-adv.80-100.desc',
    percentRangeMin: 80,
    percentRangeMax: 100,
  },
]
