import type { WithCarSlug } from '~/types/common'
import { SlugServiceAbstract } from '~/services/common/slug/slug-service.abstract'

class AdvertisementSlugService extends SlugServiceAbstract {
  create(car: WithCarSlug): string {
    const slugArray: string[] = []
    slugArray.push(...car.model.slug.split(' '))
    slugArray.push(car.year.toString(10))
    slugArray.push(car.id)

    return slugArray.join('-').toLowerCase()
  }
}

export default new AdvertisementSlugService()
