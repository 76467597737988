import type { GenerationDto, RequestGenerationDto } from '~/types/admin'
import type { ModelDto } from '~/types/common'

export interface FormGenerationDto {
  name: string
  model: ModelDto
  yearFrom: number | null
  yearTo: number | null
}

export function emptyFormGeneration(model: ModelDto): FormGenerationDto {
  return {
    name: '',
    yearFrom: null,
    yearTo: null,
    model: model,
  }
}

export function mapGenerationToForm(generation: GenerationDto): FormGenerationDto {
  const formData = emptyFormGeneration(generation.model)

  formData.name = generation.name
  formData.yearFrom = generation.yearFrom || null
  formData.yearTo = generation.yearTo || null

  return formData
}

export function mapFormGenerationToRequest(formData: FormGenerationDto): RequestGenerationDto {
  return {
    name: formData.name,
    model: formData.model.id,
    yearFrom: formData.yearFrom || null,
    yearTo: formData.yearTo || null,
  }
}
