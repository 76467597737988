import type { FinancialServiceActivityEnum, RequestFileDto } from '~/types/common'
import { mapFormFileToRequest } from '~/types/common'
import type { FormFinancialServiceDto } from '~/types/admin'

export interface RequestFinancialServiceDto {
  name?: string
  url?: string
  disabled: boolean
  position?: number
  logo?: RequestFileDto | null
  activities: FinancialServiceActivityEnum[]
}

export function mapFormFinancialServiceToRequest(formData: FormFinancialServiceDto): RequestFinancialServiceDto {
  return {
    name: formData.name || undefined,
    url: formData.url || undefined,
    disabled: formData.disabled,
    activities: formData.activities,
    logo: mapFormFileToRequest(formData.logo),
  }
}
