const middleware = {}

middleware['admin/auth.middleware'] = require('../middleware/admin/auth.middleware.ts')
middleware['admin/auth.middleware'] = middleware['admin/auth.middleware'].default || middleware['admin/auth.middleware']

middleware['client/auth.middleware'] = require('../middleware/client/auth.middleware.ts')
middleware['client/auth.middleware'] = middleware['client/auth.middleware'].default || middleware['client/auth.middleware']

middleware['client/car/car-is-archived-old.middleware'] = require('../middleware/client/car/car-is-archived-old.middleware.ts')
middleware['client/car/car-is-archived-old.middleware'] = middleware['client/car/car-is-archived-old.middleware'].default || middleware['client/car/car-is-archived-old.middleware']

middleware['client/car/car-view-history.middleware'] = require('../middleware/client/car/car-view-history.middleware.ts')
middleware['client/car/car-view-history.middleware'] = middleware['client/car/car-view-history.middleware'].default || middleware['client/car/car-view-history.middleware']

middleware['client/car/check-modified.middleware'] = require('../middleware/client/car/check-modified.middleware.ts')
middleware['client/car/check-modified.middleware'] = middleware['client/car/check-modified.middleware'].default || middleware['client/car/check-modified.middleware']

middleware['client/filter/filter-try-pretty-url.middleware'] = require('../middleware/client/filter/filter-try-pretty-url.middleware.ts')
middleware['client/filter/filter-try-pretty-url.middleware'] = middleware['client/filter/filter-try-pretty-url.middleware'].default || middleware['client/filter/filter-try-pretty-url.middleware']

middleware['client/guest.middleware'] = require('../middleware/client/guest.middleware.ts')
middleware['client/guest.middleware'] = middleware['client/guest.middleware'].default || middleware['client/guest.middleware']

middleware['client/is-removed.middleware'] = require('../middleware/client/is-removed.middleware.ts')
middleware['client/is-removed.middleware'] = middleware['client/is-removed.middleware'].default || middleware['client/is-removed.middleware']

middleware['client/only-auth.middleware'] = require('../middleware/client/only-auth.middleware.ts')
middleware['client/only-auth.middleware'] = middleware['client/only-auth.middleware'].default || middleware['client/only-auth.middleware']

middleware['client/only-dealer.middleware'] = require('../middleware/client/only-dealer.middleware.ts')
middleware['client/only-dealer.middleware'] = middleware['client/only-dealer.middleware'].default || middleware['client/only-dealer.middleware']

middleware['client/user-sessions.middleware'] = require('../middleware/client/user-sessions.middleware.ts')
middleware['client/user-sessions.middleware'] = middleware['client/user-sessions.middleware'].default || middleware['client/user-sessions.middleware']

middleware['common/fetch-data.middleware'] = require('../middleware/common/fetch-data.middleware.ts')
middleware['common/fetch-data.middleware'] = middleware['common/fetch-data.middleware'].default || middleware['common/fetch-data.middleware']

middleware['common/not-found.middleware'] = require('../middleware/common/not-found.middleware.ts')
middleware['common/not-found.middleware'] = middleware['common/not-found.middleware'].default || middleware['common/not-found.middleware']

middleware['common/page.middleware'] = require('../middleware/common/page.middleware.ts')
middleware['common/page.middleware'] = middleware['common/page.middleware'].default || middleware['common/page.middleware']

export default middleware
