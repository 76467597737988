import type { NuxtAxiosInstance } from '@nuxtjs/axios'

class Client {
  private _$axios!: NuxtAxiosInstance

  setClient(axios: NuxtAxiosInstance) {
    this._$axios = axios
  }

  get $axios() {
    return this._$axios
  }
}

const client = new Client()

export { client }
