import type { DataCollectionMapperOptions } from '~/types/common'
import { DataCollectionTypeEnum } from '~/types/common'
import { characteristicValuesMapper } from '~/mappers/common/characteristic-values.mapper'

export const carListDataCollectionOptions: DataCollectionMapperOptions = {
  bodyType: DataCollectionTypeEnum.car_body_type,
  fuelType: DataCollectionTypeEnum.car_fuel_type,
  dealerActions: DataCollectionTypeEnum.dealer_action,
  dealer: DataCollectionTypeEnum.dealer,
  model: DataCollectionTypeEnum.car_model,
  category: DataCollectionTypeEnum.category,
  currency: DataCollectionTypeEnum.currency,
  status: DataCollectionTypeEnum.advertisement_status,
  'location.city': DataCollectionTypeEnum.city,
  characteristicValues: characteristicValuesMapper,
}
