import { mapFileToForm } from '~/types/common'
import type { FormFileDto, SettingDto } from '~/types/common'

export interface FormSettingDto<DataType = unknown> {
  data: DataType
  file: FormFileDto
}

export function mapSettingToForm<DataType = unknown>(setting: SettingDto<DataType>): FormSettingDto<DataType> {
  return {
    data: JSON.parse(JSON.stringify(setting.data)),
    file: mapFileToForm(setting.file),
  }
}
