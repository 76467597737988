import type {
  DataCollectionItemDto,
  DataCollectionMapDto,
  DataCollectionTypeEnum,
  UnknownObject,
} from '~/types/common'

export interface DataCollectionDto<ItemsType = UnknownObject> {
  items: DataCollectionItemDto<ItemsType>[]
  hash: string
  type: DataCollectionTypeEnum
  maps: DataCollectionMapDto<ItemsType>
}

export function emptyDataCollection(type: DataCollectionTypeEnum): DataCollectionDto {
  return {
    items: [],
    hash: 'EMPTY',
    type,
    maps: { id: {} },
  }
}
