import { client } from '~/ports/client'
import type {
  DataCollectionMapperOptions,
  FilterableDto,
  FilterablePayloadDto,
  UnknownObject,
} from '~/types/common'
import { mapFromDataCollections } from '~/mappers/common/map-from-data-collections.mapper'

export function ApiFilterableList<IndexType, FilterType>(options: DataCollectionMapperOptions = {}) {
  return function <T extends { new (...args: any[]): {} }>(constructor: T) {
    constructor.prototype.list = async (filterablePayload: FilterablePayloadDto<FilterType>): Promise<FilterableDto<IndexType> | null> => {
      if (!constructor.prototype.apiPrefix) {
        throw new Error('@Api needed parameters is not defined')
      }

      const filterablePayloadClone = { ...filterablePayload }

      const searchData = filterablePayloadClone.search
      delete filterablePayloadClone.search

      let response
      try {
        response = await client.$axios.$patch(constructor.prototype.apiPrefix, searchData, {
          params: { ...filterablePayloadClone },
        })
      } catch (e) {
        return null
      }

      if (Object.entries(options).length) {
        response.items.forEach((item: UnknownObject) => mapFromDataCollections(item, options))
      }

      return response
    }
  }
}
