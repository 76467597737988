import type { LocationDto } from '~/types/common'

export interface RequestLocationDto {
  city: number | null
  longitude: number | null
  latitude: number | null
  address: string | null
}

export function emptyRequestLocation(): RequestLocationDto {
  return {
    city: null,
    longitude: null,
    latitude: null,
    address: null,
  }
}

export function mapLocationToRequest(location?: LocationDto): RequestLocationDto {
  if (!location || !location.address || !location.address.length) {
    return emptyRequestLocation()
  }

  return {
    latitude: location.latitude || null,
    longitude: location.longitude || null,
    city: location.city.id,
    address: location.address || null,
  }
}

// Kiev as a default city
export const defaultLocation: RequestLocationDto = {
  city: 1,
  longitude: 30.523,
  latitude: 50.45,
  address: '',
}
