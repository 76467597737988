export interface LoginDataDTO {
  username: string | null
  password: string | null
}

export interface LoginFormDataDTO {
  email: string | null
  phone: string | null
}

export function getEmptyLoginData(): LoginDataDTO {
  return {
    username: null,
    password: null,
  }
}

export function getEmptyLoginFormData(): LoginFormDataDTO {
  return {
    email: null,
    phone: null,
  }
}
