import type { ApiInterface } from '~/contracts/crud/api.interface'
import { Api, ApiUpdate, DataCollection } from '~/decorators'
import { testDriveDataCollectionOptions } from '~/configs/client'
import type { TestDriveDto } from '~/types/client'
import { client } from '~/ports/client'
import type { UpdateInterface } from '~/contracts/crud'
import type { FilterableDto } from '~/types/common'
import { emptyFilterableDto } from '~/types/common'
import { testDrivePaginatedDataCollectionOptions } from '~/configs/client/data-collection-option/test-drive-paginated'

interface DealerTestDriveService extends ApiInterface, UpdateInterface<TestDriveDto, TestDriveDto> {
}

@ApiUpdate<TestDriveDto, TestDriveDto>(testDriveDataCollectionOptions)
@Api('/secure/dealer-role/test-drives', {
  responseItemListName: 'testDrives',
  responseItemName: 'testDrive',
})
class DealerTestDriveService {
  @DataCollection(testDrivePaginatedDataCollectionOptions)
  async listByRole(id: string, page: number, perPage: number, startFrom: number | null = null): Promise<FilterableDto<TestDriveDto>> {
    if (!this.apiPrefix || !this.apiOptions || !this.apiOptions.responseItemListName) {
      throw new Error('@Api needed parameters is not defined')
    }

    try {
      return await client.$axios.$get(`secure/role/${id}/test-drives`, {
        params: {
          page,
          perPage,
          startFrom,
        },
      })
    } catch (e) {
      return emptyFilterableDto()
    }
  }
}

export default new DealerTestDriveService()
