import { AuthTypesEnum } from '~/types/common'
import type { AuthConfigurations } from '~/types/common/configs'

export const authConfigurations: AuthConfigurations = {
  [AuthTypesEnum.ADMIN]: {
    loginRedirect: { name: 'admin' },
    logoutRedirect: { name: 'admin-login' },
    storeNameSpace: 'admin/auth',
    localstorageProfile: 'admin_profile',
    localstorageToken: 'admin_token',
  },
  [AuthTypesEnum.CLIENT]: {
    loginRedirect: { name: 'client-profile' },
    logoutRedirect: { name: 'client-login' },
    storeNameSpace: 'client/auth',
    localstorageProfile: 'client_profile',
    localstorageToken: 'client_token',
  },
}
