export interface DailyStatisticsSummaryDto {
  newAdvertisements: number
  newOrganicAdvertisements: number
  archivedAdvertisements: number
  republishedAdvertisements: number
  newUsers: number
  newOrganicUsers: number
  organicLogins: number
  removedUsers: number
}

export function getEmptyDailyStatisticSummary(): DailyStatisticsSummaryDto {
  return {
    newUsers: 0,
    newOrganicUsers: 0,
    organicLogins: 0,
    removedUsers: 0,
    newAdvertisements: 0,
    newOrganicAdvertisements: 0,
    archivedAdvertisements: 0,
    republishedAdvertisements: 0,
  }
}
