export function LoadingWrapper(loaderPropertyName: string = 'loading') {
  return function (_target: object, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value

    descriptor.value = async function (...args: unknown[]) {
      const that = <{ [k: string]: unknown }> this

      if (!(loaderPropertyName in that)) {
        throw new Error(`Loader property in method ${propertyKey}() with name "${loaderPropertyName}" is not exist`)
      }

      that[loaderPropertyName] = true
      const result = await original.apply(that, args)
      that[loaderPropertyName] = false

      return result
    }
  }
}
