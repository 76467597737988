import type { Plugin } from '@nuxt/types'
import { PartModeEnum } from '~/types/common'

declare module 'vue/types/vue' {
  interface Vue {
    $getPartMode: () => PartModeEnum
  }
}

const adminAlias = process.env.adminAlias || 'admin'
const adminRegex = new RegExp(`^/${adminAlias}`, 'g')

const getPartMode: Plugin = function (context, inject) {
  inject('getPartMode', () => adminRegex.test(context.route.fullPath) ? PartModeEnum.ADMIN : PartModeEnum.FRONT)
}

export default getPartMode
