import type { Middleware } from '@nuxt/types'
import InvalidJwtException from '~/exceptions/invalid-jwt.exception'
import { AuthTypesEnum } from '~/types/common'

const AuthMiddleware: Middleware = async ({ app }) => {
  if (!app.$authenticate(AuthTypesEnum.CLIENT).checkToken()) {
    return
  }

  try {
    await app.$authenticate(AuthTypesEnum.CLIENT).setAxiosToken()
    await app.$authenticate(AuthTypesEnum.CLIENT).refreshUserData()
  } catch (error) {
    if (error instanceof InvalidJwtException) {
      app.$authenticate().logout()

      return
    }

    throw error
  }
}

export default AuthMiddleware
