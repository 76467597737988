import AuthClient from '~/ports/auth'
import CarViewHistoryApiService from '~/api/client/car-views-history.service'
import CacheStorageService from '~/services/common/cache-storage.service'

class CarViewHistoryService {
  private readonly historyStorageKey = 'car-view-history'
  private readonly lastUpdateStorageKey = 'car-view-history-updated-at'
  private readonly maxViewHistoryAdvertisements: number

  private ids: string[] | null = null
  private localUpdatedAt: number | null = null

  constructor() {
    this.maxViewHistoryAdvertisements = process.env.maxViewHistoryAdvertisements
      ? parseInt(process.env.maxViewHistoryAdvertisements, 10)
      : 50
  }

  init() {
    this.fetch()
  }

  add(id: string) {
    if (!this.ids) {
      this.ids = []
    }

    const existedIndex = this.ids.indexOf(id)
    if (existedIndex >= 0) {
      this.ids.splice(existedIndex, 1)
    }

    if (this.ids.length > this.maxViewHistoryAdvertisements) {
      this.ids.splice(0, this.ids.length - this.maxViewHistoryAdvertisements)
    }

    this.ids.push(id)
    this.save()
  }

  getAll(): string[] {
    return this.ids || []
  }

  private save() {
    CacheStorageService.write(this.historyStorageKey, this.ids)
    if (!AuthClient.$auth.isLoggedIn()) {
      return
    }

    CarViewHistoryApiService.update({ idList: this.ids! }).then((history) => {
      if (!history) {
        return
      }
      this.ids = history.idList
    })
  }

  private fetch() {
    this.ids = CacheStorageService.read<string[]>(this.historyStorageKey) || []
    this.localUpdatedAt = CacheStorageService.read<number>(this.lastUpdateStorageKey)

    if (!AuthClient.$auth.isLoggedIn()) {
      return
    }

    CarViewHistoryApiService.show().then((history) => {
      if (!history || !history.updatedAt || !history.idList.length) {
        this.localUpdatedAt = Date.now()
        CacheStorageService.write(this.lastUpdateStorageKey, this.localUpdatedAt)

        return
      }

      if (this.localUpdatedAt && this.localUpdatedAt > history.updatedAt) {
        return
      }

      this.ids = history.idList
    })
  }
}

export default new CarViewHistoryService()
