import type { OwnCarDto } from '~/types/client'

export interface RequestOwnCarDto {
  year: number
  model: string
}

export function mapOwnCarDtoToRequest(ownCar: OwnCarDto): RequestOwnCarDto {
  return {
    year: ownCar.year,
    model: ownCar.model.id,
  }
}
