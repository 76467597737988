import { Component, Vue } from 'vue-property-decorator'
import { DataCollectionGetter } from '~/decorators/data-collection-getter.decorator'
import type { HeaderLinkDto } from '~/types/common'
import { DataCollectionTypeEnum, HeaderLinkTypeEnum } from '~/types/common'

@Component
export default class HeaderLinksMixin extends Vue {
  @DataCollectionGetter(DataCollectionTypeEnum.header_link)
  readonly headerLinks!: HeaderLinkDto[]

  get headerMainLinks(): HeaderLinkDto[] {
    return this.headerLinks.filter(link => link.type === HeaderLinkTypeEnum.HEADER)
  }

  get headerTopLinks(): HeaderLinkDto[] {
    return this.headerLinks.filter(link => link.type === HeaderLinkTypeEnum.TOP)
  }
}
