import type { StorageContract } from '~/services/common/storage/storage.contract'
import localStorageServiceProvider from '~/services/common/storage/local-storage-service.provider'

class PWAService {
  readonly sessionCountKey = 'pwa-session-count'
  readonly storageSessionDateKey = 'session-date'
  readonly incrementSessionStatusKey = 'increment-status'

  constructor(private readonly storage: StorageContract) {}

  getSessionCount(): number {
    const storageData = JSON.parse(this.storage.get(this.sessionCountKey) as string)

    return storageData ? Number(storageData) : 0
  }

  getIncrementStatus(): string {
    return JSON.parse(this.storage.get(this.incrementSessionStatusKey) as string)
  }

  incrementUserSessions() {
    let countPWASessions = this.storage.get(this.sessionCountKey)
      ? Number(JSON.parse(this.storage.get(this.sessionCountKey) as string))
      : 0

    this.storage.set(this.sessionCountKey, JSON.stringify(++countPWASessions))
  }

  setDefaultSession() {
    this.storage.set(this.sessionCountKey, JSON.stringify(0))
  }

  changeSessionIncrementStatus(status: boolean) {
    this.storage.set(this.incrementSessionStatusKey, status)
  }

  updateSessionDate() {
    const newDate = new Date()
    this.storage.set(this.storageSessionDateKey, newDate)
  }

  getLastSessionDate(): string | null {
    return this.storage.get(this.storageSessionDateKey)
  }
}

export default new PWAService(localStorageServiceProvider())
