import type { Plugin } from '@nuxt/types'
import type { Dictionary } from '~/types/common'
import { AuthTypesEnum } from '~/types/common'
import { store } from '~/ports/store'

const axios: Plugin = function (context) {
  context.$axios.onError(({ response }) => {
    if (!response || !response.config.url) {
      return
    }

    switch (response.status) {
      case 403:
        if ('error' in response.data && context.app.i18n.te(`errors.${response.data.error}`)) {
          context.app.$toast.error(context.app.i18n.t(`errors.${response.data.error}`))
        } else {
          context.app.$toast.error(context.app.i18n.t('errors.no-access'))
        }

        return
      case 401:
        const errorKey = response.data.message || 'Invalid credentials.'
        context.app.$toast.error(context.app.i18n.t(`errors.${errorKey}`))

        if (!context.route.name || /\/login$/g.test(response.config.url)) {
          return
        }

        if (context.route.name.includes('admin') && context.route.name !== 'admin-login') {
          context.app.$authenticate(AuthTypesEnum.ADMIN).logout()

          return
        }

        if (context.route.name.includes('profile')) {
          context.app.$authenticate(AuthTypesEnum.CLIENT).logout()

          return
        }

        return

      case 400:
        if ('error' in response.data) {
          context.app.$toast.error(response.data.error)

          return
        }

        if ('errors' in response.data) {
          store.$store.commit('common/server-validation-errors/setErrors', response.data.errors as Dictionary<string>)

          return
        }

        context.app.$toast.error(context.app.i18n.t('errors.Something went wrong'))

        return

      case 404:
        const metaData = context.route.meta.find((item: Dictionary<string>) => 'handleFourHundredFourError' in item)

        if (metaData !== undefined && !metaData.handleFourHundredFourError) {
          return
        }

        context.app.$toast.error(context.app.i18n.t('errors.Current entity is not exist'))
        context.error({ statusCode: 404, message: 'Post not found' })

        return

      case 429:
        return
    }

    context.app.$toast.error(context.app.i18n.t('errors.Something went wrong'))
  })
}

export default axios
