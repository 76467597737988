import { SortingTypeEnum } from '~/types/common'
import type { NewsSortOptionDto } from '~/types/common'
import { i18n } from '~/ports/i18n'

export const newsSortOptions = (): NewsSortOptionDto[] => {
  return [
    {
      label: i18n.i18n.t('sort_by.created_ZA'),
      key: 'publishedAt',
      direction: SortingTypeEnum.DESC,
    },
  ]
}

export const defaultNewsSortOption = (): NewsSortOptionDto => newsSortOptions()[0]
