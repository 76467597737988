// @ts-ignore
import browserUpdate from 'browser-update'
import { disabledBodyScrollClassName } from '~/plugins/is-allow-body-scroll'

browserUpdate({
  required: { e: 18, f: 62, o: 57, s: 9, c: 70 },
  reminder: 0,
  text: 'Привіт, щоб використовувати наші технології на повну, будь ласка, скористайся іншим браузером :) <a href="https://browsehappy.com/" target="_blank">Оновити</a>',
  onshow: function () {
    document.body.classList.add(disabledBodyScrollClassName)
  },
  shift_page_down: false,
  noclose: true,
  no_permanent_hide: true,
})
