import { mapFormFileToRequest } from '~/types/common'
import type { FormUserDto, RequestFileDto, RequestLocationDto } from '~/types/common'

export interface RequestUserDto {
  firstName?: string
  lastName?: string | null
  email?: string | null
  photo?: RequestFileDto | null
  location?: RequestLocationDto
}

export function mapFormUserToRequest(formData: FormUserDto): RequestUserDto {
  return {
    firstName: formData.firstName || undefined,
    lastName: formData.lastName || null,
    email: formData.email || null,
    location: formData.location || undefined,
    photo: mapFormFileToRequest(formData.photo),
  }
}
