import type { FilterStatsDto } from '~/types/common'

export interface AdvertisementCountDto {
  stats: FilterStatsDto
  total: number
}

export function getEmptyAdvertisementCount(): AdvertisementCountDto {
  return {
    stats: {},
    total: 0,
  }
}
