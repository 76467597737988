import type { Middleware } from '@nuxt/types'
import AdvertisementSlugService from '~/services/common/slug/advertisement-slug.service'
import CarService from '~/api/client/car.service'

const CheckModified: Middleware = async ({ req, res, route }) => {
  if (!res || !req) { return }

  const carId = AdvertisementSlugService.parseId(route.params.slug)

  if (!carId) {
    return
  }

  const car = await CarService.show(carId)

  if (!car) {
    return
  }

  if (car.updatedAt) {
    const contentLastModifiedTimestamp = new Date(car.updatedAt * 1000).getTime()
    const httpLastModified = new Date(contentLastModifiedTimestamp).toUTCString()
    const ifModifiedSince = req.headers['if-modified-since']
    const statusCode = 304

    if (ifModifiedSince && (new Date(ifModifiedSince).getTime() >= contentLastModifiedTimestamp)) {
      const metaStatusCodeNode = document.createElement('meta')

      metaStatusCodeNode.setAttribute('name', 'prerender-status-code')
      metaStatusCodeNode.setAttribute('content', `${statusCode}`)

      document.head.appendChild(metaStatusCodeNode)

      res.statusCode = statusCode
      res.end()
    } else {
      const metaHeaderNode = document.createElement('meta')

      metaHeaderNode.setAttribute('name', 'prerender-header')
      metaHeaderNode.setAttribute('content', `Last-Modified: ${httpLastModified}`)

      document.head.appendChild(metaHeaderNode)

      res.setHeader('Last-Modified', httpLastModified)
    }
  }
}

export default CheckModified
