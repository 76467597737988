import type { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { namespace } from 'vuex-class'
import type {
  AuthState,
  LocationDto,
  LoginDataDTO,
  LoginResponseDTO,
  MessengerDataDto,
  ProfileDealerRoleDto,
  ProfileDto,
  RootState,
  UserDataDto,
  UserRoleEnum,
} from '~/types/common'
import {
  AuthTypesEnum,
  DealerRolesEnum,
  emptyAuthState,
  emptyProfileDto,
  mapFormDealerToRequest,
  SocialProvidersEnum,
} from '~/types/common'
import type {
  AdvertisementPaybackDto,
  OwnCarDto,
  RegistrationDto,
  SavedFilterDto,
  SavedFilterPaybackDto,
} from '~/types/client'
import { client } from '~/ports/client'
import LocalProfileService from '~/services/common/local-profile.service'
import ProfileService from '~/api/client/profile.service'
import type { BaseFavoriteListDto } from '~/types/client/favorite-list/base-favorite-list.dto'
import type { UserConsentDto } from '~/types/common/user-consent'
import MaxLoginTriesException from '~/exceptions/max-login-tries.exception'
import type { UserDataDtoWithId } from '~/types/common/auth/user-data/user-data-with-id.dto'

export const state = (): AuthState => emptyAuthState()

export const actions: ActionTree<AuthState, RootState> = {
  async login(
    { commit }: ActionContext<AuthState, RootState>,
    { username, password }: LoginDataDTO,
  ) {
    try {
      const response: LoginResponseDTO = await this.$axios.$post('secure/login', { username, password })

      commit('setLoggedIn', true)

      return response
    } catch (error) {
      if (error.isAxiosError && error.response.status === 400 && error.response.data.reason === 'max_login_tries') {
        throw new MaxLoginTriesException()
      }

      return null
    }
  },

  async logout({ commit }: ActionContext<AuthState, RootState>) {
    await this.$axios.$get('logout')
    commit('setLoggedIn', false)
  },

  async signup({ commit }: ActionContext<AuthState, RootState>, registrationData: RegistrationDto) {
    const data = {
      ...registrationData,
      dealer: registrationData.dealer ? mapFormDealerToRequest(registrationData.dealer) : undefined,
    }

    const response: LoginResponseDTO = await client.$axios.$post('signup', data)

    commit('setLoggedIn', true)

    return response
  },

  async refresh({ commit }: ActionContext<AuthState, RootState>) {
    const profile = await ProfileService.get()

    commit('setProfile', profile)
  },
}

export const mutations: MutationTree<AuthState> = {
  setLoggedIn(state: AuthState, status: boolean) {
    state.isLoggedIn = status
  },

  setProfile(state: AuthState, profile: ProfileDto | null) {
    state.profile = profile || emptyProfileDto()
  },

  removeOwnCar(state: AuthState, id: string) {
    const removeIndex = state.profile.userOwnCars.findIndex(ownCar => ownCar.id === id)

    if (removeIndex < 0) {
      return
    }

    state.profile.userOwnCars.splice(removeIndex, 1)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  addOwnCar(state: AuthState, ownCar: OwnCarDto) {
    state.profile.userOwnCars.push(ownCar)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  addMessenger(state: AuthState, messenger: MessengerDataDto) {
    const messengerIndex = state.profile.messengers.findIndex(item => item.id === messenger.id)

    if (messengerIndex < 0) {
      state.profile.messengers.push(messenger)
    } else {
      state.profile.messengers.splice(messengerIndex, 1, messenger)
    }

    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  removeMessenger(state: AuthState, id: string) {
    const messengerIndex = state.profile.messengers.findIndex(item => item.id === id)

    if (messengerIndex < 0) {
      return
    }

    state.profile.messengers.splice(messengerIndex, 1)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  addSocialLogin(state: AuthState, provider: SocialProvidersEnum) {
    switch (provider) {
      case SocialProvidersEnum.FACEBOOK:
        state.profile.isFacebookConnected = true
        break
      case SocialProvidersEnum.GOOGLE:
        state.profile.isGoogleConnected = true
        break
      default:
        return
    }

    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  removeSocialLogin(state: AuthState, provider: SocialProvidersEnum) {
    switch (provider) {
      case SocialProvidersEnum.FACEBOOK:
        state.profile.isFacebookConnected = false
        break
      case SocialProvidersEnum.GOOGLE:
        state.profile.isGoogleConnected = false
        break
      default:
        return
    }

    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  removeAccount(state: AuthState) {
    state.profile.isRemoved = true
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  addFavoriteList(state: AuthState, favoriteList: BaseFavoriteListDto) {
    state.profile.favoriteLists.push(favoriteList)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  removeFavoriteList(state: AuthState, favoriteList: BaseFavoriteListDto) {
    const removedIndex = state.profile.favoriteLists.findIndex(list => list.id === favoriteList.id)

    if (removedIndex < 0) {
      return
    }
    state.profile.favoriteLists.splice(removedIndex, 1)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  addSavedFilter(state: AuthState, savedFilterPayback: SavedFilterPaybackDto) {
    const favoriteList = state.profile.favoriteLists.find(list => list.id === savedFilterPayback.list)

    if (!favoriteList) {
      return
    }

    favoriteList.filters.push(savedFilterPayback.filter)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  removeSavedFilter(state: AuthState, id: string) {
    for (const list of state.profile.favoriteLists) {
      const filterIndex = list.filters.findIndex(filter => filter.id === id)

      if (filterIndex < 0) {
        continue
      }

      list.filters.splice(filterIndex, 1)
      LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)

      return
    }
  },

  addToComparison(state: AuthState, carId: string) {
    state.profile.comparisonIdList.push(carId)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  removeFromComparison(state: AuthState, carId: string) {
    const comparisonIndex = state.profile.comparisonIdList.findIndex(id => carId === id)

    if (comparisonIndex < 0) {
      return
    }

    state.profile.comparisonIdList.splice(comparisonIndex, 1)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  addSavedAdvertisement(state: AuthState, savedAdvertisement: AdvertisementPaybackDto) {
    const favoriteList = state.profile.favoriteLists.find(list => list.id === savedAdvertisement.listId)

    if (!favoriteList) {
      return
    }

    favoriteList.advertisementIdList.push(savedAdvertisement.advertisementId)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  removeSavedAdvertisement(state: AuthState, payload: AdvertisementPaybackDto) {
    const favoriteList = state.profile.favoriteLists.find(list => list.id === payload.listId)

    if (!favoriteList) {
      return
    }

    favoriteList.advertisementIdList.splice(favoriteList.advertisementIdList.findIndex(advId => advId === payload.advertisementId), 1)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },

  addConsent(state: AuthState, payload: UserConsentDto) {
    state.profile.consents.push(payload)
    LocalProfileService.setProfile(state.profile, AuthTypesEnum.CLIENT)
  },
}

export const getters: GetterTree<AuthState, RootState> = {
  isLoggedIn(state: AuthState): boolean {
    return state.isLoggedIn
  },

  username(state: AuthState): string {
    return state.profile.username
  },

  roles(state: AuthState): UserRoleEnum[] {
    return state.profile.roles
  },

  userMessengers(state: AuthState): MessengerDataDto[] {
    return state.profile.messengers
  },

  ownCars(state: AuthState): OwnCarDto[] {
    return state.profile.userOwnCars
  },

  isDealer(state: AuthState): boolean {
    return !!(state.profile && state.profile.dealerRoles.length)
  },

  dealerRoles(state: AuthState): ProfileDealerRoleDto[] {
    return state.profile.dealerRoles
  },

  isDealerAdmin(state: AuthState): boolean {
    return !!state.profile.dealerRoles.length && state.profile.dealerRoles[0].role === DealerRolesEnum.DEALER_ADMIN
  },

  isDealerManager(state: AuthState): boolean {
    return !!state.profile.dealerRoles.length && state.profile.dealerRoles[0].role === DealerRolesEnum.DEALER_MANAGER
  },

  userData(state: AuthState): UserDataDto {
    return {
      firstName: state.profile.firstName,
      lastName: state.profile.lastName,
      email: state.profile.email,
      photo: state.profile.photo,
      phone: state.profile.phone,
      location: state.profile.location,
      emailConfirmed: state.profile.emailConfirmed,
    }
  },

  userDataWithId(state: AuthState): UserDataDtoWithId {
    return {
      id: state.profile.id,
      firstName: state.profile.firstName,
      lastName: state.profile.lastName,
      email: state.profile.email,
      photo: state.profile.photo,
      phone: state.profile.phone,
      location: state.profile.location,
      emailConfirmed: state.profile.emailConfirmed,
    }
  },

  consents(state: AuthState): UserConsentDto[] {
    return state.profile.consents
  },

  isGoogleConnected(state: AuthState): boolean {
    return state.profile.isGoogleConnected
  },

  isFacebookConnected(state: AuthState): boolean {
    return state.profile.isFacebookConnected
  },

  isRemoved(state: AuthState): boolean {
    return state.profile.isRemoved
  },

  favoriteLists(state: AuthState): BaseFavoriteListDto[] {
    return state.profile.favoriteLists
  },

  favoriteListWithoutDefault(state: AuthState): BaseFavoriteListDto[] {
    return state.profile.favoriteLists.filter(list => !list.isDefaultList)
  },

  defaultFavoriteList(state: AuthState): BaseFavoriteListDto|null {
    return state.profile.favoriteLists.filter(list => list.isDefaultList)[0] ?? null
  },

  savedFilters(state: AuthState): SavedFilterDto[] {
    return state.profile.favoriteLists.map(list => list.filters).flat()
  },

  comparisonIdList(state: AuthState): string[] {
    return state.profile.comparisonIdList
  },

  savedAdvertisementIdList(state: AuthState): string[] {
    return state.profile.favoriteLists.map(list => list.advertisementIdList).flat()
  },

  savedUniqueAdvertisementIdList(state: AuthState): string[] {
    const listOfAdvertisementIdLists = state.profile.favoriteLists.map(list => list.advertisementIdList)

    const allAdvertisements = listOfAdvertisementIdLists.flat()

    return Array.from(new Set(allAdvertisements))
  },

  location(state: AuthState): LocationDto | null {
    return state.profile.location
  },
}

export const ClientAuthStoreModule = namespace('client/auth')
