import type { StepsFormWithAuthStatePayloadDto } from '~/types/client'
import {
  emptyStepsFormWithAuthStatePayload,
} from '~/types/client'
import type { ComplaintCategoryDto } from '~/types/common'

export interface FormComplaintDto extends StepsFormWithAuthStatePayloadDto {
  category: ComplaintCategoryDto | null
  description: string | null
  advertisement: string | null
}

export function emptyFormComplaint(): FormComplaintDto {
  return {
    ...emptyStepsFormWithAuthStatePayload(),
    advertisement: null,
    description: null,
    category: null,
  }
}
