













import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Colors, iconSizes } from '~/configs/client'
import { ButtonTypeEnum } from '~/types/client'
import VBtn from '~/components/client/ui/buttons/v-btn.vue'
import ModalAbstractComponent from '~/components/common/modal/modal.abstract'
import DeviceDetectService from '~/services/common/device-detect.service'

@Component({
  components: { VBtn },
})
export default class VModal extends Mixins(ModalAbstractComponent) {
  readonly colors = Colors
  readonly iconSizes = iconSizes
  readonly buttonTypes = ButtonTypeEnum
  readonly isMobile: boolean = DeviceDetectService.isMobile()

  @Prop({ default: null })
  readonly containerClass!: string | null

  @Watch('isShow', { immediate: true })
  changeShowStatus(newValue: boolean) {
    newValue ? this.$isAllowBodyScroll(false) : this.$isAllowBodyScroll(true)

    if (!this.isMobile) {
      return
    }
    this.$bus.$emit('toggle-slider-transform', newValue)
  }

  handleClose() {
    this.isShow = false
    this.$emit('close')
  }
}
