import type { DataCollectionMapperOptions, Dictionary } from '~/types/common'
import { mapFromDataCollections } from '~/mappers/common/map-from-data-collections.mapper'

export function DataCollection(options: DataCollectionMapperOptions) {
  return function (_target: object, _propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value

    descriptor.value = async function (...args: unknown[]) {
      const that = <Dictionary<unknown>> this

      const result = await original.apply(that, args)

      if (!result || typeof result !== typeof {}) {
        return result
      }

      if (Array.isArray(result)) {
        for (const item of result) {
          mapFromDataCollections(item, options)
        }

        return result
      }

      mapFromDataCollections(result, options)

      return result
    }
  }
}
