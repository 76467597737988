import { client } from '~/ports/client'

export function ApiRemove() {
  return function <T extends { new (...args: any[]): {} }>(constructor: T) {
    constructor.prototype.remove = async (id: string): Promise<boolean> => {
      if (!constructor.prototype.apiPrefix) {
        throw new Error('@Api needed parameters is not defined')
      }

      try {
        await client.$axios.$delete(`${constructor.prototype.apiPrefix}/${id}`)

        return true
      } catch (e) {
        return false
      }
    }
  }
}
