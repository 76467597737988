import type { Dictionary } from '~/types/common/dictionary'
import type { ServerValidationErrorDto } from '~/types/common/server-validation-error.dto'

export interface ServerValidationErrorsState {
  errors: Dictionary<ServerValidationErrorDto>
}

export function emptyServerValidationErrorsState(): ServerValidationErrorsState {
  return {
    errors: {},
  }
}
