import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type { FilterSelectedOptionsCarDto, SearchDataDto, SelectedOptionsDealersDto } from '~/types/client'
import type { RegionDto } from '~/types/common'
import { FilterKeysEnum } from '~/types/common'
import DataCollectionService from '~/api/common/data-collection.service'

export class RegionDecodeStrategy<T extends FilterSelectedOptionsCarDto | SelectedOptionsDealersDto> extends StringDecodeStrategyAbstract<T> {
  readonly pattern = `^${FilterKeysEnum.REGION}=${this.numberArrayPattern}$`

  process(subject: SearchDataDto<T>, searchItemString: string): void {
    const data = searchItemString.split('=')
    const regionIds = data[1].split(',').map(item => parseInt(item))

    const mapOfRegions = DataCollectionService.collections.region!.maps.id
    for (const regionId of regionIds) {
      const region = mapOfRegions[regionId] as RegionDto|undefined

      if (!region) {
        continue
      }

      subject.selectedOptions.region.push(region)
    }
  }
}
