import type { Middleware } from '@nuxt/types'

const IsRemovedMiddleware: Middleware = async ({ store, app }) => {
  const isRemoved: boolean = store.getters['client/auth/isRemoved']

  if (!isRemoved) {
    return
  }

  await app.router?.push({ name: 'removed' })
}

export default IsRemovedMiddleware
