import { mapFormMarketirsementToRequestDto } from '~/types/admin'
import {
  isUpdateContentBlock, mapCreateFormContentToRequestDto,
  mapFormFileToRequest,
  mapFormVideoToRequest,
  mapUpdateFormContentToRequestDto,
} from '~/types/common'
import type {
  RequestFileDto,
  UpdateBaseContentBlockDto,
  RequestVideoDto,
  RequestContentBlockDto,
  FormContentBlockDto,

  NewsStatusCodeEnum,
} from '~/types/common'
import type { FilterDto, FormNewsDto, RequestMarketirsementDto } from '~/types/admin'

export interface RequestNewsDto {
  title?: string
  subtitle?: string
  newsAuthorId?: string
  tags?: string
  statusCode?: NewsStatusCodeEnum
  addContent?: RequestContentBlockDto[],
  updateContent?: RequestContentBlockDto<UpdateBaseContentBlockDto>[],
  filter?: FilterDto
  isHot?: boolean
  image?: RequestFileDto | null
  video?: RequestVideoDto | null
  marketirsement?: RequestMarketirsementDto | null
}

export function mapFormNewsToRequestDto(news: FormNewsDto): RequestNewsDto {
  return {
    title: news.title,
    subtitle: news.subtitle,
    newsAuthorId: news.newsAuthorId,
    filter: news.filter,
    addContent: prepareContentRequest(news.newContent),
    updateContent: prepareContentRequest(news.updatedContent) as RequestContentBlockDto<UpdateBaseContentBlockDto>[],
    image: news.image ? mapFormFileToRequest(news.image) : undefined,
    isHot: news.isHot,
    statusCode: news.statusCode,
    tags: news.tags.join(','),
    marketirsement: !news.marketirsement ? news.marketirsement : mapFormMarketirsementToRequestDto(news.marketirsement),
    video: news.video ? mapFormVideoToRequest(news.video) : undefined,
  }
}

function prepareContentRequest(contents: FormContentBlockDto[]): RequestContentBlockDto[] {
  const requestContents: RequestContentBlockDto[] = []

  for (const content of contents) {
    if (!content) {
      continue
    }

    const requestContent = isUpdateContentBlock(content)
      ? mapUpdateFormContentToRequestDto(content)
      : mapCreateFormContentToRequestDto(content)

    if (!requestContent) {
      continue
    }

    requestContents.push(requestContent)
  }

  return requestContents
}
