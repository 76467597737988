













import { Vue, Component } from 'vue-property-decorator'
import AppMenu from '~/components/admin/menu/index.vue'
import { AuthTypesEnum } from '~/types/common'

@Component({
  components: {
    AppMenu,
  },
})
export default class AdminHeaderComponent extends Vue {
  type: AuthTypesEnum = AuthTypesEnum.ADMIN
}
