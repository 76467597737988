




























import { Component, Prop, Vue } from 'vue-property-decorator'
import type { LinkDto } from '~/types/client'

@Component
export default class CardNavigate extends Vue {
  @Prop({ default: [] })
  readonly links!: LinkDto[]

  get visibleLinks(): LinkDto[] {
    return this.links.filter(item => !item.hide)
  }
}
