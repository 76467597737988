import type { SocialLinkDto } from '~/types/client'
import { SocialLinkCodeEnum } from '~/types/client'

export const socialLinkList: SocialLinkDto[] = [
  {
    link: 'https://www.facebook.com/mymycarsdigital/',
    code: SocialLinkCodeEnum.FACEBOOK,
  },
  {
    link: 'https://www.instagram.com/mymycars_com/',
    code: SocialLinkCodeEnum.INSTAGRAM,
  },
  {
    link: 'https://t.me/prodamtachky',
    code: SocialLinkCodeEnum.TELEGRAM,
  },
  {
    link: 'https://www.youtube.com/channel/UCo1Fd2Jm_26a9N7MQw3cfow',
    code: SocialLinkCodeEnum.YOUTUBE,
  },
  {
    link: 'https://www.tiktok.com/@mymycars?_t=8pbgUFYOUZK&_r=1',
    code: SocialLinkCodeEnum.TIKTOK,
  },
]
