import type {
  CharacteristicValueListDto,
  CheckboxCharacteristicDto,
  ColorCharacteristicDto,
  MultiChoiceCharacteristicDto,
  RequestCharacteristicValueDto,
  SingleChoiceCharacteristicDto,
} from '~/types/common'
import {
  CharacteristicTypesEnum,
} from '~/types/common'

export interface RequestCharacteristicValueListDto {
  [characteristicId: string]: RequestCharacteristicValueDto
}

export function mapCharacteristicValueListToRequest(
  characteristicValueLists: CharacteristicValueListDto[],
): RequestCharacteristicValueListDto {
  const requestData: RequestCharacteristicValueListDto = {}

  characteristicValueLists.forEach((characteristicValueList) => {
    const characteristicId = characteristicValueList.characteristic.id

    switch (characteristicValueList.characteristic.dtype) {
      case CharacteristicTypesEnum.singleChoice:
        const singleChoiceCharacteristicValue = characteristicValueList as CharacteristicValueListDto<SingleChoiceCharacteristicDto>

        if (!singleChoiceCharacteristicValue.value) {
          break
        }

        requestData[characteristicId] = { value: singleChoiceCharacteristicValue.value.id }
        break

      case CharacteristicTypesEnum.multiChoice:
        const multiChoiceCharacteristicValue = characteristicValueList as CharacteristicValueListDto<MultiChoiceCharacteristicDto>

        if (!multiChoiceCharacteristicValue.value || !multiChoiceCharacteristicValue.value.length) {
          break
        }

        requestData[characteristicId] = { value: multiChoiceCharacteristicValue.value.map(value => value.id) }
        break

      case CharacteristicTypesEnum.color:
        const colorCharacteristicValue = characteristicValueList as CharacteristicValueListDto<ColorCharacteristicDto>

        if (!colorCharacteristicValue.value) {
          break
        }

        requestData[characteristicId] = { value: colorCharacteristicValue.value.id }
        break

      case CharacteristicTypesEnum.number:
        const checkboxCharacteristicValue = characteristicValueList as CharacteristicValueListDto<CheckboxCharacteristicDto>

        if (characteristicValueList.value === null) {
          break
        }
        requestData[characteristicId] = { value: checkboxCharacteristicValue.value }
        break

      case CharacteristicTypesEnum.checkbox:
      case CharacteristicTypesEnum.text:
        if (characteristicValueList.value === null) {
          break
        }
        requestData[characteristicId] = { value: characteristicValueList.value as string | number | boolean }
        break
    }
  })

  return requestData
}
