import Vue from 'vue'
import { client } from '~/ports/client'
import JsonService from '~/services/common/json.service'
import type { DataCollectionDto, UnknownObject } from '~/types/common'
import type { DataCollectionList } from '~/types/admin'
import { dataCollectionMapper } from '~/mappers/common/data-collection.mapper'
import { mapFromDataCollections } from '~/mappers/common/map-from-data-collections.mapper'
import CacheStorageService from '~/services/common/cache-storage.service'
import { dataCollectionMappingOptions } from '~/configs/common/data-collection-option/data-collection-mapping-options'
import { emptyDataCollectionList } from '~/types/admin'
import { DataCollectionTypeEnum } from '~/types/common'
import type { RequestCheckDataCollectionDto } from '~/types/common/data-collection/request-check-data-collection.dto'

class DataCollectionService {
  private localStorageKey = 'data-collection'
  private responseKey = 'data'
  private initialized = false
  private hasData = false
  dataCollectionItemIdFieldKey = '$dataCollectionItemIdField'
  collections: DataCollectionList = emptyDataCollectionList()
  sourceCollections: DataCollectionList = {}

  async initialize() {
    if (!this.initialized) {
      this.fetchAllFromLocalStorage()
    }

    const needRefresh = !await this.check()

    if (!needRefresh) {
      this.linkCollections()
    } else {
      await this.refresh()
    }

    // eslint-disable-next-line no-console
    console.debug(`Size of ${this.localStorageKey} - ${CacheStorageService.size(this.localStorageKey)}`)
  }

  public getMappedCollection<T>(collectionName: DataCollectionTypeEnum, mapKey?: string) {
    mapKey = mapKey || 'id'
    const collectionMap = this.collections[collectionName] && this.collections[collectionName]!.maps ? (this.collections[collectionName]!.maps[mapKey] || []) : []

    return new Map<string, T>(Object.entries(collectionMap) as [string, T][])
  }

  public async refresh() {
    this.initialized = false
    const hashList: RequestCheckDataCollectionDto = {}

    for (const type in this.collections) {
      const dataCollection = this.collections[<DataCollectionTypeEnum>type]

      hashList[<DataCollectionTypeEnum>type] = dataCollection ? dataCollection.hash : undefined
    }

    const response = await client.$axios.$post('/data-collections/check', { hashList })

    const refreshedCollections: DataCollectionList = response[this.responseKey]

    this.updateLocalStorageCollections(refreshedCollections)

    this.fetchAllFromLocalStorage()
    this.linkCollections()
  }

  private linkCollections() {
    if (this.initialized) {
      return
    }

    for (const type in this.collections) {
      const collectionType = <DataCollectionTypeEnum>type
      const collection = this.collections[collectionType]

      const options = dataCollectionMapper(collectionType)
      if (!options || !collection) {
        continue
      }

      mapFromDataCollections(<unknown>collection as UnknownObject, options)
    }

    this.initialized = true
  }

  private readFromLocalStorage(): DataCollectionList | null {
    return CacheStorageService.read(this.localStorageKey) as DataCollectionList
  }

  private updateLocalStorageCollections(collections: DataCollectionList) {
    if (Object.values(collections).length === 0) {
      return
    }

    for (const type in collections) {
      const collectionType = <DataCollectionTypeEnum>type
      const collection = collections[collectionType]

      if (!collection) {
        continue
      }

      this.sourceCollections[collectionType] = JSON.parse(JSON.stringify(collection))
    }

    CacheStorageService.write(this.localStorageKey, this.sourceCollections)
  }

  private fetchAllFromLocalStorage() {
    const fetchedCollections = this.readFromLocalStorage()
    this.sourceCollections = fetchedCollections ? JSON.parse(JSON.stringify(fetchedCollections)) : {}

    this.hasData = !!fetchedCollections

    if (!fetchedCollections) {
      return
    }

    for (const type in fetchedCollections) {
      const collectionType = <DataCollectionTypeEnum>type
      const fetchedCollection = fetchedCollections[collectionType]
      if (!fetchedCollection) {
        continue
      }

      this.mapCollection(fetchedCollection)
    }
  }

  private async check(): Promise<boolean> {
    const serverHashList = await client.$axios.$get('/data-collections/hashes')

    const localHashList: RequestCheckDataCollectionDto = {}

    for (const type in this.collections) {
      const dataCollection = this.collections[<DataCollectionTypeEnum>type]

      if (!dataCollection) {
        continue
      }

      localHashList[dataCollection.type] = dataCollection.hash
    }

    for (const type in DataCollectionTypeEnum) {
      if (!(type in serverHashList) || !(type in localHashList)) {
        return false
      }

      if (serverHashList[<DataCollectionTypeEnum>type] !== localHashList[<DataCollectionTypeEnum>type]) {
        return false
      }
    }

    return true
  }

  private mapCollection(collectionData: DataCollectionDto) {
    const collection = this.collections[collectionData.type]

    if (!collection) {
      return
    }

    collection.hash = collectionData.hash
    collection.type = collectionData.type
    collection.items.splice(0, collection.items.length, ...collectionData.items)
    collection.maps = { id: {} }

    if (!collection.items.length) {
      return
    }

    let fieldsForMap = ['id']
    let itemIdField = 'id'

    try {
      if (dataCollectionMappingOptions[collection.type]) {
        fieldsForMap = fieldsForMap.concat(dataCollectionMappingOptions[collection.type]!.mappingFields || [])
        itemIdField = dataCollectionMappingOptions[collection.type]!.idField || 'id'
      }

      for (const fieldForMap of fieldsForMap) {
        if (!(fieldForMap in collection.items[0]) || typeof collection.items[0][fieldForMap] === typeof {}) {
          const errorMessage = `${fieldForMap} does not exist in ${collection.type} collection. ${JsonService.stringify(collection.items[0])}`
          const error = new Error(errorMessage)
          Vue.rollbar.error(error)

          throw error
        }

        collection.maps[fieldForMap] = {}
      }

      for (const item of collection.items) {
        collection.maps.id[item.id] = item
        for (const fieldForMap of fieldsForMap) {
          collection.maps[fieldForMap][<string>item[fieldForMap]] = item
        }

        Object.defineProperty(item, this.dataCollectionItemIdFieldKey, {
          value: itemIdField,
          configurable: false,
          enumerable: false,
        })
      }
    } catch (e) {
      if (process.env.rollbarEnvName === 'dev') {
        throw new Error(e.message)
      }

      collection.hash = ''
    }
  }
}

export default new DataCollectionService()
