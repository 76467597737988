import type { DataCollectionMapperOptions } from '~/types/common'
import { DataCollectionTypeEnum } from '~/types/common'
import { characteristicValuesMapper } from '~/mappers/common/characteristic-values.mapper'

export const favoriteListDataCollectionOptions: DataCollectionMapperOptions = {
  'filters.*.category': DataCollectionTypeEnum.category,
  'filters.*.filters.category': DataCollectionTypeEnum.category,
  'filters.*.filters.bodyType': DataCollectionTypeEnum.car_body_type,
  'filters.*.filters.fuelType': DataCollectionTypeEnum.car_fuel_type,
  'filters.*.filters.brandModelGroups.*.model': DataCollectionTypeEnum.car_model,
  'filters.*.filters.brandModelGroups.*.brand': DataCollectionTypeEnum.car_brand,
  'filters.*.filters.colorCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
  'filters.*.filters.checkboxCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
  'filters.*.filters.singleChoiceCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
  'filters.*.filters.multiChoiceCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
  'filters.*.filters.numberCharacteristics.*.characteristic': DataCollectionTypeEnum.characteristic,
  'advertisements.*.category': DataCollectionTypeEnum.category,
  'advertisements.*.model': DataCollectionTypeEnum.car_model,
  'advertisements.*.currency': DataCollectionTypeEnum.currency,
  'advertisements.*.status': DataCollectionTypeEnum.advertisement_status,
  'advertisements.*.bodyType': DataCollectionTypeEnum.car_body_type,
  'advertisements.*.location.city': DataCollectionTypeEnum.city,
  'advertisements.*.characteristicValues': characteristicValuesMapper,
  'advertisements.*.fuelType': DataCollectionTypeEnum.car_fuel_type,
  'advertisements.*.dealer': DataCollectionTypeEnum.dealer,
}
