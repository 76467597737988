import { Component, Emit, Vue } from '~/node_modules/vue-property-decorator'
import type { ModalComponent } from '~/types/common'

@Component
export default class ModalAbstractComponent extends Vue implements ModalComponent {
  isShow: boolean = false

  @Emit('showed')
  show(): void {
    this.isShow = true
  }

  @Emit('hidden')
  hide(): void {
    this.isShow = false
  }

  toggle() {
    if (this.isShow) {
      this.hide()

      return
    }

    this.show()
  }

  detectEscape(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      this.hide()
    }
  }

  mounted() {
    window.addEventListener('keydown', this.detectEscape)
  }

  destroyed() {
    window.removeEventListener('keydown', this.detectEscape)
  }
}
