import Vue from 'vue'
import VueGtm from 'vue-gtm'

const analyticsEnabled = process.env.analyticsEnabled || false

// Google Tag Manager
Vue.use(VueGtm, {
  id: process.env.googleTagManagerContainerId,
  trackOnNextTick: true,
  defer: true,
  enabled: analyticsEnabled,
  loadScript: analyticsEnabled,
  ignoredViews: ['admin'],
})
