import type {
  CheckboxCharacteristicDto,
  FormBaseCharacteristicDto,
} from '~/types/admin'
import {
  emptyFormBaseCharacteristic,
  mapBaseCharacteristicToForm,
} from '~/types/admin'
import type { SectionDto } from '~/types/common'
import { CharacteristicTypesEnum } from '~/types/common'

export interface FormCheckboxCharacteristicDto extends FormBaseCharacteristicDto {
  falseLabel: string | null
  trueLabel: string | null
}

export function emptyFormCheckboxCharacteristic(section: SectionDto): FormCheckboxCharacteristicDto {
  return {
    ...emptyFormBaseCharacteristic(CharacteristicTypesEnum.checkbox, section),
    falseLabel: null,
    trueLabel: null,
  }
}

export function mapCheckboxCharacteristicToForm(characteristic: CheckboxCharacteristicDto): FormCheckboxCharacteristicDto {
  return {
    ...mapBaseCharacteristicToForm(characteristic),
    falseLabel: characteristic.falseLabel || null,
    trueLabel: characteristic.trueLabel || null,
  }
}
