




















import { Vue, Component } from 'vue-property-decorator'
import { ButtonTypeEnum } from '~/types/client'
import VBtn from '~/components/client/ui/buttons/v-btn.vue'

@Component({
  components: {
    VBtn,
  },
  beforeMount() {
    if (process.client) {
      // eslint-disable-next-line no-restricted-properties
      window.location.href = `${process.env.frontendUrl}/404`
    }
  },
})
export default class ClientError404 extends Vue {
  readonly buttonTypes = ButtonTypeEnum
}
