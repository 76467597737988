import type { ActionTree, ActionContext, MutationTree, GetterTree } from 'vuex'
import { namespace } from '~/node_modules/vuex-class'
import type { RecentlyViewedState, BrandDto, ModelDto, RawRecentlyViewedBrandModelDto } from '~/types/client'
import { emptyRecentlyViewedState } from '~/types/client'
import type { RootState } from '~/types/common'
import RecentlyViewedBrandModelDataService from '~/services/client/recently-viewed-brand-model-data.service'
import { DataCollectionTypeEnum } from '~/types/common'
import { mapFromDataCollection } from '~/mappers/common/map-from-data-collection.mapper'

export const state = (): RecentlyViewedState => emptyRecentlyViewedState()

export const actions: ActionTree<RecentlyViewedState, RootState> = {
  loadRecentlyViewedBrandModel({ commit }: ActionContext<RecentlyViewedState, RootState>) {
    commit('setRecentlyViewedBrandModel', RecentlyViewedBrandModelDataService.getDataFromStorage())
  },
}

export const mutations: MutationTree<RecentlyViewedState> = {
  setRecentlyViewedBrandModel(state: RecentlyViewedState, data: RawRecentlyViewedBrandModelDto[]) {
    state.recentlyViewedBrandModel = data
  },
  addBrandModel(state: RecentlyViewedState, { brand, model, limit }: {brand: string, model: string | null, limit: number}) {
    const storedBrandIndex = state.recentlyViewedBrandModel.findIndex(item => item.brand === brand)

    let storedBrand
    if (storedBrandIndex < 0) {
      storedBrand = { brand, models: [] }
    } else {
      [storedBrand] = state.recentlyViewedBrandModel.splice(storedBrandIndex, 1)
    }

    state.recentlyViewedBrandModel.push(storedBrand)

    if (state.recentlyViewedBrandModel.length > limit) {
      state.recentlyViewedBrandModel.shift()
    }

    if (!model) {
      return
    }

    const storeModelIndex = storedBrand.models.indexOf(model)

    if (storeModelIndex >= 0) {
      storedBrand.models.splice(storeModelIndex, 1)
    }

    storedBrand.models.push(model)
    if (storedBrand.models.length > limit) {
      storedBrand.models.shift()
    }
  },
}

export const getters: GetterTree<RecentlyViewedState, RootState> = {
  recentlyViewedBrands(state: RecentlyViewedState): BrandDto[] {
    const mappedItems = mapFromDataCollection<BrandDto[]>(state.recentlyViewedBrandModel.map(item => item.brand), DataCollectionTypeEnum.car_brand) as (BrandDto | undefined)[]

    return mappedItems.filter(item => Boolean(item)) as BrandDto[]
  },
  recentlyViewedModels(state: RecentlyViewedState): ModelDto[] {
    const mappedItems = mapFromDataCollection<ModelDto[]>(state.recentlyViewedBrandModel.map(item => item.models).flat(), DataCollectionTypeEnum.car_model) as (ModelDto | undefined)[]

    return mappedItems.filter(item => Boolean(item)) as ModelDto[]
  },
}

export const ClientRecentlyViewedModule = namespace('client/recently-viewed')
