import type {
  BaseCharacteristicDto,
  BodyTypeDto,
  BrandDto,
  CarCategoryDto, ClientFullCarDto,
  GenerationDto,
  ModelDto,
  ModificationDto,
  RequestCarDto,
  SerieDto,
} from '~/types/client'
import {
  emptyFormImageListDto, mapFormImageListDto,
} from '~/types/client'
import type {
  CarMarksDto,
  CharacteristicValueListDto,
  CurrencyDto,
  FormImageListDto,
  FormVideoDto,
  RequestLocationDto,
  WithVinPlateNumber,
  EquipmentDto,
  FuelTypeDto,
  StatusDto,
  CarRegionDto,
  CityDto, FormFileDto,
} from '~/types/common'
import {
  emptyCharacteristicValueListDto,
  emptyFormVideo,
  emptyRequestLocation,
  mapCharacteristicValueLists,
  mapCharacteristicValueListToRequest,
  mapFormImageListToRequest,
  mapFormVideoToRequest,
  mapLocationToRequest,
  mapVideoToFormVideo,
  getEmptyCarMarks,
  CharacteristicSectionModeEnum, emptyFormFile, mapFileToForm,
} from '~/types/common'
import { isLocationEmpty } from '~/plugins/is-location-empty'
import type { PdfDto } from '~/types/client/car/pdf.dto'

export interface FormCarDto extends WithVinPlateNumber {
    fuelType: FuelTypeDto | null
    status: StatusDto | null
    editMode: boolean
    dealerRole?: string
    dealerId?: string | null
    category: CarCategoryDto | null
    bodyType: BodyTypeDto | null
    brand: BrandDto | null
    model: ModelDto | null
    equipment: EquipmentDto | null
    year: number | null
    isNew: boolean
    price: number | null
    avgPrice: number | null
    currency: CurrencyDto | null
    mileage: number | null
    hotDeal: boolean
    featureValues: CharacteristicValueListDto[]
    description: string | null
    characteristicValues: CharacteristicValueListDto[]
    imageList: FormImageListDto
    video: FormVideoDto
    location: RequestLocationDto | null
    marks: CarMarksDto
    generation: GenerationDto | null
    serie: SerieDto | null
    modification: ModificationDto | null
    region: CarRegionDto | null
    city: CityDto | null
    certificate?: FormFileDto,
    pdf?: PdfDto | null
    certificationTerm?: string | null
}

export function emptyFormCarDto(features: BaseCharacteristicDto[], category: CarCategoryDto | null = null): FormCarDto {
  return {
    category,
    fuelType: null,
    status: null,
    editMode: false,
    bodyType: null,
    brand: null,
    model: null,
    equipment: null,
    year: null,
    isNew: false,
    hotDeal: false,
    price: null,
    avgPrice: null,
    currency: null,
    mileage: null,
    featureValues: features.map(emptyCharacteristicValueListDto),
    description: null,
    location: emptyRequestLocation(),
    imageList: emptyFormImageListDto(),
    characteristicValues: [],
    video: emptyFormVideo(),
    marks: getEmptyCarMarks(),
    generation: null,
    serie: null,
    modification: null,
    plateNumber: null,
    vinCode: null,
    region: null,
    city: null,
    certificate: emptyFormFile(),
    pdf: null,
    certificationTerm: null,
  }
}

export function mapCarToForm(car: ClientFullCarDto, features: BaseCharacteristicDto[], generations?: GenerationDto[]): FormCarDto {
  const characteristicValues = car.category.characteristicList.map(emptyCharacteristicValueListDto)
  const characteristicsWithoutFeatureMode = car.characteristicValues.filter(item => item.characteristic.section.mode !== CharacteristicSectionModeEnum.FEATURE)
  mapCharacteristicValueLists(characteristicValues, characteristicsWithoutFeatureMode)

  const featureValues = features.map(emptyCharacteristicValueListDto)
  const carFeatureCharacteristics = car.characteristicValues.filter(item => item.characteristic.section.mode === CharacteristicSectionModeEnum.FEATURE)
  mapCharacteristicValueLists(featureValues, carFeatureCharacteristics)

  const currentModification = car.equipment && car.equipment.modification ? car.equipment.modification : null
  const currentSerie = currentModification && currentModification.serie ? currentModification.serie : null
  const currentGenerationId = currentModification && currentModification.serie && currentModification.serie.generation ? currentModification.serie.generation.id : null

  const generation = generations && generations.length ? generations.find(generation => currentGenerationId && generation.id === currentGenerationId) || null : null
  const serie = generation ? generation.series.find(serie => currentSerie && serie.id === currentSerie.id) || null : null
  const modification = serie ? serie.modifications.find(modification => currentModification && modification.id === currentModification.id) || null : null
  const equipment = modification ? modification.equipments.find(equipment => car.equipment && equipment.id === car.equipment.id) || null : null

  return {
    fuelType: car.fuelType,
    status: car.status,
    vinCode: car.vinCode,
    plateNumber: car.plateNumber,
    editMode: true,
    characteristicValues,
    dealerRole: car.dealerRole ? car.dealerRole.id : undefined,
    dealerId: car.dealerRole ? car.dealerRole.dealer.id : null,
    bodyType: car.bodyType,
    brand: car.model.brand,
    category: car.category,
    model: car.model,
    equipment,
    year: car.year,
    isNew: car.isNew,
    hotDeal: car.hotDeal,
    price: car.price / 100,
    currency: car.currency,
    mileage: car.mileage !== undefined ? car.mileage : null,
    featureValues,
    description: car.description || null,
    imageList: mapFormImageListDto(car.imageList),
    video: car.video ? mapVideoToFormVideo(car.video) : emptyFormVideo(),
    marks: { ...car.marks },
    location: mapLocationToRequest(car.location),
    generation,
    serie,
    modification,
    avgPrice: null,
    region: car.location && car.location.city && car.location.city.region ? car.location.city.region : null,
    city: car.location && car.location.city ? car.location.city : null,
    certificate: mapFileToForm(car.certificate),
    pdf: car.pdf,
    certificationTerm: car.certificationTerm,
  }
}

export function mapFromCarToRequest(formData: FormCarDto): RequestCarDto {
  return {
    fuelType: formData.fuelType!.id,
    dealerRole: formData.dealerRole,
    vinCode: formData.vinCode || undefined,
    plateNumber: formData.plateNumber || undefined,
    category: formData.category!.id,
    bodyType: formData.bodyType!.id,
    model: formData.model!.id,
    equipment: formData.equipment ? formData.equipment.id : null,
    year: formData.year!,
    price: formData.price! * 100,
    currency: formData.currency!.code,
    mileage: formData.mileage !== null ? formData.mileage : undefined,
    isNew: formData.isNew,
    hotDeal: formData.hotDeal,
    video: formData.video ? mapFormVideoToRequest(formData.video) : undefined,
    characteristicValues: formData.isNew
      ? mapCharacteristicValueListToRequest(formData.characteristicValues)
      : { ...mapCharacteristicValueListToRequest(formData.characteristicValues), ...mapCharacteristicValueListToRequest(formData.featureValues.filter(item => item.value)) },
    imageList: mapFormImageListToRequest(formData.imageList),
    location: !isLocationEmpty(formData.location) ? formData.location! : undefined,
    description: formData.description || null,
    marks: {
      testDriveAbility: formData.marks.testDriveAbility,
      trade: formData.marks.trade,
      bidding: formData.marks.bidding,
      showVinCode: formData.marks.showVinCode,
      showPlateNumber: formData.marks.showPlateNumber,
      availableInDealer: formData.marks.availableInDealer,
    },
    certificate: formData.certificate,
    pdf: formData.pdf!,
    certificationTerm: formData.certificationTerm,
  }
}
