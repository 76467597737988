import type { Plugin } from '@nuxt/types'
import type {
  CharacteristicValueListDto,
  ColorCharacteristicDto,
  CurrencyDto,
  NumberCharacteristicDto,
} from '~/types/common'
import type { FullCarDto } from '~/types/client'
import { CharacteristicCode } from '~/types/common/characteristic/characteristic-code.enum'

declare module 'vue/types/vue' {
  interface Vue {
    $carSeoDescriptionFormatter(car: FullCarDto, currency: CurrencyDto): string,
  }
}

const carSeoDescriptionFormatter: Plugin = function (context, inject) {
  inject('carSeoDescriptionFormatter', (car: FullCarDto, currency: CurrencyDto) => {
    const colorCharacteristicValue = car.characteristicValues
      .find(value => value.characteristic.code === CharacteristicCode.COLOR) as CharacteristicValueListDto<ColorCharacteristicDto>
    const engineCharacteristicValue = car.characteristicValues
      .find(value => value.characteristic.code === CharacteristicCode.ENGINE_VOLUME) as CharacteristicValueListDto<NumberCharacteristicDto>

    const color = colorCharacteristicValue && colorCharacteristicValue.value ? colorCharacteristicValue.value.name : ''
    const engine = engineCharacteristicValue && engineCharacteristicValue.value
      ? `${engineCharacteristicValue.value} ${engineCharacteristicValue.characteristic.unit}`.toLocaleLowerCase()
      : ''
    const fuelType = car.fuelType.name.toLocaleLowerCase()

    const params = {
      brand: car.model.brand.name,
      model: car.model.name,
      bodyType: car.bodyType.name,
      year: car.year,
      city: car.location ? car.location.city.name : '',
      color,
      engine,
      fuelType,
      price: context.app.$currencyFormatter(car.price, car.currency, currency),
      mileage: car.mileage ? `${car.mileage} ${context.app.i18n.t('kilometers')}` : null,
    }

    return car.isNew
      ? context.app.i18n.t('meta-data.new-car-description', params)
      : context.app.i18n.t('meta-data.used-car-description', params)
  })
}

export default carSeoDescriptionFormatter
