import { emptyFilterNumberRangeDto } from '~/types/common'
import type { NumberRangeDto, TagDto } from '~/types/common'

export interface SelectedFilterOptionsNewsDto {
  tags: TagDto[],
  author: string,
  publishedAt: NumberRangeDto,
  content: string,
}

export function emptySelectedFilterOptionsNewsDto(): SelectedFilterOptionsNewsDto {
  return {
    tags: [],
    author: '',
    publishedAt: emptyFilterNumberRangeDto(),
    content: '',
  }
}
