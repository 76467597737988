import JsonService from '~/services/common/json.service'
import type { UnknownObject } from '~/types/common'
import type { StorageContract } from '~/services/common/storage/storage.contract'

/** TODO: Deprecated - look into nuxt/modules/common/storage */

class DynamicStorageService implements StorageContract {
  get<T = unknown>(key: string): T | null {
    const windowData = this.read()

    return windowData[key] as T || null
  }

  set(key: string, value: unknown): void {
    const windowData = this.read()
    windowData[key] = value
    this.write(windowData)
  }

  remove(key: string): void {
    const windowData = this.read()
    delete windowData[key]
    this.write(windowData)
  }

  getKeysByRegExp(keyExpression: RegExp): string[] {
    const windowData = this.read()
    const keys = Object.keys(windowData)

    return keys.filter(item => keyExpression.test(item))
  }

  getSize(key: string): number {
    const windowData = this.read()

    return (JsonService.stringify(windowData[key]).length + key.length) * 2 / 1024
  }

  private read(): UnknownObject {
    const windowData = window.name ? JsonService.parse(window.name) : {}

    if (!windowData) {
      return {}
    }

    return windowData as UnknownObject
  }

  private write(value: UnknownObject): void {
    window.name = JsonService.stringify(value)
  }
}

export default new DynamicStorageService()
