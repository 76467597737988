import { OnlyInstantiableByContainer, Singleton } from 'typescript-ioc'
import { DataCollectionGetter } from '~/decorators/data-collection-getter.decorator'
import { DataCollectionTypeEnum } from '~/types/common'
import type { CurrencyDto } from '~/types/common'

@Singleton
@OnlyInstantiableByContainer
export class CurrencyService {
  @DataCollectionGetter(DataCollectionTypeEnum.currency)
  readonly currencies!: CurrencyDto[]

  get defaultCurrency(): CurrencyDto {
    return this.currencies.find(currency => currency.default) || this.currencies[0]
  }

  get secondaryCurrencies(): CurrencyDto[] {
    return this.currencies.filter(currency => !currency.default)
  }

  get usdCurrency(): CurrencyDto | null {
    return this.getCurrencyByCode('usd')
  }

  get eurCurrency(): CurrencyDto | null {
    return this.getCurrencyByCode('eur')
  }

  get uahCurrency(): CurrencyDto | null {
    return this.getCurrencyByCode('uah')
  }

  getCurrencyByCode(code: string): CurrencyDto | null {
    return this.currencies.find(currency => currency.code === code) || null
  }
}
