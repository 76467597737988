import type { AuthenticateProviderOptions } from '~/plugins/authenticate/type'

export const socialConnectProviders: AuthenticateProviderOptions = {
  facebook: {
    scope: ['email', 'public_profile'],
    url: '/secure/profile/social/facebook',
    authorizationEndpoint: 'https://www.facebook.com/v2.6/dialog/oauth',
    clientId: process.env.facebookId,
  },
  google: {
    clientId: process.env.googleId,
    url: '/secure/profile/social/google',
  },
}
