import type { SelectOptionDto } from '~/types/common'

export interface SelectedOptionsUserDto {
  regions: SelectOptionDto[]
  cities: SelectOptionDto[]
  roles: SelectOptionDto[]
  active: boolean[]
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
}

export function emptySelectedOptionsUser() {
  return {
    regions: [],
    cities: [],
    roles: [],
    active: [],
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
  }
}
