


















import { Component, Vue } from 'vue-property-decorator'
import AppHeader from '~/components/admin/headers/header.vue'
import Sidebar from '~/components/admin/sidebar.vue'
import { closedIndexMeta } from '~/configs/client/robots-meta'

@Component({
  components: {
    AppHeader,
    Sidebar,
  },
  head(this: AdminLayout) {
    return {
      meta: [
        closedIndexMeta,
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
})
export default class AdminLayout extends Vue {
  $i18n: any
}
