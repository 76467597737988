import { mapFormFileToRequest } from '~/types/common'
import type { FormDealerDto, RequestFileDto, RequestLocationDto } from '~/types/common'
import type { DealerTypeEnum } from '~/types/common/dealer/dealer-type.enum'

export interface RequestDealerDto {
  email?: string
  legalEntityCode?: string
  name?: string
  website?: string
  multibrandLogo?: RequestFileDto | null | undefined
  logo?: RequestFileDto | null | undefined
  bannerImage?: RequestFileDto | null | undefined
  phone?: string
  location?: RequestLocationDto
  activities?: string[]
  type?: DealerTypeEnum
  description: string | null
}

export function mapFormDealerToRequest(formDealer: FormDealerDto): RequestDealerDto {
  return {
    email: formDealer.email || undefined,
    legalEntityCode: formDealer.legalEntityCode || undefined,
    name: formDealer.name || undefined,
    website: typeof formDealer.website === 'string' ? formDealer.website : undefined,
    multibrandLogo: mapFormFileToRequest(formDealer.multibrandLogo),
    logo: mapFormFileToRequest(formDealer.logo),
    bannerImage: mapFormFileToRequest(formDealer.bannerImage),
    phone: formDealer.phone || undefined,
    location: formDealer.location || undefined,
    activities: formDealer.activities,
    type: formDealer.type || undefined,
    description: formDealer.description || null,
  }
}
