

























import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import { Colors, iconSizes } from '~/configs/client'
import { ButtonTypeEnum } from '~/types/client'
import VBtn from '~/components/client/ui/buttons/v-btn.vue'

@Component({
  components: { VBtn },
})
export default class VModalSmall extends Vue {
  readonly colors = Colors
  readonly iconSizes = iconSizes
  readonly buttonTypes = ButtonTypeEnum

  @Prop({ required: true })
  readonly value!: boolean

  @Prop({ default: null })
  readonly containerClass!: string | null

  destroyed() {
    this.$isAllowBodyScroll(true)
  }

  @Watch('value')
  onValueChange(newValue: boolean) {
    this.$isAllowBodyScroll(!newValue)
  }

  @Emit()
  input() {
    return false
  }
}
