import type { Plugin } from '@nuxt/types'
import type { CharacteristicValueListDto } from '~/types/common'
import type { ParseCharacteristicValueStrategyInterface } from '~/plugins/formatter/characteristic-value/parse-characteristic-value-strategy/parse-characteristic-value-strategy.interface'
import {
  ParseCheckboxCharacteristicValueStrategy,
  ParseColorCharacteristicValueStrategy,
  ParseMultiChoiceCharacteristicValueStrategy,
  ParseNumberCharacteristicValueStrategy,
  ParseSingleChoiceCharacteristicValueStrategy,
  ParseTextCharacteristicValueStrategy,
} from '~/plugins/formatter/characteristic-value/parse-characteristic-value-strategy'

const parseCharacteristicValueStrategies: ParseCharacteristicValueStrategyInterface[] = [
  ParseTextCharacteristicValueStrategy,
  ParseNumberCharacteristicValueStrategy,
  ParseCheckboxCharacteristicValueStrategy,
  ParseColorCharacteristicValueStrategy,
  ParseMultiChoiceCharacteristicValueStrategy,
  ParseSingleChoiceCharacteristicValueStrategy,
]

declare module 'vue/types/vue' {
  interface Vue {
    $characteristicValueFormatter(item: CharacteristicValueListDto): string,
  }
}

const characteristicValueFormatter: Plugin = function (_context, inject) {
  inject('characteristicValueFormatter', (item: CharacteristicValueListDto) => {
    if (!item.value) {
      return '--'
    }

    const strategy = parseCharacteristicValueStrategies.find(strategy => strategy.support(item.characteristic.dtype))

    if (!strategy) {
      return '--'
    }

    return strategy.parse(item)
  })
}

export default characteristicValueFormatter
