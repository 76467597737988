import type { BodyTypeDto, ModelDto, RegionDto, SalesLeaderTypeEnum } from '~/types/common'
import type { RequestSalesLeaderDto, SalesLeaderDto } from '~/types/admin'

export interface FormSalesLeaderDto {
  type: SalesLeaderTypeEnum | null
  model: ModelDto | null
  bodyType: BodyTypeDto | null
  region: RegionDto | null
  year: number | null
  avgPrice: number | null,
}

export function emptyFormSalesLeader(): FormSalesLeaderDto {
  return {
    bodyType: null,
    model: null,
    region: null,
    type: null,
    year: null,
    avgPrice: null,
  }
}

export function mapSalesLeaderToForm(salesLeader: SalesLeaderDto): FormSalesLeaderDto {
  return {
    bodyType: salesLeader.bodyType || null,
    model: salesLeader.model,
    region: salesLeader.region || null,
    type: salesLeader.type,
    year: salesLeader.year || null,
    avgPrice: salesLeader.avgPrice ? Math.round(salesLeader.avgPrice / 100) : null,
  }
}

export function mapFormSalesLeaderToRequest(formData: FormSalesLeaderDto): RequestSalesLeaderDto {
  return {
    bodyType: formData.bodyType ? formData.bodyType.id : undefined,
    model: formData.model!.id,
    region: formData.region ? formData.region.id : undefined,
    type: formData.type!,
    year: formData.year || undefined,
    avgPrice: formData.avgPrice ? formData.avgPrice * 100 : undefined,
  }
}
