import type { RawRecentlyViewedBrandModelDto } from '~/types/client'

export interface RecentlyViewedState {
  recentlyViewedBrandModel: RawRecentlyViewedBrandModelDto[]
}

export function emptyRecentlyViewedState(): RecentlyViewedState {
  return {
    recentlyViewedBrandModel: [],
  }
}
