import type { Plugin } from '@nuxt/types'

declare module 'vue/types/vue' {
  interface Vue {
    $proxyUrlFormatter(path: string | null): string,
  }
}

const proxyUrlFormatter: Plugin = function (_context, inject) {
  inject('proxyUrlFormatter', (path: string | null) => {
    return path ? `${process.env.backendUrl}/proxy/${encodeURIComponent(path)}` : null
  })
}

export default proxyUrlFormatter
