export abstract class SlugServiceAbstract {
  abstract create(data: unknown): string

  parseId(slug?: string): string | null {
    if (!slug) {
      return null
    }

    const idMatch = slug.match(/[0-9A-Za-z]{8}-[0-9A-Za-z]{4}-4[0-9A-Za-z]{3}-[89ABab][0-9A-Za-z]{3}-[0-9A-Za-z]{12}$/g)

    return idMatch ? idMatch[0] : null
  }
}
