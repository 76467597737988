import Vue from 'vue'
import type Rollbar from 'rollbar'

// @ts-ignore
import VueRollbar from 'vue-rollbar'

declare module 'vue/types/vue' {
  interface Vue {
    $rollbar: Rollbar,
  }

  interface VueConstructor {
    rollbar: Rollbar
  }
}

Vue.use(VueRollbar, {
  accessToken: process.env.rollbarToken,
  enabled: process.env.rollbarEnabled,
  environment: process.env.rollbarEnvName,
  payload: {
    client: {
      javascript: {
        code_version: process.env.rollbarVersion,
      },
    },
  },
} as Rollbar.Configuration)

Vue.config.errorHandler = (error) => {
  Vue.rollbar.error(error)
  throw error
}
