import type {
  ChoiceCharacteristicItemDto,
  FormChoiceCharacteristicDto,
  RequestBaseCharacteristicDto,
} from '~/types/admin'
import {
  mapFormBaseCharacteristicToRequest,
} from '~/types/admin'
import type { Dictionary } from '~/types/common'

export interface RequestChoiceCharacteristicDto extends RequestBaseCharacteristicDto {
  values: Dictionary<ChoiceCharacteristicItemDto>
}

export function mapFormChoiceCharacteristicToRequest(formData: FormChoiceCharacteristicDto): RequestChoiceCharacteristicDto {
  const values: Dictionary<ChoiceCharacteristicItemDto> = {}

  let newValueIndex = 0
  for (const value of formData.values) {
    values[value.id || (newValueIndex++).toString()] = value
  }

  return {
    ...mapFormBaseCharacteristicToRequest(formData),
    values,
  }
}
