import type { Plugin } from '@nuxt/types'
import numeral from 'numeral'

declare module 'vue/types/vue' {
  interface Vue {
    $priceFormatter(priceStringOrNumber: string | number): string,
  }
}

export function formatPrice(price: number): string {
  numeral.localeData().delimiters.thousands = ' '

  return numeral(price).format('0,0[.]00')
}

const priceFormatter: Plugin = function (_context, inject) {
  inject('priceFormatter', formatPrice)
}

export default priceFormatter
