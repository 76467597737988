import type { Plugin } from '@nuxt/types'
import type { BeforeInstallPromptEvent } from '~/before-Install-prompt-event'

declare module 'vue/types/vue' {
  interface Vue {
    $beforeInstallPromptEvent: Promise<BeforeInstallPromptEvent | null>
  }
}

const beforeInstallPromptEventPlugin: Plugin = function (_context, inject) {
  inject('beforeInstallPromptEvent', new Promise<BeforeInstallPromptEvent | null>((resolve) => {
    const timer = setTimeout(() => {
      resolve(null)
    }, 60000)

    window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
      resolve(event)
      clearTimeout(timer)
      event.preventDefault()
    })
  }))
}

export default beforeInstallPromptEventPlugin
