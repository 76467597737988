import type { BrandDto, RequestBrandDto } from '~/types/admin'

export interface FormBrandDto {
  id: string | null
  salesLeader: boolean
  name: string
  viewsCheatCoefficient: number | null
  aliases: string[]
}

export function emptyFormBrandDto(): FormBrandDto {
  return {
    id: null,
    salesLeader: false,
    name: '',
    viewsCheatCoefficient: null,
    aliases: [],
  }
}

export function mapBrandToFormDto(brand: BrandDto): FormBrandDto {
  return {
    id: brand.id,
    salesLeader: brand.salesLeader,
    name: brand.name,
    viewsCheatCoefficient: brand.viewsCheatCoefficient || null,
    aliases: [...brand.aliases],
  }
}

export function mapBrandFormToRequestDto(form: FormBrandDto): RequestBrandDto {
  return {
    salesLeader: form.salesLeader,
    name: form.name,
    viewsCheatCoefficient: form.viewsCheatCoefficient || undefined,
    aliases: [...form.aliases],
  }
}
