import type { RequestFileDto } from '~/types/common'
import { mapFormFileToRequest } from '~/types/common'
import type { FormMarketirsementDto } from '~/types/admin'

export interface RequestMarketirsementDto {
  image?: RequestFileDto | null
  link: string
}

export function mapFormMarketirsementToRequestDto(marketirsement: FormMarketirsementDto): RequestMarketirsementDto | undefined {
  return {
    image: marketirsement.image.newFile || marketirsement.image.file ? mapFormFileToRequest(marketirsement.image) : null,
    link: marketirsement.link,
  }
}
