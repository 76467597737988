import type { CarDto } from '~/types/client'
import type { FilterablePayloadDto } from '~/types/common'

export type CarSearchResultDto = {
  items: {
    [page: number]: CarDto[]
  }
  total: number
  query: FilterablePayloadDto
}

export function emptyCarSearchResult(): CarSearchResultDto {
  return {
    items: {},
    total: 0,
    query: {},
  }
}
