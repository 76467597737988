import type {
  BaseCharacteristicDto,
  CharacteristicDto,
  NumberCharacteristicDto,
} from '~/types/common'
import {
  CharacteristicTypesEnum,
} from '~/types/common'

export function isNumberCharacteristic<T extends BaseCharacteristicDto = BaseCharacteristicDto>(characteristic: CharacteristicDto<T>): characteristic is NumberCharacteristicDto<T> {
  return characteristic.dtype === CharacteristicTypesEnum.number
}
