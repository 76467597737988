import type {
  ColorCharacteristicItemDto,
  FormColorCharacteristicDto,
  RequestBaseCharacteristicDto,
} from '~/types/admin'
import {
  mapFormBaseCharacteristicToRequest,
} from '~/types/admin'
import type { Dictionary } from '~/types/common'

export interface RequestColorCharacteristicDto extends RequestBaseCharacteristicDto {
  values: Dictionary<ColorCharacteristicItemDto>
}

export function mapFormColorCharacteristicToRequest(formData: FormColorCharacteristicDto): RequestColorCharacteristicDto {
  const values: Dictionary<ColorCharacteristicItemDto> = {}

  let newValueIndex = 0
  for (const value of formData.values) {
    values[value.id || (newValueIndex++).toString()] = value
  }

  return {
    ...mapFormBaseCharacteristicToRequest(formData),
    values,
  }
}
