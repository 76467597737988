import type { Store } from '~/node_modules/vuex'
import type { RootState } from '~/types/common'

class StoreClient {
  private _store!: Store<RootState>

  setStore(store: Store<RootState>) {
    this._store = store
  }

  get $store() {
    return this._store
  }
}

const store = new StoreClient()

export { store }
