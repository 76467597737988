import type {
  CreateBaseContentBlockDto,
  UpdateBaseContentBlockDto,
  TextContentBlockDto,
} from '~/types/common'
import {
  ContentBlockTypeEnum,
} from '~/types/common'

export type FormTextContentBlockDto<T extends CreateBaseContentBlockDto = CreateBaseContentBlockDto> = T & {
  text: string
  type: ContentBlockTypeEnum.TEXT
}

export function emptyFormTextContentBlockDto(): FormTextContentBlockDto {
  return {
    text: '',
    type: ContentBlockTypeEnum.TEXT,
  }
}

export function mapTextContentBlockToUpdateFormDto(contentBlock: TextContentBlockDto): FormTextContentBlockDto<UpdateBaseContentBlockDto> {
  return {
    id: contentBlock.id,
    type: ContentBlockTypeEnum.TEXT,
    position: contentBlock.position,
    text: contentBlock.text,
  }
}
