import type { FinancialServiceActivityEnum, FormFileDto } from '~/types/common'
import { emptyFormFile, mapFileToForm } from '~/types/common'
import type { FinancialServiceDto } from '~/types/admin'

export interface FormFinancialServiceDto {
  name: string | null
  url: string | null
  position?: number
  disabled: boolean
  logo: FormFileDto
  activities: FinancialServiceActivityEnum[]
}

export function emptyFormFinancialService(): FormFinancialServiceDto {
  return {
    name: null,
    url: null,
    disabled: true,
    logo: emptyFormFile(),
    activities: [],
  }
}

export function mapFinancialServiceToFrom(financialService: FinancialServiceDto): FormFinancialServiceDto {
  return {
    name: financialService.name,
    position: financialService.position,
    url: financialService.url,
    disabled: financialService.disabled,
    activities: financialService.activities,
    logo: mapFileToForm(financialService.logo),
  }
}
