import type {
  FormFileDto, ImageContentBlockDto,
  CreateBaseContentBlockDto, UpdateBaseContentBlockDto, FileDto,
} from '~/types/common'
import {
  ContentBlockTypeEnum, emptyFormFile, mapFileToForm,
} from '~/types/common'

export type FormImageContentBlockDto<T extends CreateBaseContentBlockDto = CreateBaseContentBlockDto> = T & {
  caption: string
  image: FormFileDto
  type: ContentBlockTypeEnum.IMAGE
}

export function emptyFormImageContentBlockDto(): FormImageContentBlockDto {
  return {
    caption: '',
    image: emptyFormFile(),
    type: ContentBlockTypeEnum.IMAGE,
  }
}

export function mapImageContentBlockToUpdateFormDto(contentBlock: ImageContentBlockDto): FormImageContentBlockDto<UpdateBaseContentBlockDto> {
  const image = contentBlock.image as FileDto

  return {
    id: contentBlock.id,
    type: ContentBlockTypeEnum.IMAGE,
    position: contentBlock.position,
    caption: contentBlock.caption,
    image: mapFileToForm(image),
  }
}
