













































import { Component, Vue, Watch } from 'vue-property-decorator'
import { VImgTransitionEnum } from '~/configs/common/v-img-transition.enum'
import type { Dictionary } from '~/types/common'
import AdsBannerComponent from '~/components/client/ads-banner.vue'

@Component({
  components: {
    AdsBannerComponent,
  },
})
export default class ImgScreen extends Vue {
  readonly transitionType = VImgTransitionEnum

  srcList: string[] = []
  srcsetList: string[] = []
  currentImageIndex: number = 0
  closed: boolean = true
  handlers: Dictionary<(params?: unknown) => void> = {}
  imgAnimation: boolean = true
  slideTransitionSide: VImgTransitionEnum = this.transitionType.FADE
  initialX: null | number = null
  initialY: null | number = null

  created() {
    document.addEventListener('touchstart', this.startTouch, false)
    document.addEventListener('touchend', this.moveTouch, false)
    window.addEventListener('keyup', (e) => {
      // esc key and 'q' for quit
      if (e.keyCode === 27 || e.keyCode === 81) {
        this.close()
      }
      // arrow right and 'l' key (vim-like binding)
      if (e.keyCode === 39 || e.keyCode === 76) {
        this.next()
      }
      // arrow left and 'h' key (vim-like binding)
      if (e.keyCode === 37 || e.keyCode === 72) {
        this.prev()
      }
    })
    window.addEventListener('scroll', () => {
      this.close()
    })
  }

  beforeDestroy() {
    document.removeEventListener('touchstart', this.startTouch, false)
    document.removeEventListener('touchend', this.moveTouch, false)
  }

  @Watch('currentImageIndex')
  onCurrentImageIndexChanged() {
    this.imgAnimation = false
    setTimeout(() => {
      this.imgAnimation = true
    }, 50)
  }

  @Watch('closed')
  onClosedChange(newVal: boolean) {
    if (newVal && this.handlers.closed) {
      this.handlers.closed()
    }
    if (!newVal && this.handlers.opened) {
      this.handlers.opened()
    }
  }

  fireChangeEvent() {
    if (this.handlers.changed) {
      this.handlers.changed(this.currentImageIndex)
    }
  }

  close() {
    if (this.closed) {
      return
    }

    document.querySelector('body')!.classList.remove('body-fs-v-img')
    this.srcList = []
    this.srcsetList = []
    this.currentImageIndex = 0
    this.closed = true
    this.slideTransitionSide = this.transitionType.FADE
  }

  open() {
    this.closed = false
  }

  next() {
    if (!this.closed && this.srcList.length > 1) {
      // if next index not exists in array of srcList, set index to first element
      if (this.currentImageIndex + 1 < this.srcList.length) {
        this.currentImageIndex++
      } else {
        this.currentImageIndex = 0
      }
      this.slideTransitionSide = this.transitionType.SLIDE_NEXT
      this.fireChangeEvent()
    }
  }

  select(selectedImage: number) {
    this.currentImageIndex = selectedImage
  }

  prev() {
    if (!this.closed && this.srcList.length > 1) {
      // if prev index not exists in array of images, set index to last element
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--
      } else {
        this.currentImageIndex = this.srcList.length - 1
      }
      this.slideTransitionSide = this.transitionType.SLIDE_PREV
      this.fireChangeEvent()
    }
  }

  startTouch(e: TouchEvent) {
    const target = e.target as HTMLElement

    if (!target || target.tagName !== 'IMG' || !target.parentElement || target.parentElement.className !== 'content-v-img') {
      this.initialX = null
      this.initialY = null

      return
    }
    this.initialX = e.changedTouches[0].screenX
    this.initialY = e.changedTouches[0].screenY
  }

  moveTouch(e: TouchEvent) {
    const target = e.target as HTMLElement

    if (!target || target.tagName !== 'IMG' || !target.parentElement || target.parentElement.className !== 'content-v-img') {
      return
    }

    if (this.initialX === null || this.initialY === null) {
      return
    }

    const currentX = e.changedTouches[0].screenX
    const currentY = e.changedTouches[0].screenY
    const diffX = this.initialX - currentX
    const diffY = this.initialY - currentY
    const ratioX = Math.abs(diffX / diffY)
    const ratioY = Math.abs(diffY / diffX)
    const absDiff = Math.abs(ratioX > ratioY ? diffX : diffY)

    if (absDiff < 30) {
      return
    }

    if (ratioX < ratioY || !window.vueImg) {
      return
    }
    if (diffX >= 0) {
      window.vueImg.next()

      return
    }
    window.vueImg.prev()

    this.initialX = null
    this.initialY = null

    e.preventDefault()
  }
}
