export enum RadioTypeEnum {
  RADIO_TEXT = 'radio-text',
  RADIO_TEXT_RED = 'radio-text radio-text--red',
  RADIO_TEXT_GREEN = 'radio-text radio-text--green',
  RADIO_COLOR = 'radio-color',
  RADIO_ROUND = 'radio-round',
  RADIO_ROUND_GRAY = 'radio-round radio-round--gray',
  RADIO_TAB = 'radio-tab',
  RADIO_TAB_RED = 'radio-tab-red',
  RADIO_FAKE_BTN_RED_DASHED = 'radio-fake-btn radio-fake-btn--red-dashed',
}
