import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type { BodyTypeDto, FilterSelectedOptionsCarDto, SearchDataDto } from '~/types/client'
import DataCollectionService from '~/api/common/data-collection.service'
import { FilterKeysEnum } from '~/types/common'

export class BodyTypeDecodeStrategy extends StringDecodeStrategyAbstract<FilterSelectedOptionsCarDto> {
  readonly pattern = `^${FilterKeysEnum.BODY_TYPE}=(${this.slugPattern},?)+$`

  process(subject: SearchDataDto<FilterSelectedOptionsCarDto>, searchItemString: string): void {
    const bodyTypeSlugs = searchItemString.split('=')[1].split(',')

    for (const slug of bodyTypeSlugs) {
      const bodyType = <unknown>DataCollectionService.collections.car_body_type!.maps.slug[slug] as BodyTypeDto

      if (!bodyType) {
        continue
      }

      subject.selectedOptions.bodyTypes.push(bodyType)
    }
  }
}
