import { StringDecodeStrategyAbstract } from '~/services/client/search-string/string-decode-strategy/string-decode-strategy.abstract'
import type { FilterSelectedOptionsCarDto, SearchDataDto } from '~/types/client'
import type { SelectedOptionsNewsDto } from '~/types/common'
import { FilterGroupsEnum } from '~/types/common'
import { carSortOptions, HIDDEN_SORT_OPTIONS, newsSortOptions } from '~/configs/client'

export class OrderDecodeStrategy<T extends FilterSelectedOptionsCarDto | SelectedOptionsNewsDto> extends StringDecodeStrategyAbstract<T> {
  readonly pattern = `^${FilterGroupsEnum.ORDER}=${this.slugPattern}:${this.orderPattern}$`
  private readonly sortOptionList = [...newsSortOptions(), ...carSortOptions()]

  process(subject: SearchDataDto<T>, searchItemString: string): void {
    const data = searchItemString.split('=')[1].split(':')

    const orderKey = data[0]
    const orderValue = data[1]

    const sortOption = this.sortOptionList.find(item => item.key === orderKey && item.direction === orderValue)

    if (sortOption) {
      subject.sortOption = sortOption

      return
    }

    if (Object.values(HIDDEN_SORT_OPTIONS).includes(orderKey as HIDDEN_SORT_OPTIONS)) {
      subject.sortOption = { key: orderKey, direction: orderValue }

      return
    }

    subject.sortOption = this.sortOptionList[0]
  }
}
