export type ConditionItemDto = {
  key: string
  value?: string
}

export function emptyConditionItemDto(): ConditionItemDto {
  return {
    key: '',
    value: '',
  }
}
