import type { DataCollectionMapperOptions } from '~/types/common'
import { DataCollectionTypeEnum } from '~/types/common'
import {
  carBodyTypeDataCollectionOptions,
  carBrandDataCollectionOptions,
  carClassDataCollectionOptions,
  carModelDataCollectionOptions,
  categoryDataCollectionOptions,
  characteristicDataCollectionOptions,
  cityDataCollectionOptions,
  dealerActionDataCollectionOptions,
  dealerDataCollectionOptions,
  regionDataCollectionOptions,
  sectionCharacteristicDataCollectionOptions,
  complaintCategoryDataCollectionOptions,
  featuresDataCollectionOptions,
  sectionFeaturesDataCollectionOptions,
  tagDataCollectionOptions,
} from '~/configs/common/data-collection-option/store'

export function dataCollectionMapper(type: DataCollectionTypeEnum): DataCollectionMapperOptions | null {
  switch (type) {
    case DataCollectionTypeEnum.car_body_type:
      return carBodyTypeDataCollectionOptions
    case DataCollectionTypeEnum.car_brand:
      return carBrandDataCollectionOptions
    case DataCollectionTypeEnum.brands_with_cars:
      return carBrandDataCollectionOptions
    case DataCollectionTypeEnum.car_model:
      return carModelDataCollectionOptions
    case DataCollectionTypeEnum.category:
      return categoryDataCollectionOptions
    case DataCollectionTypeEnum.characteristic:
      return characteristicDataCollectionOptions
    case DataCollectionTypeEnum.city:
      return cityDataCollectionOptions
    case DataCollectionTypeEnum.cities_with_cars:
      return cityDataCollectionOptions
    case DataCollectionTypeEnum.center_cities_with_cars:
      return cityDataCollectionOptions
    case DataCollectionTypeEnum.dealer:
      return dealerDataCollectionOptions
    case DataCollectionTypeEnum.dealer_action:
      return dealerActionDataCollectionOptions
    case DataCollectionTypeEnum.region:
      return regionDataCollectionOptions
    case DataCollectionTypeEnum.regions_with_cars:
      return regionDataCollectionOptions
    case DataCollectionTypeEnum.section_characteristic:
      return sectionCharacteristicDataCollectionOptions
    case DataCollectionTypeEnum.car_class:
      return carClassDataCollectionOptions
    case DataCollectionTypeEnum.complaint_category:
      return complaintCategoryDataCollectionOptions
    case DataCollectionTypeEnum.features:
      return featuresDataCollectionOptions
    case DataCollectionTypeEnum.section_features:
      return sectionFeaturesDataCollectionOptions
    case DataCollectionTypeEnum.tag:
      return tagDataCollectionOptions
    default:
      return null
  }
}
