export interface ServerValidationErrorDto {
  label: string
  cleared: boolean
  timeoutId?: number,
}

export function createServerValidationError(label: string): ServerValidationErrorDto {
  return {
    label,
    cleared: false,
  }
}
