










import { Component, Vue } from 'vue-property-decorator'
import GuestHeader from '~/components/admin/headers/guest-header.vue'
import { closedIndexMeta } from '~/configs/client/robots-meta'

@Component({
  components: {
    GuestHeader,
  },
  head(this: AdminLoginLayout) {
    return {
      meta: [
        closedIndexMeta,
      ],
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
})
export default class AdminLoginLayout extends Vue {
  $i18n: any
}
