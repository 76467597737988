import type { Dictionary } from '~/types/common/dictionary'

export interface WithAliases {
  aliases: string[]
}

export function isWithAliases(value: unknown): value is WithAliases {
  return typeof value === 'object'
    && value !== null
    && 'aliases' in value
    && Array.isArray((value as Dictionary<unknown>).aliases)
}
