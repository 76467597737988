import type {
  FormTextCharacteristicDto,
  RequestBaseCharacteristicDto,
} from '~/types/admin'
import {
  mapFormBaseCharacteristicToRequest,
} from '~/types/admin'

export interface RequestTextCharacteristicDto extends RequestBaseCharacteristicDto {
  minLength: number | null
  maxLength: number | null
}

export function mapFormTextCharacteristicToRequest(formData: FormTextCharacteristicDto): RequestTextCharacteristicDto {
  return {
    ...mapFormBaseCharacteristicToRequest(formData),
    minLength: formData.minLength || null,
    maxLength: formData.maxLength || null,
  }
}
