import type { Dictionary } from '~/types/common'

export const sizes: Dictionary<string> = {
  basic: '1rem', // 16px when font-size = 16px

  xs_xs: '0.25rem',
  xs_sm: '0.5rem',
  xs_md: '0.65rem',
  xs_lg: '1rem',

  sm_xs: '1.25rem',
  sm_sm: '1.5rem',
  sm_md: '1.75rem',
  sm_lg: '2rem',

  md_xs: '2.25rem',
  md_sm: '2.5rem',
  md_md: '2.75rem',
  md_lg: '3rem',

  lg_xs: '3.25rem',
  lg_sm: '3.5rem',
  lg_md: '3.75rem',
  lg_lg: '4rem',

  xl_xs: '4.25rem',
  xl_sm: '4.5rem',
  xl_md: '4.75rem',
  xl_lg: '5rem',

  xxl_xs: '5.25rem',
  xxl_sm: '5.5rem',
  xxl_md: '5.75rem',
  xxl_lg: '6rem',

  md: '20px',

  px25: '25px',
}
