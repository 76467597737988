import type { FormMetadataDto } from '~/types/admin'

export type RequestMetadataDto = FormMetadataDto

export function getEmptyRequestMetadataDto(): RequestMetadataDto {
  return {
    conditionList: [],
    questionAnswerList: [],
  }
}

export function mapFormMetadataToRequest(form: FormMetadataDto): RequestMetadataDto {
  return { ...form }
}
