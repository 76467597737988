export enum UserConsentTypeEnum {
  COOKIES = 'cookies',
  BETA = 'beta',
  CAN_CHANGE_EQUIPMENT_DATA = 'can-change-equipment-data',
  AUTOFILL_BY_VIN_PLATE_NUMBER = 'autofill-vin-plate-number',
  VEHICLE_SCANNING = 'vehicle-scanning',
  ADVERTISEMENT_FILLING_PERCENT = 'advertisement-filling-percent',
  ADD_MESSENGER = 'add-messenger',
  RECOMMENDING_SYSTEM = 'recommending-system',
  CAR_SELECTION = 'car-selection',
  CAR_CHECK = 'car-check',
  HOT_PRICE = 'hot-price',
  IN_THE_WAY = 'in-the-way',
}
