import type { BodyTypeDto, BrandDto, ModelDto, RegionDto, SalesLeaderTypeEnum } from '~/types/common'
import type { RequestFilterSalesLeaderDto } from '~/types/admin'

export interface FilterSalesLeaderDto {
  type: SalesLeaderTypeEnum[]
  model: ModelDto[]
  brand: BrandDto | null
  bodyType: BodyTypeDto[]
  region: RegionDto[]
}

export function emptyFilterSalesLeader(): FilterSalesLeaderDto {
  return {
    type: [],
    model: [],
    brand: null,
    bodyType: [],
    region: [],
  }
}

export function mapFilterSalesLeaderToRequest(filterData: FilterSalesLeaderDto): RequestFilterSalesLeaderDto {
  return {
    bodyType: filterData.bodyType.map(bodyType => bodyType.id),
    model: filterData.model.map(model => model.id),
    brand: filterData.brand ? [filterData.brand.id] : [],
    type: filterData.type,
    region: filterData.region.map(region => region.id),
  }
}
