
















import { Vue, Component } from 'vue-property-decorator'
import type { MenuGroup } from '~/types/common'
import { AuthTypesEnum } from '~/types/common'
import AppMenu from '~/components/admin/menu/index.vue'
import { menuItems } from '~/configs/admin/menu'

@Component({
  components: {
    AppMenu,
  },
})
export default class SidebarComponent extends Vue {
  readonly name: string = this.$authenticate(AuthTypesEnum.ADMIN).username()
  readonly menu: MenuGroup[] = menuItems
}
