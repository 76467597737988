













import { Vue, Component } from 'vue-property-decorator'
import { ClientAuthStoreModule } from '~/store/client/auth'
import { iconSizes } from '~/configs/client'
import AnonFavoriteListService from '~/services/client/local-list/anon-favorite-list.service'

@Component
export default class FavoriteCountButton extends Vue {
  readonly iconSize = iconSizes.sm_sm
  readonly anonFavoriteIdList = AnonFavoriteListService.ids

  @ClientAuthStoreModule.Getter('isLoggedIn')
  readonly isLoggedIn!: boolean

  @ClientAuthStoreModule.Getter('savedUniqueAdvertisementIdList')
  readonly savedAdvertisementIdList!: string[]

  get amount(): number {
    return this.isLoggedIn ? this.savedAdvertisementIdList.length : this.anonFavoriteIdList.length
  }

  get routeName(): string {
    return this.isLoggedIn ? 'client-profile-favorite-advertisements' : 'client-anon-favorite-list'
  }
}
