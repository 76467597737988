import type { ImageSizeDto, Dictionary } from '~/types/common'
import { sizes } from '~/configs/client/sizes'

export const iconSizes: Dictionary<ImageSizeDto> = {
  // 1.25em
  basic: {
    height: sizes.sm_xs,
    width: sizes.sm_xs,
  },

  // 0.25em - 1em
  xs_xs: {
    height: sizes.xs_xs,
    width: sizes.xs_xs,
  },
  xs_sm: {
    height: sizes.xs_sm,
    width: sizes.xs_sm,
  },
  xs_md: {
    height: sizes.xs_md,
    width: sizes.xs_md,
  },
  xs_lg: {
    height: sizes.xs_lg,
    width: sizes.xs_lg,
  },

  // 1.25em - 2em
  sm_xs: {
    height: sizes.sm_xs,
    width: sizes.sm_xs,
  },
  sm_sm: {
    height: sizes.sm_sm,
    width: sizes.sm_sm,
  },
  sm_md: {
    height: sizes.sm_md,
    width: sizes.sm_md,
  },
  sm_lg: {
    height: sizes.sm_lg,
    width: sizes.sm_lg,
  },

  // 2.25em - 3em
  md_xs: {
    height: sizes.md_xs,
    width: sizes.md_xs,
  },
  md_sm: {
    height: sizes.md_sm,
    width: sizes.md_sm,
  },
  md_md: {
    height: sizes.md_md,
    width: sizes.md_md,
  },
  md_lg: {
    height: sizes.md_lg,
    width: sizes.md_lg,
  },

  // 3.25em - 4em
  lg_xs: {
    height: sizes.lg_xs,
    width: sizes.lg_xs,
  },
  lg_sm: {
    height: sizes.lg_sm,
    width: sizes.lg_sm,
  },
  lg_md: {
    height: sizes.lg_md,
    width: sizes.lg_md,
  },
  lg_lg: {
    height: sizes.lg_lg,
    width: sizes.lg_lg,
  },

  // 20px
  md: {
    height: sizes.md,
    width: sizes.md,
  },

  px25: {
    height: sizes.px25,
    width: sizes.px25,
  },

}
