import type { Plugin } from '@nuxt/types'
import { RouteNavigatorService } from '~/plugins/route-navigator/service/route-navigator.service'

declare module 'vue/types/vue' {
  interface Vue {
    $routeNavigator: RouteNavigatorService
  }
}

const bus: Plugin = function (context, inject) {
  inject('routeNavigator', new RouteNavigatorService(context.app.router!))
}

export default bus
