import type { Plugin } from '@nuxt/types'
import Vue from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $bus: Vue
  }
}

const bus: Plugin = function (_context, inject) {
  inject('bus', new Vue())
}

export default bus
