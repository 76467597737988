import type { ModelDto } from '~/types/client'

export interface OwnCarDto {
  id?: string
  year: number
  model: ModelDto
}

export function emptyPartialOwnCarDto(): Partial<OwnCarDto> {
  return {
    year: new Date().getFullYear(),
  }
}
