import type { DataCollectionDto } from '~/types/common'
import { DataCollectionTypeEnum, emptyDataCollection } from '~/types/common'

export type DataCollectionList = {
  [key in DataCollectionTypeEnum]?: DataCollectionDto
}

export function emptyDataCollectionList(): DataCollectionList {
  const list: DataCollectionList = {}

  Object.values(DataCollectionTypeEnum).forEach((key) => {
    list[key] = emptyDataCollection(key)
  })

  return list
}
