import type { Store } from 'vuex'
import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { VueAuthenticate } from 'vue-authenticate'
import UserService from '~/api/admin/user.service'
import { authConfigurations as configurations } from '~/plugins/authenticate/configs/auth'
import type {
  LoginDataDTO,
  LoginResponseDTO,
  SocialDataResponseDto,
  SocialProvidersEnum,
} from '~/types/common'
import {
  AuthTypesEnum,
  UserRoleEnum,
} from '~/types/common'
import { LocalstorageKeys } from '~/configs/admin/localstorage-keys'
import type { RegistrationDto } from '~/types/client'
import { UserAlreadyExistsException } from '~/exceptions'
import ProfileService from '~/api/client/profile.service'
import LocalProfileService from '~/services/common/local-profile.service'
import MaxLoginTriesException from '~/exceptions/max-login-tries.exception'
import type { AuthenticateContract } from '~/plugins/authenticate/type'
import type { StorageContract } from '~/services/common/storage/storage.contract'
import type { RouteNavigatorService } from '~/plugins/route-navigator/service/route-navigator.service'

export default class Authenticate implements AuthenticateContract {
  constructor(
    private readonly type: AuthTypesEnum = AuthTypesEnum.CLIENT,
    private readonly store: Store<any>,
    private readonly $axios: NuxtAxiosInstance,
    private readonly vueAuth: VueAuthenticate,
    private readonly routeNavigator: RouteNavigatorService,
    private readonly storage: StorageContract,
  ) {
  }

  async signUp(registrationData: RegistrationDto, redirect: boolean = true, redirectUrl: string | null = null): Promise<LoginResponseDTO | null> {
    const data: RegistrationDto = { ...registrationData }

    if (data.user.phone) {
      data.user.phone = data.user.phone.replace(/\s+/g, '')
    }

    const response: LoginResponseDTO = await this.store.dispatch(`${configurations[this.type].storeNameSpace}/signup`, data)

    await this.postLogin(response.token, redirect, redirectUrl)

    return response
  }

  async login(loginData: LoginDataDTO, redirect: boolean = true, redirectUrl: string | null = null): Promise<LoginResponseDTO | null> {
    if (!loginData.username) {
      return null
    }
    try {
      const response: LoginResponseDTO = await this.store.dispatch(`${configurations[this.type].storeNameSpace}/login`, {
        ...loginData,
        username: this.prepareUserName(loginData.username),
      })

      await this.postLogin(response.token, redirect, redirectUrl)

      return response
    } catch (error) {
      if (error instanceof MaxLoginTriesException) {
        throw error
      }

      return null
    }
  }

  async loginAs(id: string): Promise<LoginResponseDTO | null> {
    const response: LoginResponseDTO | null = await UserService.loginAs(id)

    if (!response) {
      return null
    }

    this.setToken(response.token, AuthTypesEnum.CLIENT)
    await this.clearUserData(AuthTypesEnum.CLIENT)

    window.open('/profile/')

    return response
  }

  async logout(): Promise<void> {
    await this.store.dispatch(`${configurations[this.type].storeNameSpace}/logout`)

    this.storage.remove(configurations[this.type].localstorageToken)
    this.storage.remove(configurations[this.type].localstorageProfile)
    this.storage.remove(LocalstorageKeys.MODERATING_CAR)

    await this.setAxiosToken()

    this.routeNavigator.redirect(configurations[this.type].logoutRedirect)
  }

  checkToken(): boolean {
    return !!this.storage.get(configurations[this.type].localstorageToken)
  }

  async setAxiosToken(type?: AuthTypesEnum) {
    type = type || this.type
    const token = this.storage.get<string>(configurations[type].localstorageToken)
    let loggedIn = false

    if (token) {
      this.$axios.setHeader('JWTAuthorization', 'Bearer ' + token)
      await ProfileService.validateToken()
      loggedIn = true
    }

    if (!loggedIn) {
      this.$axios.setHeader('JWTAuthorization', false)
    }
    this.store.commit(`${configurations[type].storeNameSpace}/setLoggedIn`, loggedIn)
  }

  clearUserData(authType?: AuthTypesEnum) {
    authType = authType || this.type

    this.store.commit(`${configurations[authType].storeNameSpace}/setProfile`, null)
    this.storage.remove(configurations[authType].localstorageProfile)
    LocalProfileService.setProfile(null, authType)
  }

  public async refreshUserData() {
    let profile = LocalProfileService.getProfile(this.type)

    if (!profile) {
      profile = await ProfileService.get()
      LocalProfileService.setProfile(profile, this.type)
    }

    this.store.commit(`${configurations[this.type].storeNameSpace}/setProfile`, profile)
  }

  username(): string {
    return this.store.getters[`${configurations[this.type].storeNameSpace}/username`]
  }

  isAdmin(): boolean {
    const roles: UserRoleEnum[] = this.store.getters[`${configurations[this.type].storeNameSpace}/roles`]

    return roles.includes(UserRoleEnum.ADMIN)
  }

  isModerator(): boolean {
    const roles: UserRoleEnum[] = this.store.getters[`${configurations[this.type].storeNameSpace}/roles`]

    return roles.includes(UserRoleEnum.CLIENT)
  }

  isContentManager(): boolean {
    const roles: UserRoleEnum[] = this.store.getters[`${configurations[this.type].storeNameSpace}/roles`]

    return roles.includes(UserRoleEnum.CONTENT_MANAGER)
  }

  grantedRoles(): UserRoleEnum[] {
    return this.store.getters[`${configurations[this.type].storeNameSpace}/grantedRoles`]
  }

  isLoggedIn(): boolean {
    return this.store.getters[`${configurations[this.type].storeNameSpace}/isLoggedIn`]
  }

  async socialLogin(provider: SocialProvidersEnum): Promise<LoginResponseDTO | SocialDataResponseDto> {
    try {
      const response = await this.vueAuth.link(provider)

      if ('token' in response) {
        await this.postLogin(response.token)
      }

      return response
    } catch (error) {
      if ('response' in error && error.response.code === 422) {
        throw new UserAlreadyExistsException(error.response.error)
      }

      throw error
    }
  }

  setToken(token: string, type?: AuthTypesEnum) {
    type = type || this.type

    this.storage.set(configurations[type].localstorageToken, token)
  }

  private async postLogin(token: string, redirect: boolean = true, redirectUrl: string | null = null) {
    this.clearUserData()
    this.setToken(token)
    await this.setAxiosToken()

    if (!redirect) {
      return
    }

    this.routeNavigator.redirect(redirectUrl ? { path: redirectUrl } : configurations[this.type].loginRedirect)
  }

  private prepareUserName(username: string): string {
    if (username.includes('@')) {
      return username
    }

    return username.replace(RegExp('\\s|\\)|\\(|-', 'g'), '')
  }
}
