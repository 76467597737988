import { defaultMetadataDto } from '~/types/common/metadata/metadata.dto'
import type { MetadataDto } from '~/types/common'

export interface MetadataPageDto {
  id: string
  key: string
  name: string
  metadataList: MetadataDto[]
}

export const defaultMetadataPageDto: MetadataPageDto = {
  id: '',
  key: 'default',
  name: 'Default',
  metadataList: [
    defaultMetadataDto,
  ],
}
