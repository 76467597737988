













































































import { Component, Mixins } from 'vue-property-decorator'
import { ButtonTypeEnum, DirectionEnum } from '~/types/client'
import { Colors, iconSizes } from '~/configs/client'
import SocialSmall from '~/components/client/social/social-small.vue'
import HeaderLinksMixin from '~/mixins/header-links.mixin'

@Component({
  components: { SocialSmall },
})
export default class FooterMain extends Mixins(HeaderLinksMixin) {
  readonly year = new Date().getFullYear()

  readonly buttonTypes = ButtonTypeEnum
  readonly colors = Colors
  readonly iconSizes = iconSizes
  readonly directions = DirectionEnum

  get htmlSitemapUrl() {
    return process.env.frontendUrl + '/map/'
  }
}
