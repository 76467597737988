import { SalesLeaderTypeEnum } from '~/types/common'
import type { BodyTypeDto, RegionDto } from '~/types/common'
import type { RequestFilterSalesLeaderDto } from '~/types/client'

export interface FilterSalesLeaderDto {
  type: SalesLeaderTypeEnum
  bodyType?: BodyTypeDto | null
  region: RegionDto | null
  price?: {
    minPrice?: number
    maxPrice?: number
  } | null
}

export function emptyFilterSalesLeader(): FilterSalesLeaderDto {
  return {
    type: SalesLeaderTypeEnum.NEW,
    bodyType: null,
    region: null,
    price: null,
  }
}

export function mapFilterSalesLeaderToRequest(filterData: FilterSalesLeaderDto): RequestFilterSalesLeaderDto {
  return {
    bodyType: filterData.bodyType ? filterData.bodyType.id : null,
    type: filterData.type,
    region: filterData.region ? filterData.region.id : null,
    minPrice: filterData.price ? filterData.price.minPrice : null,
    maxPrice: filterData.price ? filterData.price.maxPrice : null,
  }
}
