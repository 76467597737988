






import { Vue, Component, Prop } from 'vue-property-decorator'
import AppMenuGroup from './group.vue'
import type { MenuGroup } from '~/types/common'

@Component({
  components: {
    AppMenuGroup,
  },
})
export default class Menu extends Vue {
  @Prop({ required: true })
  readonly groups!: MenuGroup[]

  get menuGroups(): MenuGroup[] {
    return this.groups
  }
}
