import type { Options as SimpleMDEOptions } from 'simplemde'

export const editorConfig: SimpleMDEOptions = {
  spellChecker: false,
  status: false,
  toolbar: [
    'bold',
    'italic',
    'strikethrough',
    '|',
    'unordered-list',
    'ordered-list',
    '|',
    'preview',
  ],
}
