import { IconEnum } from '~/types/client/ui'
import type { MenuGroup } from '~/types/common'

export const profileDropdownMenuGuest: MenuGroup[] = [
  {
    items: [
      {
        name: 'client-profile-add-advertisement',
        title: 'profile-menu.navigation.add_car',
        icon: IconEnum.addCircleRed,
        id: 'add-advertisement-sidebar',
      },
      {
        name: 'client-profile-favorite-advertisements',
        title: 'profile-menu.navigation.choose_cars',
        icon: IconEnum.heartEmptyRed,
      },
      {
        name: 'client-profile-favorite-filters',
        title: 'profile-menu.navigation.choose_filters',
        icon: IconEnum.starRed,
      },
      {
        name: 'client-profile-comparison',
        title: 'profile-menu.navigation.compare',
        icon: IconEnum.scalesRed,
      },
      {
        name: 'client-profile-edit',
        title: 'profile-menu.navigation.settings',
        icon: IconEnum.settingsRed,
      },
    ],
  },
]
