import type { Plugin } from '@nuxt/types'

declare module 'vue/types/vue' {
  interface Vue {
    $serverUrlWithDomainFormatter(path: string | null): string,
  }
}

const serverUrlWithDomainFormatter: Plugin = function (_context, inject) {
  inject('serverUrlWithDomainFormatter', (path: string | null) => {
    const backendUrl = process.env.backendUrl as string

    return backendUrl + path
  })
}

export default serverUrlWithDomainFormatter
