import Vue from 'vue'

function formatPhoneNumber(value: string, isEnteredSymbol: boolean = false): string {
  let newValue = value.replace(/\D/g, '')

  if (newValue.startsWith('380')) {
    newValue = '+' + newValue
  } else if (/^\d/.test(newValue) || isEnteredSymbol) {
    newValue = '+380' + newValue.substring(1)
  }

  if (newValue.length > 13) { newValue = newValue.substring(0, 13) }

  if (newValue.length > 3) { newValue = newValue.replace(/^(\+380)(\d)/, '$1 $2') }
  if (newValue.length > 6) { newValue = newValue.replace(/^(\+380 \d{2})(\d)/, '$1 $2') }
  if (newValue.length > 9) { newValue = newValue.replace(/^(\+380 \d{2} \d{3})(\d)/, '$1 $2') }
  if (newValue.length > 11) { newValue = newValue.replace(/^(\+380 \d{2} \d{3} \d{2})(\d)/, '$1 $2') }

  return newValue
}

Vue.directive('phone-mask', {
  bind(el, binding) {
    el.addEventListener('input', (event) => {
      const target = event.target as HTMLInputElement | null
      if (target) {
        const value = target.value

        if (binding.modifiers.checkPrefix) {
          if (value.startsWith('+')) {
            target.value = formatPhoneNumber(value, true)
          }

          if (/^\d/.test(value)) {
            target.value = formatPhoneNumber(value)
          }
        } else {
          target.value = formatPhoneNumber(value)
        }
      }
    })
  },
})
