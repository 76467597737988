export enum DataCollectionTypeEnum {
  'advertisement_status' = 'advertisement_status',
  'characteristic' = 'characteristic',
  'category' = 'category',
  'section_characteristic' = 'section_characteristic',
  'section_features' = 'section_features',
  'dealer' = 'dealer',
  'car_body_type' = 'car_body_type',
  'car_fuel_type' = 'car_fuel_type',
  'car_brand' = 'car_brand',
  'car_model' = 'car_model',
  'car_gear_box_type' = 'car_gear_box_type',
  'currency' = 'currency',
  'region' = 'region',
  'city' = 'city',
  'cities_with_cars' = 'cities_with_cars',
  'center_cities_with_cars' = 'center_cities_with_cars',
  'regions_with_cars' = 'regions_with_cars',
  'brands_with_cars' = 'brands_with_cars',
  'dealer_activity' = 'dealer_activity',
  'header_link' = 'header_link',
  'dealer_action' = 'dealer_action',
  'financial_service' = 'financial_service',
  'car_class' = 'car_class',
  'contacts' = 'contacts',
  'complaint_category' = 'complaint_category',
  'metadata_page' = 'metadata_page',
  'features' = 'features',
  'settings' = 'settings',
  'tag' = 'tag',
  'tag_category' = 'tag_category',
  'promotion_config' = 'promotion_config',
  'external_banner' = 'external_banner',
}
