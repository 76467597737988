import type { DealerActivityDto, LocationRadiusDto, RegionDto, CityDto } from '~/types/common'
import type { BrandDto, ModelDto } from '~/types/client'

export interface SelectedOptionsDealersDto {
  page: number,
  activities: DealerActivityDto[]
  region: RegionDto[]
  city: CityDto[]
  brands: BrandDto | null
  models?: ModelDto | null
  name: string
  location: LocationRadiusDto | null
}

export function emptySelectedOptionsDealersDto(): SelectedOptionsDealersDto {
  return {
    page: 1,
    activities: [],
    region: [],
    city: [],
    brands: null,
    models: null,
    name: '',
    location: null,
  }
}
