import type {
  FuelTypeDto,
  SerieDto,
} from '~/types/common'
import {
  emptyCharacteristicValueListDto,
  mapCharacteristicValueLists,
  mapCharacteristicValueListToRequest,
} from '~/types/common'
import type { FullModificationDto, RequestModificationDto, WithFormCharacteristicValuesDto } from '~/types/admin'

export interface FormModificationDto extends WithFormCharacteristicValuesDto {
  name: string
  fuelType: FuelTypeDto | null,
  serie: SerieDto
}

export function emptyFormModification(serie: SerieDto): FormModificationDto {
  const characteristics = serie.generation.model.category ? serie.generation.model.category.characteristicList : []

  const characteristicValues = characteristics.map(characteristic => emptyCharacteristicValueListDto(characteristic))

  return {
    serie,
    characteristicValues,
    name: '',
    fuelType: null,
  }
}

export function mapModificationToForm(modification: FullModificationDto): FormModificationDto {
  const formData = emptyFormModification(modification.serie)

  formData.name = modification.name
  formData.fuelType = modification.fuelType || null
  mapCharacteristicValueLists(formData.characteristicValues, modification.characteristicValues)

  return formData
}

export function mapFormModificationToRequest(formData: FormModificationDto): RequestModificationDto {
  return {
    fuelType: formData.fuelType ? formData.fuelType.id : undefined,
    name: formData.name,
    serie: formData.serie.id,
    characteristicValues: mapCharacteristicValueListToRequest(formData.characteristicValues),
  }
}
