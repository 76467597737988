export enum IconEnum {
  closeWhite = 'close-white',
  actionsNew = 'actions-new',
  close = 'close',
  profile = 'profile',
  people = 'people',
  chat = 'chat',
  heartEmpty = 'heart_empty',
  heartEmptyRed = 'heart_empty_red',
  testDriveCar = 'test_drive_car',
  testDriveCarBlack = 'test_drive_car_black',
  star = 'star',
  starRed = 'star_red',
  scales = 'scales',
  scalesRed = 'scales_red',
  settings = 'settings',
  settingsRed = 'settings_red',
  support = 'support',
  cards = 'cards',
  cash = 'cash',
  carProtected = 'car_protected',
  driverWithBelt = 'driver_with_belt',
  driverWithBeltRed = 'driver_with_belt_red',
  car1 = 'car_1',
  car1Red = 'car_1_red',
  dealer = 'dealer',
  speedometer = 'speedometer',
  speedometerRed = 'speedometer_red',
  addCircle = 'add_circle',
  addCircleRed = 'add_circle_red',
  report = 'report',
  reportRed = 'report_red',
  addedAdvertisements = 'added_advertisement',
  addedAdvertisementsRed = 'added_advertisement_red',
  logout = 'logout',
  becomeDealer = 'become_dealer',
  actions = 'actions',

  actionsRed = 'actions_red',
  advertisements = 'advertisements',
  palette = 'palette',
  carRight = 'car_right',
  gasStation = 'gas_station',
  car = 'car',
  person = 'person',
  peopleGroup = 'people_group',
  calendar = 'calendar',
  calendarNew = 'calendar-new',
  calendarForAuthorNews = 'calendar-for-author-news',
  location = 'location',
  user = 'user',
  fuel = 'fuel',
  roadAccident = 'road_accident',
  wheel = 'wheel',
  transmission = 'transmission',
  cash2 = 'cash_2',
  protected = 'protected',
  plateNumber = 'plate_number',
  weight = 'weight',
  barCode = 'bar_code',
  exclamationMark = 'exclamation_mark',
  list = 'list',
  edit = 'edit',
  mark = 'mark',
  viewTable = 'view_table',
  viewList = 'view_list',
  back = 'back',
  arrowDoubleUp = 'arrow_double_up',
  arrowRightLong = 'arrow-right-long',
  reload = 'reload',
  preloader = 'preloader',
  phone = 'phone',
  check = 'check',
  salesLeaders= 'sales-leaders',
  barChart = 'bar-chart',
  dataAnalysis = 'data-analysis',
  engineering = 'engineering',
  group = 'group',
  research = 'research',
  headerLinks = 'header-links',
  feedbacks = 'feedbacks',
  financialServices = 'financial-services',
  smsLogs = 'research',
  carLeft = 'car_left',
  way = 'way',
}
