import type { Plugin } from '@nuxt/types'

declare module 'vue/types/vue' {
  interface Vue {
    $frontendUrlFormatter(path: string | null): string,
  }
}

const frontendUrlFormatter: Plugin = function (_context, inject) {
  inject('frontendUrlFormatter', (path: string | null) => {
    return path ? `${process.env.frontendUrl}${path}` : null
  })
}

export default frontendUrlFormatter
