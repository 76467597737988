import type {
  BaseCharacteristicDto,
  CheckboxCharacteristicDto,
  ChoiceItemDto,
  ColorCharacteristicDto,
  ColorItemDto,
  MultiChoiceCharacteristicDto,
  NumberCharacteristicDto,
  NumberRangeDto,
  SingleChoiceCharacteristicDto,
} from '~/types/common'
import type { CategoryDto, FilterCharacteristicDto } from '~/types/client'

export type FilterCharacteristicValuesDto<T extends BaseCharacteristicDto = BaseCharacteristicDto> = {
  value?:
    T extends NumberCharacteristicDto ? NumberRangeDto :
      T extends CheckboxCharacteristicDto ? boolean | null :
        undefined,
  values?:
    T extends SingleChoiceCharacteristicDto ? ChoiceItemDto[] :
      T extends MultiChoiceCharacteristicDto ? ChoiceItemDto[] :
        T extends ColorCharacteristicDto ? ColorItemDto[] :
          undefined,
}

export function getEmptyCharacteristicValueList(category: CategoryDto): FilterCharacteristicDto[] {
  return category.characteristicList
    .filter(characteristic => characteristic.filter)
    .map(characteristic => ({ characteristic, values: {} }))
}
