import type { CharacteristicValueListDto, CheckboxCharacteristicDto } from '~/types/common'
import { CharacteristicTypesEnum } from '~/types/common'
import type { ParseCharacteristicValueStrategyInterface } from '~/plugins/formatter/characteristic-value/parse-characteristic-value-strategy/parse-characteristic-value-strategy.interface'
import { i18n } from '~/ports/i18n'

class ParseCheckboxCharacteristicValueStrategy implements ParseCharacteristicValueStrategyInterface {
  support(type: CharacteristicTypesEnum): boolean {
    return type === CharacteristicTypesEnum.checkbox
  }

  parse(valueList: CharacteristicValueListDto): string {
    const characteristic = valueList.characteristic as CheckboxCharacteristicDto

    return valueList.value
      ? (characteristic.trueLabel || i18n.i18n.t('yes'))
      : (characteristic.falseLabel || i18n.i18n.t('no'))
  }
}

export default new ParseCheckboxCharacteristicValueStrategy()
