import { emptyProfileDto } from '~/types/common'
import type { ProfileDto } from '~/types/common'

export interface AuthState {
  profile: ProfileDto,
  isLoggedIn: boolean
}

export function emptyAuthState(): AuthState {
  return {
    profile: emptyProfileDto(),
    isLoggedIn: false,
  }
}
