import type { Middleware } from '@nuxt/types'

const NotFoundMiddleware: Middleware = () => {
  const meta404Node = document.createElement('meta')

  meta404Node.setAttribute('name', 'prerender-status-code')
  meta404Node.setAttribute('content', '404')

  document.head.appendChild(meta404Node)
}

export default NotFoundMiddleware
