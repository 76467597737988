import type {
  ChoiceCharacteristicDto, ChoiceCharacteristicItemDto,
  FormBaseCharacteristicDto,
} from '~/types/admin'
import {
  emptyFormBaseCharacteristic,
  mapBaseCharacteristicToForm,
} from '~/types/admin'
import type { CharacteristicTypesEnum, SectionDto } from '~/types/common'

export interface FormChoiceCharacteristicDto extends FormBaseCharacteristicDto {
  values: ChoiceCharacteristicItemDto[]
}

export function emptyFormChoiceCharacteristic(dtype: CharacteristicTypesEnum, section: SectionDto): FormChoiceCharacteristicDto {
  return {
    ...emptyFormBaseCharacteristic(dtype, section),
    values: [],
  }
}

export function mapChoiceCharacteristicToForm(characteristic: ChoiceCharacteristicDto): FormChoiceCharacteristicDto {
  return {
    ...mapBaseCharacteristicToForm(characteristic),
    values: [...characteristic.values],
  }
}
