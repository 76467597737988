export interface LocationRadiusDto {
  lat: number
  lon: number
  radius: number
}

export function emptyLocationRadius(): LocationRadiusDto {
  return {
    lon: 0,
    lat: 0,
    radius: 0,
  }
}
