








  import { Component, Vue } from 'vue-property-decorator'

  @Component({
  head(this: SitemapLayout) {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
})
  export default class SitemapLayout extends Vue {
    $i18n: any
  }
