import type { Plugin } from '@nuxt/types'
import { toastedDecorator } from '~/plugins/toasted/toastedDecorator'
import type { Toasted } from '~/plugins/toasted/type'

declare module 'vue/types/vue' {
  interface Vue {
    $toast: Toasted
  }
}

const index: Plugin = function (_context, inject) {
  inject('toast', toastedDecorator)
}

export default index
